import {
  DataAccessProfile,
  SortOrder,
} from 'api/resources/models/AutoGenerated';
import { SelectOption } from 'components/Select';
import { useCompanies } from 'pages/hooks';
import { useEffect, useState } from 'react';

type DataAccessProfilesParams = {
  companyId: number | null;
  sortString: keyof DataAccessProfile | null;
  sortOrder: SortOrder | null;
};

export function useDataAccessProfilesParams(
  url: string
): DataAccessProfilesParams {
  const urlParams = new URLSearchParams(url);

  const companyId = urlParams.get('companyId');
  const sort = urlParams.get('sort');
  const sortOrder = urlParams.get('sortDirection') as SortOrder;
  return {
    companyId: companyId ? Number(companyId) : null,
    sortString: sort as keyof DataAccessProfile,
    sortOrder: Object.values(SortOrder).includes(sortOrder) ? sortOrder : null,
  };
}

export function useCompaniesOptions(): SelectOption[] {
  const { companies } = useCompanies({});
  const [options, setOptions] = useState<SelectOption[]>();
  useEffect(() => {
    setOptions([
      { id: 0, value: '', text: 'All' },
      ...companies.map((c) => ({
        id: c.id,
        value: c.name ?? '',
        text: c.name ?? '',
      })),
    ]);
  }, [companies]);
  return options ?? [];
}
