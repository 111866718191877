import styled from '@emotion/styled';
import { Label, PageHeader, UpperInfoContainer } from 'components/PageLayout';
import { useParams } from 'react-router-dom';
import { CoveaReport } from './Covea';
import { NPSBilling } from './NPSBilling';
import { BackButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { Heading } from 'components/Typography';
import { TotalLoss } from './TotalLoss';
import { GoodWillReport } from './Goodwill';
import { InvoiceExtract } from './InvoiceExtract';
import { SLAPerformanceV3 } from "./SLAPerformanceV3";
import { DetailedCycleTimeReport } from './DetailedCycleTime';
import { SMSBillingReport } from './SMSBillling';
import { SMSReport } from './SMSReport';
import { Rolling30DayReport } from './Rolling30DayReport';
import { WorkInProgress } from './WorkInProgress';
type ReportViewerParams = {
  reportPath: string;
};

function ReportNotFound() {
  return (
    <MainContainer direction="column">
      <Container direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Reports" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>Not found</Heading>
        </PageHeader>
        <GridItem>
          <Label>No report found, please try again.</Label>
        </GridItem>
      </Container>
    </MainContainer>
  );
}

export function ReportsDetails() {
  const { reportPath } = useParams<ReportViewerParams>();
  const CurrentReport = generateReportTitle(reportPath);
  return <CurrentReport.component reportPath={reportPath} />;

  function generateReportTitle(report: string) {
    switch (report) {
      case 'GetRepairStatusReportCovea': {
        return { title: 'Repair Status Portal Usage', component: CoveaReport };
      }
      case 'GetNPSBillingReport': {
        return { title: 'NPS Billing Report', component: NPSBilling };
      }
      case 'GetTLEngineeringSchedule': {
        return {
          title: 'TL Engineering Schedule Report',
          component: TotalLoss,
        };
      }
      case 'GetDLGoodwillReport': {
        return {
          title: 'DL Proposed Good will Report',
          component: GoodWillReport,
        };
      }
      case 'GetInvoiceExtractReport ': {
        return { title: 'Invoice Extract Report', component: InvoiceExtract };
      }
      case "GetSLAPerformanceV3Report": {
        return { title: "SLA Performance V3 Report", component: SLAPerformanceV3 }
      }
      case 'GetDetailedCycleTimeReportExV2': {
        return {
          title: 'Detailed Cycle Time Report with Exceptions',
          component: DetailedCycleTimeReport,
        };
      }
      case 'GetSMSReport': {
        return { title: 'SMS Report', component: SMSReport };
      }
      case 'Rolling30DayReport': {
        return { title: 'Repairer Capacity Report', component: Rolling30DayReport };
      }
      case 'GetWorkInProgressV3Report': {
        return {
          title: 'Work In Progress V3 Report',
          component: WorkInProgress,
        };
      }
      case 'SMSBillingReport02': {
        return {
          title: 'SMS Billing Report - 02',
          component: SMSBillingReport,
        };
      }
      default: {
        return { title: 'Report not found', component: ReportNotFound };
      }
    }
  }
}

const MainContainer = styled(UpperInfoContainer)`
  padding: ${({ theme }) => `0 ${theme.padding.xl}`};
`;
