import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};


export interface SelectOption {
  value: string;
  text: string;
  optionElement?: JSX.Element;
  id: number;
}

interface SelectProps {
  options: SelectOption[];
  onChange?: (selected: string, checked: boolean) => void;
  className?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  error?: boolean;
  disabled?: boolean;
  value: string[];
}

export default function MultiSelect({options,disabled,onChange, value, variant }: SelectProps) {
  
  const [selectAll, setSelectAll] = React.useState<boolean>(false);

  React.useEffect(() => {
    setSelectAll(value.length === options.length);
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
        <StyledMaterialSelect
          labelId="demo-multiple-checkbox-label"
          id={"demo-multiple-checkbox-label"}
          multiple
          value={value}
          disabled={disabled}
          variant={variant}
          renderValue={() => options.filter(a=> value.indexOf(a.value) > -1).map(a=>a.text).join(", ")}
          MenuProps={MenuProps}
        >
          <StyledMenuItem key='selectAll' value={'Select All'} onClick={()=> {
            onChange?.("All", !selectAll);
            setSelectAll(!selectAll);
          }}>
              <Checkbox checked={selectAll} />
              <ListItemText primary='Select All' />
          </StyledMenuItem>
          {options.map((item) => (
            <StyledMenuItem key={item.id} value={item.value} onClick={()=> {
              onChange?.(item.value, false);
              setSelectAll(false);
            }}>
              <Checkbox checked={value.indexOf(item.value) > -1} />
              <ListItemText primary={item.text} />
            </StyledMenuItem>
          ))}
        </StyledMaterialSelect>
  );
}


const StyledMenuItem = styled(MenuItem)`
  font-size: ${({ theme }) => theme.fontSize.m};
`;

const StyledMaterialSelect = styled(Select)`
  width: 100%;
  height: 40px;
  font-size: ${({ theme }) => theme.fontSize.m};
  background-color: ${({ theme }) => theme.palette.background.default};

  & .MuiSelect-select {
    background-color: transparent;
    padding: 2px 14px;
  }

  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${({ theme }) => `2px solid ${theme.palette.primary.main}`};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme, disabled }) =>
    disabled ? theme.palette.button.disabled : 'inherits'};
  }

  &.MuiPaper-root {
    width: 100%;
  }
  fieldset {
    border: ${({ disabled }) => (disabled ? '0px' : 'inherits')};
  }
`;