import styled from '@emotion/styled';
import { Container, GridItem } from 'components/Layout';
import { SecondaryText, Text } from 'components/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { Skeleton } from '@mui/material';
import { useRepairerDetails } from 'pages/hooks';
import {
  MainModalContainer,
  ModalButton,
  ModalTextSkeleton,
} from 'components/ModalLayout';

interface ContactRepairerProp {
  repairId: number;
  onContinue: () => void;
}

export function ContactRepairer({ repairId, onContinue }: ContactRepairerProp) {
  const { repairerDetails, isLoading } = useRepairerDetails(repairId);
  return (
    <MainModalContainer direction="column">
      <GridItem className="secondary-text">
        {isLoading ? (
          <ModalTextSkeleton />
        ) : (
          <SecondaryText>Repairer Name</SecondaryText>
        )}
      </GridItem>
      <GridItem className="text">
        {isLoading ? (
          <ModalTextSkeleton />
        ) : (
          <Text>{repairerDetails?.name}</Text>
        )}
      </GridItem>
      <GridItem className="secondary-text">
        {isLoading ? (
          <ModalTextSkeleton />
        ) : (
          <SecondaryText>Repairer Group</SecondaryText>
        )}
      </GridItem>
      <GridItem className="text">
        {isLoading ? (
          <ModalTextSkeleton />
        ) : (
          <Text>{repairerDetails?.repairerGroup}</Text>
        )}
      </GridItem>
      <GridItem className="secondary-text">
        {isLoading ? (
          <ModalTextSkeleton />
        ) : (
          <SecondaryText>Telephone</SecondaryText>
        )}
      </GridItem>
      <GridItem className="text">
        {isLoading ? (
          <Container direction="row">
            <Skeleton variant="circular" className="image"></Skeleton>
            <ModalTextSkeleton />
          </Container>
        ) : (
          <Container direction="row">
            <PhoneIcon className="image" />{' '}
            <EmailPhone href={`tel:${repairerDetails?.telephone}`}>
              {repairerDetails?.telephone}
            </EmailPhone>
          </Container>
        )}
      </GridItem>
      <GridItem className="secondary-text">
        {isLoading ? (
          <ModalTextSkeleton />
        ) : (
          <SecondaryText>Email</SecondaryText>
        )}
      </GridItem>
      <GridItem className="text">
        {isLoading ? (
          <Container direction="row">
            <Skeleton variant="circular" className="image"></Skeleton>
            <ModalTextSkeleton />
          </Container>
        ) : (
          <Container direction="row">
            <MailIcon className="image" />
            <EmailPhone href={`mailto:${repairerDetails?.email}`}>
              {repairerDetails?.email}
            </EmailPhone>
          </Container>
        )}
      </GridItem>
      <GridItem>
        {isLoading ? (
          <Skeleton variant="rectangular" height={40}></Skeleton>
        ) : (
          <ModalButton onClick={onContinue}>Continue</ModalButton>
        )}
      </GridItem>
    </MainModalContainer>
  );
}

export const EmailPhone = styled.a`
  color: ${({ theme }) => theme.palette.button.dark};
  text-overflow: ellipsis;
  max-width: 255px;
  width: 100%;
  overflow: hidden;
  :hover {
    color: ${({ theme }) => theme.palette.primary.activeText};
  }
`;
