import {
  Column,
  Row,
  DataTypeProvider,
  DataTypeProviderProps,
  TableSummaryRow,
  IntegratedSummary
} from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import { Heading, Text } from 'components/Typography';
import { BackButton, PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import {
  Label,
  MainPageContainer,
  PageHeader,
  UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { useInMobile } from 'core/Theming/Device';
import dayjs from 'dayjs';
import { useSLAPerformanceV3, useGetRepairerGroup, useGetRepairerByGroupName } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { convertToDate } from 'pages/hooks';
import { formatDate } from 'utils/helpers';
import InfoText from 'components/InfoText';
import { Select } from 'components/Select';
import { GrayBackdrop, Loader } from 'components/Loader';
import { SLAPerformance } from '../../../api/resources/models/AutoGenerated';
import MultiSelect from 'components/MultiSelect';
import { ReportTitleInfo } from 'components/ReportTitleInfo';

interface Props {
  reportPath: string;
}

type DateFormatterProps = DataTypeProvider.ValueFormatterProps;

interface Props {
  reportPath: string;
}

const DateFormatter = ({ value }: DateFormatterProps) => (
  <div>{formatDate(value)}</div>
);

const DateTypeProvider: React.ComponentType<DataTypeProviderProps> = (
  props: DataTypeProviderProps
) => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

export function SLAPerformanceV3({ reportPath }: Props) {
  const isMobile = useInMobile();
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const urlParams = {
    endDate: urlSearchParams.get('endDate'),
    startDate: urlSearchParams.get('startDate'),
    repairGroup: urlSearchParams.get('repairGroup'),
    siteCode: urlSearchParams.get('siteCode'),
  };
  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParams.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParams.endDate) || null
  );
  const [repairerGroup, setRepairerGroup] = useState<string | null>(urlParams.repairGroup || null);
  const [siteCode, setSiteCode] = useState<string[]>(urlParams.siteCode?.split(', ') || []);

  const dateToString = (date: Date | null) => dayjs(date).format('YYYY-MM-DD');


  const { repairerGroupList, isLoading } = useGetRepairerGroup();
  const { siteCodes, isFetching } = useGetRepairerByGroupName(repairerGroup || "", repairerGroup !== null);

  const repairerGroupOptions = repairerGroupList?.map((item, index) => {
    return ({ id: index, value: item.groupName, text: item.groupName })
  });

  const siteCodesOptions = siteCodes?.map((item, index) => {
    return ({ id: index, value: item.formalSiteCode, text: item.repairerName })
  });

  const queryEnabled =
    !!urlParams.startDate &&
    !!urlParams.endDate &&
    !!urlParams.repairGroup && 
    !!urlParams.siteCode;

  const { reportData, isReportFetching, isFetched, } =
    useSLAPerformanceV3(
      {
        startDate: urlParams.startDate,
        endDate: urlParams.endDate,
        repairerGroup: urlParams.repairGroup,
        siteCode: urlParams.siteCode,
      },
      queryEnabled
    );

    const SummaryItemComponent = (data: TableSummaryRow.ItemProps) => {
      if (data.type === 'percentage') {
        return (
          <Text fontSize="m" fontWeight="bold">
            SLA Compliance: {data.value}
          </Text>
        );  
      }
      if (data.type === 'average') {
        return (
          <Text fontSize="m" fontWeight="bold">
            Average Duration: {data.value}
          </Text>
        );  
      }
      return (
        <Text fontSize="m" fontWeight="bold">
          Total Claims: {data.value}
        </Text>
      );
    };

  const GroupSummaryItemComponent = (data: TableSummaryRow.ItemProps) => {
    if (data.type === 'percentage') {
      return (
        <Text fontSize="m" fontWeight="bold">
          SLA Compliance: {data.value}
        </Text>
      );  
    }
    if (data.type === 'average') {
      return (
        <Text fontSize="m" fontWeight="bold">
          Average Duration: {data.value}
        </Text>
      );  
    }
    return (
      <Text fontSize="m" fontWeight="bold">
        Claims: {data.value}
      </Text>
    );
    
  };

  const [summaryItems] = useState([
    {
      columnName: 'claimReference',
      type: 'count',
    },
    {
      columnName: 'startDTG',
      type: 'percentage',
    },
    {
      columnName: 'slaDuration',
      type: 'average',
    },
  ]);

  const [groupSummaryItems] = useState([
    {
      columnName: 'claimReference',
      type: 'count',
      showInGroupFooter: false,
      alignByColumn: true,
    },
    {
      columnName: 'startDTG',
      type: 'percentage',
      showInGroupFooter: false,
      alignByColumn: true,
    },
    {
      columnName: 'slaDuration',
      type: 'average',
      showInGroupFooter: false,
      alignByColumn: true,
    },
  ]);
  
  const summaryCalculator = (type: string, rows: Array<Row>, getValue: (row: Row) => Row) => {
    if (type === 'percentage') {
      if (!rows.length) {
        return null;
      }
      const totalRow = rows.length;

      const totalCompletedRow = rows.map((a:SLAPerformance) => a.slaCompleted).filter(a=>a === true).length;

      return `${Number(( totalCompletedRow / totalRow) * 100).toFixed(2)}%`;
    }
    if (type === 'average') {
      if (!rows.length) {
        return null;
      }
      const totalRow = rows.length;
      const totalValue = rows.reduce((a: SLAPerformance, b: SLAPerformance) => {
          const first = a.slaDuration ?? 0;
          const second = b.slaDuration ?? 0;
          return first + second; 
      }, 0);
      return totalValue/totalRow;
    }
    return IntegratedSummary.defaultCalculator(type, rows, getValue);
  };

  const [tableColumnExtensions] = useState([
    { columnName: 'repairerGroup', title: 'Repairer Group' ,wordWrapEnabled: true},
    { columnName: 'repairCode', title: 'Repair Code', width:'15%', wordWrapEnabled: true },
    { columnName: 'regMark', title: 'Registration' ,wordWrapEnabled: true},
    { columnName: 'repairerName', title: 'Repairer Name' ,wordWrapEnabled: true},
    { columnName: 'claimReference', title: 'Claim Reference' ,wordWrapEnabled: true},
    { columnName: 'startDTG', title: 'SLA Start' ,wordWrapEnabled: true},
    { columnName: 'expectedDataValue', title: 'SLA Expected End',wordWrapEnabled: true },
    { columnName: 'actualDataValue', title: 'SLA Actual End' ,wordWrapEnabled: true},
    { columnName: 'slaDuration', title: 'Duration' ,wordWrapEnabled: true},
    { columnName: 'hashtag', title: 'Hashtag',wordWrapEnabled: true },
  ]);

  const [columns] = useState<Column[]>([
    { name: 'repairerGroup', title: 'Repairer Group' },
    { name: 'repairCode', title: 'Repair Code' },
    { name: 'regMark', title: 'Registration' },
    { name: 'repairerName', title: 'Repairer Name' },
    { name: 'claimReference', title: 'Claim Reference' },
    { name: 'startDTG', title: 'SLA Start' },
    { name: 'expectedDataValue', title: 'SLA Expected End' },
    { name: 'actualDataValue', title: 'SLA Actual End' },
    { name: 'slaDuration', title: 'Duration' },
    { name: 'hashtag', title: 'Hashtag' },
    { name: 'slaTitle', title: 'SLA Title' },
  ]);

  const rows = useMemo<Row>(() => reportData || [], [reportData]);

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlParams.startDate,
    urlParams.endDate,
    urlParams.repairGroup,
    urlParams.siteCode,
  ]);

  const isBusy = isReportFetching || isLoading || isFetching;
  return (
    <MainPageContainer
      isLoading={isBusy}
      direction="column"
      wrap="nowrap"
    >
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <UpperInfoContainer direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Reports" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>
            <ReportTitleText>
                <div>SLA Performance V3 Report</div>
                <ReportTitleInfo
                  message="Allows a detailed analysis of SLA Performance."
                  title="SLA Performance V3 Report"
                  />
              </ReportTitleText>
            </Heading>
        </PageHeader>
        <CustomForm onSubmit={handleSubmit}>
          <CustomGridContainer direction="row">
            <InputButton md={3} sm={3} xs={12}>
              <Label>Start Date</Label>
              <DatePickerField
                onChange={(date) => setStartDate(date)}
                value={startDate}
                maxDate={endDate}
              />
            </InputButton>
            <InputButton md={3} sm={3} xs={12}>
              <Label>End Date</Label>
              <DatePickerField
                onChange={(date) => setEndDate(date)}
                value={endDate}
                minDate={startDate}
              />
            </InputButton>
            <GridItem md={1} sm={1} xs={2}>
              <Button type="submit" disabled={!startDate || !endDate || !repairerGroup || (repairerGroup !== 'Select All' && siteCode.length <= 0)}>
                {renderSearchIcon()}
                {renderSearchButtonText()}
              </Button>
            </GridItem>
          </CustomGridContainer>
          <Container>
            <InputButton md={3} sm={3} xs={12}>
              <Label>Repairer Group</Label>
              <Select
                options={repairerGroupOptions || []}
                selectedId={
                  repairerGroupOptions?.find(
                    (e: { value: string | null }) => e.value == repairerGroup
                  )?.id
                }
                onChange={(item) => {
                  setRepairerGroup(item.value);
                  setSiteCode([]);
                }}
              />
            </InputButton>
            <InputButton md={3} sm={3} xs={12}>
              <Label>Select Sites</Label>
              <MultiSelect
                options={siteCodesOptions || []}
                value={siteCode}
                onChange={(value, checked) => {
                  if (value === 'All') {
                    if (checked) {
                      setSiteCode(siteCodesOptions?.map((a) => a.value) || []);
                    } else {
                      setSiteCode([]);
                    }
                  } else {
                    if (siteCode.includes(value)) {
                      setSiteCode((r) => [...r.filter((a) => a !== value)]);
                    } else {
                      setSiteCode((r) => [...r, value]);
                    }
                  }
                }}
                disabled={repairerGroup === 'Select All'}
                variant="outlined"
              />
            </InputButton>
          </Container>
        </CustomForm>
      </UpperInfoContainer>
      {isFetched && !isReportFetching && rows && rows?.length === 0 && (
        <CustomInfoText>
          {"Sorry, we couldn't find any reports with this search criteria."}
        </CustomInfoText>
      )}
      <ReportGridContainer direction="column">
        {isFetched && !isReportFetching && rows && rows?.length > 0 && (
          <ReportGrid
            tableColumnExtensions={tableColumnExtensions}
            columns={columns}
            rows={rows}
            summaryItems={summaryItems}
            SummaryItemComponent={SummaryItemComponent}
            DateTypeProvider={DateTypeProvider}
            dateColumnsName={['startDTG']}
            title="SLAPerformanceV3"
            groupSummaryItems={groupSummaryItems}
            disableDragAndDrop
            GroupSummaryItemComponent={GroupSummaryItemComponent}
            defaultGroupingColumn={[{ columnName: 'slaTitle' }, { columnName: 'repairerGroup' }, { columnName: 'repairerName' }]}
            headerTitleForSheet="SLA Performance V3 Report"
            reportHeaderTagLine={`Report produced on ${new Date().toLocaleString()} for period ${startDate?.toLocaleDateString()} to ${endDate?.toLocaleDateString()}`}
            summaryCalculator={summaryCalculator}
          />
        )}
      </ReportGridContainer>
    </MainPageContainer>
  );
  function renderSearchIcon() {
    if (isMobile) return <Search />;
    return null;
  }

  function renderSearchButtonText() {
    if (!isMobile) return 'View';
    return null;
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!!startDate && !!endDate && !!repairerGroup && !!siteCode) {
      return history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
            repairGroup: repairerGroup,
            siteCode: siteCode.join(', '),
          },
          reportPath
        )
      );
    }else return;
  }
}

const InputButton = styled(GridItem)`
      padding-right: ${({ theme }) => theme.margin.s};
      max-width: 330px !important;
      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          margin-bottom: 6px;
      }
      & svg {
          width: 20px;
      height: 20px;
      color: ${({ theme }) => theme.palette.secondary.main};
      }
  `;

const CustomForm = styled.form`
    margin-bottom: ${({ theme }) => theme.margin.l};
  `;

const Button = styled(PrimaryButton)`
    min-width: 40px;
    font-size: ${({ theme }) => theme.fontSize.s};
    height: 40px;
    padding: 12px;
    margin-top: 20.5px;
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      width: 100%;
    }
  `;

const ReportGridContainer = styled(Container)`
    padding-left: ${({ theme }) => theme.padding.xl};
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      padding: 0 20px;
    }
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      padding: 0 15px;
    }
  `;

const CustomInfoText = styled(InfoText)`
    margin-top: 0;
  `;
const CustomGridContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.margin.m};;
`;
const ReportTitleText = styled('div')`
  display: flex;
`;