import {
  Column,
  DataTypeProvider,
  DataTypeProviderProps,
  Row,
} from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import { Heading } from 'components/Typography';
import { BackButton, PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import {
  Label,
  MainPageContainer,
  PageHeader,
  UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { useInMobile } from 'core/Theming/Device';
import dayjs from 'dayjs';
import { useNPSBillingReport } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useReportParams } from '../hooks';
import { convertToDate } from 'pages/hooks';
import InfoText from 'components/InfoText';
import { GrayBackdrop, Loader } from 'components/Loader';
import { ReportTitleInfo } from 'components/ReportTitleInfo';
import { formatDate } from 'utils/helpers';

interface Props {
  reportPath: string;
}

export function NPSBilling({ reportPath }: Props) {
  const isMobile = useInMobile();
  const history = useHistory();
  const location = useLocation();
  const urlParams = useReportParams(location.search);
  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParams.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParams.endDate) || null
  );

  const dateToString = (date: Date | null) => dayjs(date).format('YYYY-MM-DD');

  const queryEnabled = !!urlParams.startDate && !!urlParams.endDate;
  const { reportData, isReportFetching } = useNPSBillingReport(
    {
      startDate: urlParams.startDate,
      endDate: urlParams.endDate,
    },
    queryEnabled
  );

  const [columns] = useState<Column[]>([
    { name: 'repairerName', title: 'Repairer Name' },
    { name: 'repairerGroup', title: 'Repairer Group' },
    { name: 'schemeCode', title: 'Scheme Code' },
    { name: 'billingCount', title: 'Billing Count' },
    { name: 'startDate', title: 'Start Date' },
    { name: 'endDate', title: 'End Date' },
  ]);

  type DateFormatterProps = DataTypeProvider.ValueFormatterProps;

  const DateFormatter = ({ value }: DateFormatterProps) => (
    <div>{formatDate(value, 'L')}</div>
  );

  const DateTypeProvider: React.ComponentType<DataTypeProviderProps> = (
    props: DataTypeProviderProps
  ) => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

  const rows = useMemo<Row>(() => reportData, [reportData]);

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.startDate, urlParams.endDate]);

  return (
    <MainPageContainer
      isLoading={isReportFetching}
      direction="column"
      wrap="nowrap"
    >
      <GrayBackdrop open={isReportFetching}>
        <Loader />
      </GrayBackdrop>
      <UpperInfoContainer direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Reports" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>
            <ReportTitleText>
              <div>NPS Billing Report</div>
              <ReportTitleInfo
                message="Displays the NPS billing report for the date range provided"
                title="NPS Billing Report"
              />
            </ReportTitleText>
          </Heading>
        </PageHeader>
        <CustomForm onSubmit={handleSubmit}>
          <Container direction="row">
            <InputButton md={3} sm={4} xs={12}>
              <Label>Start Date</Label>
              <DatePickerField
                onChange={(date) => setStartDate(date)}
                value={startDate}
                maxDate={endDate}
              />
            </InputButton>
            <InputButton md={3} sm={4} xs={12}>
              <Label>End Date</Label>
              <DatePickerField
                onChange={(date) => setEndDate(date)}
                value={endDate}
                minDate={startDate}
              />
            </InputButton>
            <GridItem md={1} sm={1} xs={2}>
              <Button type="submit" disabled={!startDate || !endDate}>
                {renderSearchIcon()}
                {renderSearchButtonText()}
              </Button>
            </GridItem>
          </Container>
        </CustomForm>
      </UpperInfoContainer>

      {!isReportFetching && rows?.length === 0 && (
        <CustomInfoText>
          {"Sorry, we couldn't find any reports with this search criteria."}
        </CustomInfoText>
      )}

      {rows && rows?.length > 0 && (
        <ReportGridContainer direction="column">
          <ReportGrid
            title="npsBillingReport"
            columns={columns}
            rows={rows}
            DateTypeProvider={DateTypeProvider}
            dateColumnsName={['startDate', 'endDate']}
          />
        </ReportGridContainer>
      )}
    </MainPageContainer>
  );
  function renderSearchIcon() {
    if (isMobile) return <Search />;
    return null;
  }

  function renderSearchButtonText() {
    if (!isMobile) return 'View';
    return null;
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!startDate || !endDate) return;
    navigateToReport();
  }
  function navigateToReport() {
    history.replace(
      createReportURL(
        { startDate: dateToString(startDate), endDate: dateToString(endDate) },
        reportPath
      )
    );
  }
}

const InputButton = styled(GridItem)`
    padding-right: ${({ theme }) => theme.margin.s};
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        margin - bottom: 6px;
    }
    & svg {
        width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
    }
`;

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const ReportGridContainer = styled(Container)`
  padding-left: ${({ theme }) => theme.padding.xl};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;

const CustomInfoText = styled(InfoText)`
  margin-top: 0;
`;

const ReportTitleText = styled('div')`
  display: flex;
`;
