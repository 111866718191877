import { ReactElement, useState } from 'react';
import { Container } from 'components/Layout';
import styled from '@emotion/styled';
import { Text } from 'components/Typography';
import { SecondaryButton } from 'components/Button';
import { Help } from '@mui/icons-material';
import { HelpInformationModal } from 'components/HelpInformationModal';
import { DescriptionText, Home } from 'components/Home';

export function Dashboard(): ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Home headerText="Welcome to Activeweb">
      <DescriptionText fontSize="l">
        Activeweb is the leading Claims Management and Claims Communication
        platform.
      </DescriptionText>
      <DescriptionText fontSize="l">
        Developed closely with leading industry experts, Activeweb allows all
        stakeholders in the claim process to manage key information and interact
        with each other at all stages.
      </DescriptionText>
      <DescriptionText fontSize="l">
        From Bodyshops to Insurers, Manufacturers to Suppliers, Activeweb can
        connect you directly to the information you need, when you need it.
      </DescriptionText>
      <Buttons>
        <Button
          onClick={() => window.open('https://www.pinetreeconsulting.co.uk/')}
        >
          <Text>Find Out More</Text>
        </Button>
        <Button onClick={() => setIsOpen(true)}>
          <HelpIcon />
          <Text>Get Help</Text>
        </Button>
      </Buttons>

      {isOpen && (
        <HelpInformationModal
          isOpen={isOpen}
          onCloseClick={() => {
            setIsOpen(false);
          }}
        />
      )}
    </Home>
  );
}

const Buttons = styled(Container)`
  margin-top: 32px;
`;

const Button = styled(SecondaryButton)`
  margin-right: 12px;
`;
const HelpIcon = styled(Help)`
  margin-right: 10px;
`;
