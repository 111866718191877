import dayjs, { Dayjs } from 'dayjs';
import { GridDateStatusIcon } from './DateStatusIcon';
import { DateStatus } from './types';
import styled from '@emotion/styled';
import { formatDate } from 'utils/helpers';
import { AdditionalStatus, additionalStatus } from './additionalStatus';
import { Container } from 'components/Layout';

interface Props {
  completed?: Date;
  ecd?: Date;
  additionalStatus?: AdditionalStatus | undefined;
}

export function CompletedDisplay({ ecd, completed }: Props) {
  const now = dayjs();

  const ecdDate: Dayjs | undefined = ecd ? dayjs(ecd) : undefined;

  const dtgCompletedDate: Dayjs | undefined = completed
    ? dayjs(completed)
    : undefined;

  const status = getCompletedGridStatus(now, ecdDate, dtgCompletedDate);

  return (
    <Container direction="row" wrap="nowrap">
      <GridDateStatusIcon status={status} />
      <DateTimeColumn>
        <div className="custom-width">{formatDate(completed)}</div>
        <div className="custom-width">
          {additionalStatus(ecdDate, dtgCompletedDate)?.message}
        </div>
      </DateTimeColumn>
    </Container>
  );
}

export function getCompletedGridStatus(
  now: Dayjs,
  ecd?: Dayjs,
  completed?: Dayjs
): DateStatus {
  if (!ecd) return 'grey';
  if (ecd > now && !completed) return 'grey';
  if (ecd < now && !completed) return 'amber';
  if (ecd && completed) {
    const isSameDate = dayjs(ecd).isSame(dayjs(completed), 'date');
    if (isSameDate) return 'grey';
    if (!isSameDate) return 'red';
  }
  return 'unknown';
}

export function getCompletedDetailsStatus(
  now: Dayjs,
  ecd?: Dayjs,
  completed?: Dayjs
): DateStatus {
  if (!ecd && !completed) return 'grey';
  if (ecd) {
    if (ecd > now && !completed) return 'grey';
    if (ecd < now && !completed) return 'amber';
  }
  if (!ecd && completed) return 'green';
  if (ecd && completed) {
    const isSameDate = dayjs(ecd).isSame(dayjs(completed), 'date');
    if (isSameDate) return 'green';
    if (!isSameDate) return 'red';
  }
  return 'unknown';
}

const DateTimeColumn = styled('div')`
  max-width: 100px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  .custom-width {
    width: 83px;
    display: block;
  }
`;
