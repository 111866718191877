import {
  Column,
  Row,
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import { SelectOption } from 'components/InputSelect';
import { Heading } from 'components/Typography';
import { BackButton, PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import { Select } from 'components/Select';
import MultiSelect from 'components/MultiSelect';
import {
  Label,
  MainPageContainer,
  PageHeader,
  UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { useInMobile } from 'core/Theming/Device';
import dayjs from 'dayjs';
import {
  useGetDetailedCycleTimeReport,
  useGetRepairerGroup,
  useGetRepairerByGroupName,
} from 'pages/hooks';
import { FormEvent, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useReportParams } from '../hooks';
import { convertToDate } from 'pages/hooks';
import { formatDate } from 'utils/helpers';
import InfoText from 'components/InfoText';
import { GrayBackdrop, Loader } from 'components/Loader';
import { ReportTitleInfo } from 'components/ReportTitleInfo';
import { Checkbox } from 'components/Checkbox';
interface Props {
  reportPath: string;
}

type DateFormatterProps = DataTypeProvider.ValueFormatterProps;

interface Props {
  reportPath: string;
}

const DateFormatter = ({ value }: DateFormatterProps) => (
  <div>{formatDate(value)}</div>
);

const DateTypeProvider: React.ComponentType<DataTypeProviderProps> = (
  props: DataTypeProviderProps
) => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

export function DetailedCycleTimeReport({ reportPath }: Props) {
  const isMobile = useInMobile();
  const history = useHistory();
  const location = useLocation();
  const urlParams = useReportParams(location.search);
  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParams.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParams.endDate) || null
  );
  const [repairerGroup, setRepairerGroup] =
    useState<SelectOption<string> | null>(null);
  const [siteCode, setSiteCode] = useState<string[]>([]);
  const [showExcep, setShowExcep] = useState<boolean>(false);

  const [queryEnabled, setQueryEnabled] = useState<boolean>(false);

  const dateToString = (date: Date | null) => dayjs(date).format('YYYY-MM-DD');

  const { repairerGroupList, isLoading } = useGetRepairerGroup();
  const { siteCodes, isFetching } = useGetRepairerByGroupName(
    repairerGroup?.value || 'Select All', repairerGroup !== null
  );

  const repairerGroupOptions = repairerGroupList?.map((item, index) => {
    return { id: index, value: item.groupName, text: item.groupName };
  });

  const siteCodesOptions = siteCodes?.map((item, index) => {
    return { id: index, value: item.formalSiteCode, text: item.repairerName };
  });

  const { reportData, isReportFetching, isFetched, reFetchReportData } =
    useGetDetailedCycleTimeReport(
      {
        startDate: urlParams.startDate,
        endDate: urlParams.endDate,
        repairerGroup: repairerGroup?.value || '',
        siteCode:
          siteCode && repairerGroup?.value !== 'Select All'
            ? siteCode.join(', ')
            : '',
        showExcep: showExcep,
      },
      queryEnabled
    );

  const [tableColumnExtensions] = useState([
    { columnName: 'repairer', width: '10%', wordWrapEnabled: true },
    { columnName: 'stream', width: '10%', wordWrapEnabled: true },
    { columnName: 'manufacturer', width: '10%', wordWrapEnabled: true },
    { columnName: 'activeWebID', width: '10%', wordWrapEnabled: true },
    { columnName: 'registration', width: '10%', wordWrapEnabled: true },
    { columnName: 'hours', width: '10%', wordWrapEnabled: true },
    { columnName: 'fnolToOnsite', width: '10%', wordWrapEnabled: true },
    { columnName: 'onSiteToStarted', width: '10%', wordWrapEnabled: true },
    { columnName: 'startedToCompleted', width: '10%', wordWrapEnabled: true },
    { columnName: 'completedToDelivered', width: '10%', wordWrapEnabled: true },
    { columnName: 'keyToKey', width: '10%', wordWrapEnabled: true },
    { columnName: 'fnolToDelivered', width: '10%', wordWrapEnabled: true },
    { columnName: 'affectsCycleTime', width: '10%', wordWrapEnabled: true },
  ]);

  const [columns] = useState<Column[]>([
    { name: 'repairer', title: 'Repairer' },
    { name: 'stream', title: 'Stream' },
    { name: 'manufacturer', title: 'Manufacturer' },
    { name: 'activeWebID', title: 'ActiveWebID' },
    { name: 'registration', title: 'Registration' },
    { name: 'hours', title: 'Hours' },
    { name: 'fnolToOnsite', title: 'FNOLTo On Site' },
    { name: 'onSiteToStarted', title: 'Onsite To Started' },
    { name: 'startedToCompleted', title: 'Started To Completed' },
    { name: 'completedToDelivered', title: 'Completed To Delivered' },
    { name: 'keyToKey', title: 'Key To Key' },
    { name: 'fnolToDelivered', title: 'FNOLto Delivered' },
    { name: 'affectsCycleTime', title: 'Affects CycleTime' },
  ]);

  const rows = useMemo<Row>(() => reportData || [], [reportData]);

  const isBusy = isReportFetching || isFetching || isLoading;
  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <UpperInfoContainer direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Reports" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>
            <ReportTitleText>
              <div>Detailed Cycle Time Report with Exceptions</div>
              <ReportTitleInfo
                message="Provides a detailed breakdown of claims and their cycle times using the same criteria as the Cycle Time screen - this report also includes Exceptions that show why jobs do NOT appear on the Cycle Time Screen (Version 2)"
                title="Detailed Cycle Time Report with Exceptions"
              />
            </ReportTitleText>
          </Heading>
        </PageHeader>
        <CustomForm onSubmit={handleSubmit}>
          <CustomGridContainer direction="row">
            <InputButton md={3} sm={3} xs={12}>
              <Label>Start Date</Label>
              <DatePickerField
                onChange={(date) => setStartDate(date)}
                value={startDate}
                maxDate={endDate}
              />
            </InputButton>
            <InputButton md={3} sm={3} xs={12}>
              <Label>End Date</Label>
              <DatePickerField
                onChange={(date) => setEndDate(date)}
                value={endDate}
                minDate={startDate}
              />
            </InputButton>
            <GridItem md={1} sm={1} xs={2}>
              <Button
                type="submit"
                disabled={
                  !startDate ||
                  !endDate ||
                  !repairerGroup ||
                  (repairerGroup.value !== 'Select All' && siteCode.length <= 0)
                }
              >
                {renderSearchIcon()}
                {renderSearchButtonText()}
              </Button>
            </GridItem>
          </CustomGridContainer>
          <Container direction="row" alignItems="center">
            <InputButton md={3} sm={3} xs={12}>
              <Label>Repairer Group</Label>
              <Select
                options={repairerGroupOptions || []}
                selectedId={repairerGroup?.id}
                onChange={(value: SelectOption | null) => {
                  setRepairerGroup(value);
                  setSiteCode([]);
                }}
              />
            </InputButton>
            <InputButton md={3} sm={3} xs={12}>
              <Label>Select Sites</Label>
              <MultiSelect
                options={siteCodesOptions || []}
                value={siteCode}
                onChange={(value, checked) => {
                  if (value === 'All') {
                    if (checked) {
                      setSiteCode(siteCodesOptions?.map((a) => a.value) || []);
                    } else {
                      setSiteCode([]);
                    }
                  } else {
                    if (siteCode.includes(value)) {
                      setSiteCode((r) => [...r.filter((a) => a !== value)]);
                    } else {
                      setSiteCode((r) => [...r, value]);
                    }
                  }
                }}
                disabled={repairerGroup?.value === 'Select All'}
                variant="outlined"
              />
            </InputButton>
            <InputButton md={3} sm={3} xs={12}>
              <Checkbox
                checked={showExcep}
                onChange={(e) => setShowExcep(Boolean(e.target.checked))}
                label="Include Exceptions"
                color="secondary"
              />
            </InputButton>
          </Container>
        </CustomForm>
      </UpperInfoContainer>
      {isFetched && !isReportFetching && rows && rows?.length === 0 && (
        <CustomInfoText>
          {"Sorry, we couldn't find any reports with this search criteria."}
        </CustomInfoText>
      )}
      <ReportGridContainer direction="column">
        {isFetched && !isReportFetching && rows && rows?.length > 0 && (
          <ReportGrid
            columns={columns}
            tableColumnExtensions={tableColumnExtensions}
            rows={rows}
            DateTypeProvider={DateTypeProvider}
            title="DetailedCycleTimeReport_wEX_V2"
          />
        )}
      </ReportGridContainer>
    </MainPageContainer>
  );
  function renderSearchIcon() {
    if (isMobile) return <Search />;
    return null;
  }

  function renderSearchButtonText() {
    if (!isMobile) return 'View';
    return null;
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    navigateIfPossible();
    setQueryEnabled(true);
    reFetchReportData().then(() => setQueryEnabled(false));
  }

  function navigateIfPossible() {
    if (!startDate || !endDate) return;
    navigateToReport();
  }
  function navigateToReport() {
    history.replace(
      createReportURL(
        { startDate: dateToString(startDate), endDate: dateToString(endDate) },
        reportPath
      )
    );
  }
}

const InputButton = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  max-width: 330px !important;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 6px;
  }
  & svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const ReportGridContainer = styled(Container)`
  padding-left: ${({ theme }) => theme.padding.xl};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;
const CustomGridContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.margin.m}; ;
`;
const CustomInfoText = styled(InfoText)`
  margin-top: 0;
`;
const ReportTitleText = styled('div')`
  display: flex;
`;
