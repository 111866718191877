import styled from '@emotion/styled';
import { Company } from 'api/resources/models/AutoGenerated';
import { Checkbox } from 'components/Checkbox';
import InputSelect, { SelectOption } from 'components/InputSelect';
import { Container, GridItem } from 'components/Layout';
import { MainInputContainer, RequiredLabel } from 'components/PageLayout';
import { TextField } from 'components/TextField';
import { Subheading } from 'components/Typography';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AccountProfileMode } from '.';
import { AccountProfileForm } from './form';

interface Props {
  companies: Company[];
  mode: AccountProfileMode;
}

export function AccountProfileGeneralDetails({ companies, mode }: Props) {
  const { control, getValues, setValue } = useFormContext<AccountProfileForm>();

  const companyOptions = useMemo(
    () =>
      companies?.map((c) => ({
        id: c.id,
        text: c.name ?? '',
        value: c.name ?? undefined,
      })),
    [companies]
  );

  const [selectedCompany, setSelectedCompany] = useState<SelectOption | null>(
    companyOptions.find(
      (c) => c.id === getValues('accountProfile.companyId')
    ) ?? null
  );

  useEffect(() => {
    setSelectedCompany(
      companyOptions.find(
        (c) => c.id === getValues('accountProfile.companyId')
      ) ?? null
    );
  }, [companies, companyOptions, getValues]);

  const adminPermits = getValues('permits.adminPermits');

  return (
    <MainInputContainer direction="column">
      <TopSubHeadingContainer>
        <Subheading>Account Profile Details</Subheading>
      </TopSubHeadingContainer>
      <GridItem>
        <Container direction="column">
          <GridItem md={6} xs={12}>
            <RequiredLabel>Account Profile Name</RequiredLabel>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error?.message}
                  helperText={error?.message}
                  fullWidth
                />
              )}
              name="accountProfile.name"
              control={control}
              defaultValue=""
            />
          </GridItem>
          <GridItem>
            <Controller
              render={({ field }) => (
                <Checkbox
                  {...field}
                  label="Is Template"
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
              name="accountProfile.isTemplate"
              control={control}
              defaultValue={false}
            />
          </GridItem>
        </Container>

        <Container direction="column">
          <GridItem md={6} xs={12}>
            <RequiredLabel>Company Name</RequiredLabel>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <InputSelect
                  disabled={mode === 'Edit'}
                  options={companyOptions}
                  value={selectedCompany}
                  onChange={(company: SelectOption | null) => {
                    setSelectedCompany(company);
                    field.onChange(company?.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
              name="accountProfile.companyId"
              control={control}
            />
          </GridItem>

          <BottomSubHeadingContainer>
            <Subheading>Admin Access</Subheading>
          </BottomSubHeadingContainer>

          <Container direction="column">
            {adminPermits.map((permit, index) => (
              <>
                <Controller
                  key={permit.accountProfilePermitId}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      label={`${permit.name} (${permit.permit})`}
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        permit.children?.forEach((_child, childIndex) =>
                          setValue(
                            `permits.adminPermits.${index}.children.${childIndex}.isActive`,
                            e.target.checked
                          )
                        );
                      }}
                    />
                  )}
                  name={`permits.adminPermits.${index}.isActive`}
                  control={control}
                  defaultValue={false}
                />
                {permit.children?.map((child, childIndex) => (
                  <Controller
                    key={child.accountProfilePermitId}
                    render={({ field }) => (
                      <GlobalAdminCheckbox
                        {...field}
                        disabled
                        label={`${child.name} (${child.permit})`}
                        checked={field.value}
                      />
                    )}
                    name={`permits.adminPermits.${index}.children.${childIndex}.isActive`}
                    control={control}
                    defaultValue={false}
                  />
                ))}
              </>
            ))}
          </Container>
        </Container>
      </GridItem>
    </MainInputContainer>
  );
}

const GlobalAdminCheckbox = styled(Checkbox)`
  margin-left: 17px;
`;

const TopSubHeadingContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.margin.m};
`;
const BottomSubHeadingContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.margin.m};
  margin-bottom: ${({ theme }) => theme.margin.xs};
`;
