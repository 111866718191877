import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { logger } from 'core/logger';
import store from 'core/store';
import { impersonateUserToken } from './impersonateUserToken';

const apiConfig: AxiosRequestConfig = {
  baseURL: '/',
  timeout: 30000,
  validateStatus: (statusCode) => statusCode >= 200 && statusCode < 300,
};

const api = axios.create(apiConfig);

api.interceptors.response.use(
  (response) => response,
  (err) => {
    logger.error(err);
    return Promise.reject(err);
  }
);

api.interceptors.request.use(async function (config) {
  const accessToken = store.getState().auth.user?.accessToken;
  const activeUserProfileId = store.getState().auth.user?.activeUserProfileId;

  const impersonatedUserInfo = await impersonateUserToken();

  if (!accessToken) {
    return config;
  }

  if (config.headers.Authorization) {
    logger.warn(
      `Authorization header was already set 
      for request '${config.url}'. This is most likely an error.`
    );
  } else {
    if (impersonatedUserInfo?.access_token) {
      config.headers.Authorization = `Bearer ${impersonatedUserInfo?.access_token}`;
      if (impersonatedUserInfo?.profile_id)
        config.headers['Active-User-Profile'] =
          impersonatedUserInfo?.profile_id;
    } else {
      config.headers.Authorization = `Bearer ${accessToken}`;
      if (activeUserProfileId) {
        config.headers['Active-User-Profile'] = activeUserProfileId;
      }
    }
  }

  return config;
});

export { api };

export function getBadRequestErrorMessage(error: AxiosError) {
  return error.isAxiosError && error.response?.status === 400
    ? error.response.data
    : 'Something went wrong';
}
