import { Modal } from 'components/Modal';
import { ModalButton } from 'components/ModalLayout';
import PrimaryAccordion from 'components/PrimaryAccordion';
import { accordionItems } from './types';
import styled from '@emotion/styled';

interface Props {
  isOpen: boolean;
  onCloseClick: () => void;
}

export default function LegendModal({ isOpen, onCloseClick }: Props) {
  return (
    <Modal isOpen={isOpen} title="Legend" onClose={onCloseClick}>
      <PrimaryAccordion accordionItems={accordionItems} />
      <StyledModalButton onClick={onCloseClick}>Close</StyledModalButton>
    </Modal>
  );
}

const StyledModalButton = styled(ModalButton)`
  margin-top: ${({ theme }) => theme.margin.m};
`;
