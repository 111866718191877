import { useEffect, useState } from 'react';

interface SessionStorageKey {
  key: string;
}

export function useBrowserTabsSharedSession<T>({ key }: SessionStorageKey) {
  const getKey = `get${key}`;
  const setKey = `set${key}`;

  const [stateFromSession, setStateFomSession] = useState<T>();
  const [isFinished, setIsFinished] = useState(false);
  useEffect(() => {
    // Timeout for the very first instance of app (tab).
    // First instance also askes for the sessionStorage of other tabs
    // but other tabs doesn't exists and isFinished is allways equals false
    // so timeout sets isFinished to true after 200ms
    const isFinishedTimeoutId = window.setTimeout(
      () => setIsFinished(true),
      200
    );

    const sessionStateString = sessionStorage.getItem(key);
    // if sessionStorage empty ask other tabs for session storage
    if (!sessionStateString) {
      localStorage.setItem(getKey, JSON.stringify(Date.now()));
    } else {
      // if own session storage is not empty set state and is finished = true
      setStateFomSession(JSON.parse(sessionStateString));
      setIsFinished(true);
    }

    const handleStorage = (event: StorageEvent) => {
      if (event.key === getKey) {
        //some tab asked for the session storage -> send it
        const sessionString = sessionStorage.getItem(key);
        if (!sessionString) return;
        const objectToStore: T = JSON.parse(sessionString);
        localStorage.setItem(setKey, JSON.stringify(objectToStore));
        localStorage.removeItem(setKey);
        setIsFinished(true);
      } else if (
        event.key === setKey &&
        !sessionStorage[setKey]?.length &&
        event.newValue !== null
      ) {
        // if session storage empty fill it with value got from other tab
        const value: T = JSON.parse(event.newValue);
        if (value) {
          sessionStorage.setItem(key, JSON.stringify(value));
          setStateFomSession(JSON.parse(event.newValue));
        }
        setIsFinished(true);
      }
    };
    window.addEventListener('storage', handleStorage);
    return () => {
      window.removeEventListener('storage', handleStorage);
      clearTimeout(isFinishedTimeoutId);
    };
  }, [key, getKey, setKey]);

  return {
    isFinished,
    stateFromSession,
    set: (state: T) => {
      sessionStorage.setItem(key, JSON.stringify(state));
    },
  };
}
