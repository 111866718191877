import MobileBottomButton, {
  BackButton,
  CreateButton,
} from 'components/Button';
import InputSelect, { SelectOption } from 'components/InputSelect';
import { Container, GridItem } from 'components/Layout';
import {
  MainPageContainer,
  PageHeader,
  UpperInfoContainer,
} from 'components/PageLayout';
import { Heading, Text } from 'components/Typography';
import styled from '@emotion/styled';
import { useCompaniesOptions } from 'pages/DataAccessProfiles/hooks';
import { useCallback, useEffect, useState } from 'react';
import { AccountProfilesParams, useAccountProfilesParams } from './hooks';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { TextField } from 'components/TextField';
import { Select } from 'components/Select';
import { useAccountProfiles } from 'pages/hooks';
import { useRowCountPerPage } from 'components/Pagination/hooks';
import InfoText from 'components/InfoText';
import { CellButton, DataGrid, getSortObject } from 'components/DataGrid';
import {
  accountProfilesTableHeaders,
  disabledAccountProfileColumns,
} from './types';
import { SortObject } from 'components/types';
import { AccountProfile, SortOrder } from 'api/resources/models/AutoGenerated';
import {
  createAccountProfileFromTemplate,
  createAccountProfilesUrl,
  ROUTES,
} from 'core/routes';
import { DesktopContainer } from 'core/Theming/Device';
import { mapAccountProfiles } from './helpers';

const showOptions = [
  { id: 0, value: undefined, text: 'All' },
  { id: 1, value: false, text: 'Only Account Profiles' },
  { id: 2, value: true, text: 'Only Templates' },
];

export function AccountProfiles() {
  const location = useLocation();
  const urlParams = useAccountProfilesParams(location.search);
  if (typeof urlParams === 'string') return <p>{urlParams}</p>;
  return <AccountProfilesSearch urlParams={urlParams} />;
}

export function AccountProfilesSearch({
  urlParams,
}: {
  urlParams: AccountProfilesParams;
}) {
  const history = useHistory();
  const companiesOptions = useCompaniesOptions();

  const { itemsPerPage, setItemsPerPage } = useRowCountPerPage();

  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sortString,
    sortOrder: urlParams.sortOrder,
  });

  const { accountProfiles, isAccountProfilesLoading } = useAccountProfiles({
    config: {
      skip: urlParams.page * itemsPerPage,
      take: itemsPerPage,
      companyId: urlParams.companyId,
      isTemplate: urlParams.isTemplate,
      sortOrder: urlParams.sortOrder,
      sortString: urlParams.sortString,
    },
    onSuccess: () => {
      setPage(urlParams.page);
      setSortObject({
        sortString: urlParams.sortString,
        sortOrder: urlParams.sortOrder,
      });
      setSelectedCompanyId(urlParams.companyId);
      setShowOption(getShowOption());
    },
  });
  const profiles = accountProfiles?.items.map(mapAccountProfiles);

  const getShowOption = () =>
    showOptions.find((i) => i.value === urlParams.isTemplate) ?? showOptions[0];

  const [selectedCompanyId, setSelectedCompanyId] = useState(
    urlParams.companyId
  );
  const [selectedCompany, setSelectedCompany] = useState<SelectOption | null>(
    null
  );
  const [page, setPage] = useState(urlParams.page);
  const [showOption, setShowOption] = useState<
    SelectOption<boolean | undefined>
  >(getShowOption());

  const navigateToAccountProfiles = useCallback(() => {
    history.replace(
      createAccountProfilesUrl(
        page,
        selectedCompanyId,
        showOption?.value,
        sortObject?.sortOrder,
        sortObject?.sortString
      )
    );
  }, [history, showOption, page, selectedCompanyId, sortObject]);

  useEffect(() => {
    navigateToAccountProfiles();
    setSelectedCompany(
      companiesOptions.find((c) => c.id === selectedCompanyId) ?? null
    );
  }, [
    page,
    sortObject,
    selectedCompanyId,
    showOption,
    companiesOptions,
    navigateToAccountProfiles,
  ]);

  return (
    <MainPageContainer
      isLoading={isAccountProfilesLoading}
      wrap="nowrap"
      direction="column"
    >
      <UpperInfoContainer direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Administration" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>Account Profiles</Heading>
        </PageHeader>

        <Container direction="row" alignItems="flex-end">
          <CompanyContainer md={3} xs={12}>
            <TextItem>
              <Text>Select Company</Text>
            </TextItem>
            <InputSelect
              options={companiesOptions}
              value={selectedCompany}
              onChange={(company: SelectOption | null) => {
                setPage(0);
                setSelectedCompany(company);
                setSelectedCompanyId(company?.id);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </CompanyContainer>

          <TemplatesContainer md={2} xs={12}>
            <TextItem>
              <Text>Show</Text>
            </TextItem>
            <Select
              options={showOptions}
              selectedId={showOption?.id}
              onChange={(value) => {
                setPage(0);
                setShowOption(value);
              }}
            />
          </TemplatesContainer>
          <DesktopContainer>
            <GridItem md={7} xs={12}>
              {renderCreateButton()}
            </GridItem>
          </DesktopContainer>
        </Container>
      </UpperInfoContainer>

      {!isAccountProfilesLoading &&
        accountProfiles?.items &&
        accountProfiles?.items.length <= 0 ? (
        <InfoText>{"Sorry, we couldn't find any account profiles."}</InfoText>
      ) : (
        <DataGrid
          isLoading={isAccountProfilesLoading}
          headers={accountProfilesTableHeaders}
          data={profiles}
          onSort={sort}
          disabledSortColumns={disabledAccountProfileColumns}
          sortObject={sortObject}
          totalCount={accountProfiles?.totalRows}
          currentPage={page}
          onPageChange={setPage}
          onItemsPerPageChange={setItemsPerPage}
          onRowClick={rowClick}
          customCellRender={({ baseRender, value, headerId, row }) => {
            if (typeof value !== 'boolean') return baseRender();
            if (headerId === 'isTemplate') return <>{value ? 'Yes' : 'No'}</>;
            if (headerId === 'isCreatingAsCopy')
              return (
                <CellButton
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push(
                      createAccountProfileFromTemplate(row.accountProfileId)
                    );
                  }}
                >
                  Copy profile
                </CellButton>
              );

            return baseRender();
          }}
        />
      )}
      <MobileBottomButton>{renderCreateButton()}</MobileBottomButton>
    </MainPageContainer>
  );

  function sort(sortValue: string) {
    setSortObject((prevValue) => {
      return getSortObject(
        sortValue,
        prevValue
          ? prevValue
          : { sortString: sortValue, sortOrder: SortOrder.Ascending }
      );
    });
  }

  function rowClick(row: AccountProfile) {
    const route = generatePath(ROUTES.accountProfileEdit, {
      id: row.accountProfileId,
    });

    history.push(route);
  }

  function renderCreateButton() {
    return (
      <CreateButton
        text="Create New Account Profile"
        route={ROUTES.accountProfileCreate}
      />
    );
  }
}

const TextItem = styled(GridItem)`
  padding-bottom: 12px;
`;

const CompanyContainer = styled(GridItem)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-right: 0px;
    margin-bottom: ${({ theme }) => theme.padding.s};
  }
`;

const TemplatesContainer = styled(GridItem)`
  padding-left: ${({ theme }) => theme.padding.s};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-left: 0px;
    margin-top: ${({ theme }) => theme.padding.s};
    margin-bottom: ${({ theme }) => theme.padding.m};
  }
`;
