import ErrorBoundary from 'core/ErrorBoundary';
import { Provider } from 'react-redux';
import store from 'core/store';
import Main from './Main';
import EmotionTheme from 'core/Theming/EmotionTheme';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'api/client';
import { ReactQueryDevtools } from 'react-query/devtools';
import { MainContainer } from 'components/Layout';
import AuthProvider from 'core/auth/AuthProvider';
import userManager from 'core/auth/userService';
import { HashRouter } from 'react-router-dom';
import ScrollToTop from 'core/ScrollToTop';
import { MaintenanceMessages } from 'core/maintenanceMessages';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ScrollContextProvider from 'core/ScrollContext';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AuthProvider userManager={userManager} store={store}>
          <EmotionTheme>
            <MainContainer>
              <ErrorBoundary>
                <MaintenanceMessages>
                  <ToastContainer />
                  <HashRouter>
                    <ScrollToTop />
                    <ScrollContextProvider>
                      <Main />
                    </ScrollContextProvider>
                  </HashRouter>
                </MaintenanceMessages>
              </ErrorBoundary>
            </MainContainer>
          </EmotionTheme>
        </AuthProvider>
      </Provider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
