import styled from '@emotion/styled';
import { Home } from 'components/Home';
import { Text } from 'components/Typography';

interface Props {
  message: string;
}
export function MaintenancePage({ message }: Props) {
  return (
    <Home headerText="Sorry, Activeweb is under maintenance right now">
      <DescriptionText>{message}</DescriptionText>
      <DescriptionText>Come back soon!</DescriptionText>
    </Home>
  );
}

const DescriptionText = styled(Text)`
  margin-bottom: 16px;
`;
