import styled from '@emotion/styled';
import { Button, InputAdornment } from '@mui/material';
import InputSelect, { SelectOption } from 'components/InputSelect';
import { Container, GridItem } from 'components/Layout';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { TextField } from 'components/TextField';
import {
  getUserAccountCashKey,
  useAssignableAccountProfilesByCompanyId,
  useUpdateUserAccountProfiles,
  useUserAccountProfiles,
} from 'pages/hooks';
import { Clear, Star, StarOutline } from '@mui/icons-material';
import { Text } from 'components/Typography';
import { UserAccount } from './form';
import { PrimaryButton } from 'components/Button';
import { GrayBackdrop, Loader } from 'components/Loader';
import { queryClient } from 'api/client';
import {
  InputContainer,
  Label,
  MainInputContainer,
} from 'components/PageLayout';

interface Props {
  user: UserAccount;
}

export function UserProfiles({ user }: Props) {
  const { accountProfiles } = useAssignableAccountProfilesByCompanyId({
    id: user.companyId,
  });
  const { userAccountProfiles, reloadUserAccountProfiles } =
    useUserAccountProfiles(user.id);
  const { updateUserAccountProfiles, isUpdating } =
    useUpdateUserAccountProfiles();

  const profiles = accountProfiles
    .filter(
      (accountProfile) =>
        !userAccountProfiles.find(
          (u) => u.accountProfileId === accountProfile.accountProfileId
        )
    )
    .map(({ accountProfileId, name }) => ({
      id: accountProfileId,
      value: name ?? '',
      text: name ?? '',
    }));

  const [selectedProfile, setSelectedProfile] = useState<SelectOption | null>(
    null
  );
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [isProfilesOpen, setIsProfilesOpen] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const isBusy = isUpdating || isRefetching;
  return (
    <MainInputContainer direction="column">
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <Input>
        <Label>Add User Account Profiles</Label>
        <InputSelect
          options={profiles}
          open={isProfilesOpen}
          value={selectedProfile}
          onChange={() => {
            setSelectedProfile(null);
          }}
          inputValue={searchValue}
          onOpen={() => {
            setIsProfilesOpen(true);
          }}
          onFocus={() => {
            setIsProfilesOpen(true);
          }}
          onInputChange={(val, reason) => {
            if (reason !== 'reset') setSearchValue(val);
          }}
          onBlur={() => setIsProfilesOpen(false)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          renderOption={(_props, key, option) => (
            <OptionsContainer>
              <GridItem xs={11}>{key.text}</GridItem>
              <GridItem xs={1}>
                <AddButton
                  onClick={() => {
                    addClick(key);
                  }}
                  disabled={option.selected && isBusy}
                >
                  Add
                </AddButton>
              </GridItem>
            </OptionsContainer>
          )}
        />
      </Input>
      <InputContainer direction="column">
        <Label>Added User Account Profiles</Label>
        {userAccountProfiles.map((p) => (
          <AddedAcountProfile direction="row" key={p.id} alignItems="center">
            <GridItem md={4} sm={4} xs={2}>
              <StyledText noWrap fontSize="m">
                {p.name}
              </StyledText>
            </GridItem>
            <GridItem md={8} sm={8} xs={10}>
              <ButtonsContainer justify="flex-end" wrap="nowrap">
                <GridItem>
                  <TextButton onClick={() => makeDefault(p.accountProfileId)}>
                    {user.defaultUserAccountProfileId === p.id ? (
                      <>
                        <StyledStar />
                        <ButtonText noWrap fontSize="m">
                          Is default
                        </ButtonText>
                      </>
                    ) : (
                      <>
                        <StyledStarOutline />
                        <ButtonText noWrap fontSize="m">
                          Make default
                        </ButtonText>
                      </>
                    )}
                  </TextButton>
                </GridItem>
                <GridItem>
                  <TextButton
                    onClick={() => removeClick(p.accountProfileId)}
                    disabled={user.defaultUserAccountProfileId === p.id}
                  >
                    <StyledClear />
                    <ButtonText
                      isDisabled={user.defaultUserAccountProfileId === p.id}
                      fontSize="m"
                    >
                      Remove
                    </ButtonText>
                  </TextButton>
                </GridItem>
              </ButtonsContainer>
            </GridItem>
          </AddedAcountProfile>
        ))}
      </InputContainer>
    </MainInputContainer>
  );

  function addClick(profile: SelectOption) {
    setIsProfilesOpen(false);
    updateUserAccountProfiles({
      userId: user.id,
      accountProfileIds: [
        ...userAccountProfiles.map((p) => p.accountProfileId),
        profile.id,
      ],
      defaultAccountProfileId:
        userAccountProfiles.find(
          (a) => a.id === user.defaultUserAccountProfileId
        )?.accountProfileId ?? profile.id,
    }).then(() => {
      setIsRefetching(true);
      reloadUserAccountProfiles().then(() => setIsRefetching(false));
    });
  }

  function removeClick(profileId: number) {
    updateUserAccountProfiles({
      userId: user.id,
      accountProfileIds: userAccountProfiles
        .filter((a) => a.accountProfileId !== profileId)
        .map((a) => a.accountProfileId),
      defaultAccountProfileId:
        userAccountProfiles.find(
          (a) => a.id === user.defaultUserAccountProfileId
        )?.accountProfileId ?? userAccountProfiles[0].accountProfileId,
    }).then(() => {
      setIsRefetching(true);
      setSelectedProfile(null);
      reloadUserAccountProfiles().then(() => setIsRefetching(false));
    });
  }

  function makeDefault(profileId: number) {
    if (
      profileId ===
      userAccountProfiles.find((a) => a.id === user.defaultUserAccountProfileId)
        ?.accountProfileId
    )
      return;
    updateUserAccountProfiles({
      userId: user.id,
      accountProfileIds: userAccountProfiles.map((a) => a.accountProfileId),
      defaultAccountProfileId: profileId,
    }).then(() => {
      setIsRefetching(true);
      queryClient
        .invalidateQueries(getUserAccountCashKey(user.id))
        .then(() => setIsRefetching(false));
    });
  }
}

const Input = styled(GridItem)`
  margin-bottom: 12px;
  & .MuiAutocomplete-option {
    cursor: default;
  }
`;

const AddedAcountProfile = styled(Container)`
  min-height: 40px;
  border: ${({ theme }) => `1px solid ${theme.palette.secondary.main}`};
  box-shadow: ${({ theme }) => theme.shadow.s};
  border-radius: ${({ theme }) => theme.border.radius};
  padding-left: 12px;
  margin-bottom: ${({ theme }) => theme.margin.s};
`;

const StyledText = styled(Text)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
    font-size: 10px;
  }
`;

const ButtonsContainer = styled(Container)`
  padding-right: 12px;
`;
const TextButton = styled(Button)`
  background: transparent;
  line-height: 17px;
  border: 0px;
  color: ${({ theme }) => theme.palette.primary.main};
  :hover {
    background: unset;
    color: ${({ theme }) => theme.palette.primary.activeText};
    p {
      color: ${({ theme }) => theme.palette.primary.activeText};
    }
  }
  text-transform: none;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
    padding-right: 0px;
    padding-left: 0px;
  }
`;

const ButtonText = styled(Text) <{ isDisabled?: boolean }>`
  color: ${({ isDisabled, theme }) =>
    isDisabled
      ? `${theme.palette.primary.disabled}`
      : `${theme.palette.primary.dark}`};
  margin-left: 10px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
    font-size: 10px;
    margin: 0px;
  }
`;

const StyledStar = styled(Star)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
    font-size: 20px;
  }
`;

const StyledStarOutline = styled(StarOutline)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
    font-size: 20px;
  }
`;

const StyledClear = styled(Clear)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
    font-size: 20px;
  }
`;

const OptionsContainer = styled(Container)`
  width: 100%;
  padding: 10px 20px 0 10px;
  cursor: default;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.fontSize.s};
`;

const AddButton = styled(PrimaryButton)`
  min-width: 49px;
  height: 27px;
  cursor: pointer;
  padding: 5.5px 12px 5.5px 12px;
  font-size: ${({ theme }) => theme.fontSize.s};
`;
