import { useState } from 'react';
import { Container, GridItem } from 'components/Layout';
import { Text, SecondaryText } from 'components/Typography';
import { useUser } from 'pages/hooks';
import styled from '@emotion/styled';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getNameAbbreviation } from 'utils';
import { ProfileModal } from './ProfileModal';
import { UserMenuButton } from 'components/Button';
import { User } from 'api/resources/models/AutoGenerated';
import { UserMenu } from './UserMenu';
import PrimaryToast from 'components/PrimaryToast';

export function UserMenuContainer() {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user, isLoading } = useUser({
    onSuccess: (changedUser) => {
      if (!profileBeingSwitched) return;
      showProfileSwitchMessage(changedUser);
      setProfileBeingSwitched(false);
    },
  });
  const [isOpenToast, setIsOpenToast] = useState<boolean>(false);
  const [profileBeingSwitched, setProfileBeingSwitched] =
    useState<boolean>(false);

  const [message, setMessage] = useState<string>('');

  if (isLoading) return null;

  const nameAbbreviation = user?.fullName
    ? getNameAbbreviation(user.fullName)
    : '';

  const selectedUserAcountProfileName =
    user?.selectedUserAccountProfile.name ?? '';
  return (
    <div>
      <MainContainer>
        <UserMenuButton onClick={handleButtonClick}>
          <GridItem>
            <Bubble>{nameAbbreviation}</Bubble>
          </GridItem>
          <GridItem className="text-container">
            <Container direction="column">
              <TextContainer>
                <Text>{user?.fullName ?? ''}</Text>
              </TextContainer>
              <TextContainer>
                <Text>
                  <SecondaryText align="left">
                    {selectedUserAcountProfileName}
                  </SecondaryText>
                </Text>
              </TextContainer>
            </Container>
          </GridItem>
          <IconContainer className="arrow">
            {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconContainer>
        </UserMenuButton>
        {user && (
          <UserMenu
            anchorEl={anchorEl}
            onClose={handleClose}
            user={user}
            selectedProfileName={selectedUserAcountProfileName}
            onOpenProfileClick={() => {
              setIsOpen(true);
              handleClose();
            }}
          />
        )}
        {user?.userAccountProfiles && user?.userAccountProfiles.length > 0 ? (
          <ProfileModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onProfileSwitch={handleProfileSwitch}
            profiles={user?.userAccountProfiles}
          />
        ) : null}
      </MainContainer>
      <PrimaryToast
        message={message}
        isOpen={isOpenToast}
        onClose={() => setIsOpenToast(false)}
        severity="info"
      />
    </div>
  );

  function handleProfileSwitch() {
    setIsOpen(false);
    setProfileBeingSwitched(true);
  }

  function showProfileSwitchMessage(changedUser: User) {
    setMessage(
      `User account profile switched to ${changedUser?.selectedUserAccountProfile.name}`
    );
    setIsOpenToast(true);
  }

  function handleButtonClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
}

const MainContainer = styled(Container)`
  position: relative;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    right: 0;
    .text-container,
    .arrow {
      display: none;
    }
  }
`;

const TextContainer = styled(GridItem)`
  :hover {
    cursor: pointer;
  }
`;

const IconContainer = styled(GridItem)`
  display: flex;
  align-items: center;
`;

const Bubble = styled(Container)`
  width: 32px;
  height: 32px;
  background: #c9ff55;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.margin.s};
  font-weight: 700;
`;
