import { Skeleton } from '@mui/material';
import { Container, GridItem } from 'components/Layout';
import { Text } from 'components/Typography';
import { useHelpInformation } from 'pages/hooks';
import { Modal } from './Modal';
import {
  MainModalContainer,
  ModalButton,
  ModalTextSkeleton,
} from './ModalLayout';

interface Props {
  isOpen: boolean;
  onCloseClick: () => void;
}

export function HelpInformationModal({ isOpen, onCloseClick }: Props) {
  const { message, isLoading } = useHelpInformation();
  return (
    <Modal isOpen={isOpen} title="Help" onClose={onCloseClick}>
      <MainModalContainer direction="column">
        <GridItem className="text">
          {isLoading ? (
            <>
              {[...Array(2)].map((_i, idx) => (
                <Container key={idx} direction="row">
                  <Skeleton variant="circular" className="image"></Skeleton>
                  <ModalTextSkeleton />
                </Container>
              ))}
            </>
          ) : (
            <Text>
              {message ??
                'Apologies, there are no help contact information available.'}
            </Text>
          )}
        </GridItem>
        <GridItem>
          {isLoading ? (
            <ModalTextSkeleton variant="rectangular" width={312} height={40} />
          ) : (
            <ModalButton onClick={onCloseClick}>Close</ModalButton>
          )}
        </GridItem>
      </MainModalContainer>
    </Modal>
  );
}
