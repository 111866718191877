import axios from 'axios';
import { config } from 'core/config';
import store from 'core/store';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import { logger } from 'core/logger';
import {
  localStorageImpersonatedUser,
  localStorageImpersonatedUserId,
  signout,
} from 'core/auth/userService';
import {
  impersonatedAccessTokenExpired,
  userImpersonated,
  userImpersonationStarted,
} from 'core/store/auth';

const adminBaseUrl = '/api/users';

export const impersonateUserToken = async () => {
  const accessToken = store.getState().auth.user?.accessToken;
  const activeUserProfileId = store.getState().auth.user?.activeUserProfileId;

  const impersonatedUserAccessToken =
    store.getState().auth.impersonatedUser?.accessToken;
  const impersonatedUserId = store.getState().auth.impersonatedUser?.userId;
  const impersonatedUserActiveUserProfileId =
    store.getState().auth.impersonatedUser?.activeUserProfileId;

  if (impersonatedUserAccessToken) {
    const decoded: { exp: number } = await jwt_decode(
      impersonatedUserAccessToken
    );
    const difference = dayjs.unix(decoded.exp).diff(dayjs(), 'minutes');

    if (difference > 0 && difference <= 5 && impersonatedUserId) {
      try {
        const response = await axios.get(
          `${config.activewebApiUrl}${adminBaseUrl}/impersonate/${impersonatedUserId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Active-User-Profile': activeUserProfileId,
            },
          }
        );

        localStorage.setItem(
          localStorageImpersonatedUser,
          JSON.stringify(response.data)
        );
        localStorage.setItem(
          localStorageImpersonatedUserId,
          impersonatedUserId.toString()
        );
        store.dispatch(userImpersonationStarted());
        const updatedTokenDecoded: { name: string; email: string } =
          await jwt_decode(response.data.access_token);
        store.dispatch(
          userImpersonated({
            accessToken: response.data.access_token,
            name: updatedTokenDecoded.name ?? undefined,
            email: updatedTokenDecoded.email ?? undefined,
            userId: impersonatedUserId,
          })
        );

        return {
          access_token: response.data.access_token,
          profile_id: impersonatedUserActiveUserProfileId,
        };
      } catch (error) {
        logger.error(error);
      }
    } else if (difference <= 0) {
      store.dispatch(impersonatedAccessTokenExpired());
      signout();
      return null;
    } else
      return {
        access_token: impersonatedUserAccessToken,
        profile_id: impersonatedUserActiveUserProfileId,
      };
  }
};
