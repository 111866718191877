import dayjs, { Dayjs } from 'dayjs';
import { formatDate } from 'utils/helpers';
import { GridDateStatusIcon } from './DateStatusIcon';
import { SlaFieldContainer } from './SlaFieldContainer';
import { DateStatus } from './types';

interface Props {
  modified: Date;
  delivered?: Date;
}

export function ModifiedDisplay({ modified, delivered }: Props) {
  const now = dayjs();
  const minusThreeDays = now.subtract(3, 'day');
  const minusSevenDays = now.subtract(7, 'day');
  const isDelivered = !!delivered;

  const status = getModifiedDisplayStatus(
    dayjs(modified),
    minusThreeDays,
    minusSevenDays,
    isDelivered
  );

  return (
    <SlaFieldContainer>
      <GridDateStatusIcon status={status} />
      <div style={{ width: '83px' }}>{formatDate(modified)}</div>
    </SlaFieldContainer>
  );
}

function getModifiedDisplayStatus(
  initialField: Dayjs,
  minusThreeDays: Dayjs,
  minusSevenDays: Dayjs,
  isDelivered: boolean
): DateStatus {
  if (isDelivered) return 'grey';

  if (initialField > minusThreeDays) return 'green';

  if (initialField > minusSevenDays && initialField < minusThreeDays)
    return 'amber';

  if (initialField < minusSevenDays) return 'red';

  return 'unknown';
}
