import { ReactComponent as IsRequired } from 'assets/courtesy-car/IsRequired.svg';
import { ReactComponent as NotRequired } from 'assets/courtesy-car/NotRequired.svg';
import { ReactComponent as AssignedReturned } from 'assets/courtesy-car/AssignedReturned.svg';
import { ReactComponent as AssignedOut } from 'assets/courtesy-car/AssignedOut.svg';
import { ReplacementVehicleStatus } from 'api/resources/models/AutoGenerated';
import { logger } from 'core/logger';
import Status from './Status';

export interface ReplacementVehicleIconProps {
  status: ReplacementVehicleStatus;
  isStatusTooltipVisible?: boolean;
}
export function ReplacementVehicleIcon({
  status,
  isStatusTooltipVisible,
}: ReplacementVehicleIconProps) {
  const props = { status, isStatusTooltipVisible };
  if (status === ReplacementVehicleStatus.AssignedOut)
    return (
      <Status {...props} toolTipMessage="Assigned and out with the customer">
        <AssignedOut />
      </Status>
    );

  if (status === ReplacementVehicleStatus.AssignedReturned)
    return (
      <Status {...props} toolTipMessage="Assigned and now returned">
        <AssignedReturned />
      </Status>
    );

  if (status === ReplacementVehicleStatus.IsRequired)
    return (
      <Status {...props} toolTipMessage="Required">
        <IsRequired />
      </Status>
    );

  if (status === ReplacementVehicleStatus.NotRequired)
    return (
      <Status {...props} toolTipMessage="Not Required">
        <NotRequired />
      </Status>
    );

  logger.warn(`Unknown ReplacementVehicleStatus ${status}`);
  return <div></div>;
}
