import {
  Column,
  DataTypeProvider,
  DataTypeProviderProps,
  Row,
  TableSummaryRow,
} from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import { Heading, Text } from 'components/Typography';
import { BackButton, PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import {
  Label,
  MainPageContainer,
  PageHeader,
  UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { useInMobile } from 'core/Theming/Device';
import dayjs from 'dayjs';
import { useSMSReport } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useReportParams } from '../hooks';
import { convertToDate } from 'pages/hooks';
import InfoText from 'components/InfoText';
import { GrayBackdrop, Loader } from 'components/Loader';
import { ReportTitleInfo } from 'components/ReportTitleInfo';
import { formatDate } from 'utils/helpers';

const SummaryItemComponent = (data: TableSummaryRow.ItemProps) => {
  return (
    <Text fontSize="m" fontWeight="bold">
      Total Messages: {data.value}
    </Text>
  );
};

interface Props {
  reportPath: string;
}

export function SMSReport({ reportPath }: Props) {
  const isMobile = useInMobile();
  const history = useHistory();
  const location = useLocation();
  const urlParams = useReportParams(location.search);
  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParams.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParams.endDate) || null
  );

  const queryEnabled = !!urlParams.startDate && !!urlParams.endDate;
  const { reportData, isReportFetching } = useSMSReport(
    {
      startDate: urlParams.startDate,
      endDate: urlParams.endDate,
    },
    queryEnabled
  );

  const dateToString = (date: Date | null) => dayjs(date).format('YYYY-MM-DD');

  type DateFormatterProps = DataTypeProvider.ValueFormatterProps;
  const DateFormatter = ({ value }: DateFormatterProps) => {
    return <div>{formatDate(value, 'L')}</div>;
  };
  const DateTypeProvider: React.ComponentType<DataTypeProviderProps> = (
    props: DataTypeProviderProps
  ) => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

  const rows = useMemo<Row>(() => reportData, [reportData]);
  const [columns] = useState<Column[]>([
    { name: 'repairerName', title: 'Repairer Name' },
    { name: 'schemeCode', title: 'Scheme Code' },
    { name: 'claimReference', title: 'Claim Ref.' },
    { name: 'regMark', title: 'Vehicle Reg.' },
    { name: 'messageType', title: 'Message Type' },
    { name: 'messageDescription', title: 'Message Description' },
    { name: 'messageDate', title: 'Message Date' },
    { name: 'messageTime', title: 'Message Time' },
    { name: 'smsBody', title: 'SMS Body' },
  ]);
  const [tableColumnExtensions] = useState([
    { columnName: 'repairerName', wordWrapEnabled: true },
    { columnName: 'schemeCode', wordWrapEnabled: true },
    { columnName: 'claimReference', wordWrapEnabled: true },
    { columnName: 'regMark', wordWrapEnabled: true },
    { columnName: 'messageType', wordWrapEnabled: true },
    { columnName: 'messageDescription', wordWrapEnabled: true },
    { columnName: 'messageDate', wordWrapEnabled: true },
    { columnName: 'messageTime', wordWrapEnabled: true },
    { columnName: 'smsBody', wordWrapEnabled: true },
  ]);
  const [summaryItems] = useState([
    { columnName: 'repairerName', type: 'count' },
  ]);

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.startDate, urlParams.endDate]);

  return (
    <MainPageContainer
      isLoading={isReportFetching}
      direction="column"
      wrap="nowrap"
    >
      <GrayBackdrop open={isReportFetching}>
        <Loader />
      </GrayBackdrop>
      <UpperInfoContainer direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Reports" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>
            <ReportTitleText>
              <div>SMS Report</div>
              <ReportTitleInfo
                message="Displays the SMS report for the date range provided"
                title="SMS Report"
              />
            </ReportTitleText>
          </Heading>
        </PageHeader>
        <CustomForm onSubmit={handleSubmit}>
          <Container direction="row">
            <InputButton md={3} sm={4} xs={12}>
              <Label>Start Date</Label>
              <DatePickerField
                onChange={(date) => setStartDate(date)}
                value={startDate}
                maxDate={endDate}
              />
            </InputButton>
            <InputButton md={3} sm={4} xs={12}>
              <Label>End Date</Label>
              <DatePickerField
                onChange={(date) => setEndDate(date)}
                value={endDate}
                minDate={startDate}
              />
            </InputButton>
            <GridItem md={1} sm={1} xs={2}>
              <Button type="submit" disabled={!startDate || !endDate}>
                {renderSearchIcon()}
                {renderSearchButtonText()}
              </Button>
            </GridItem>
          </Container>
        </CustomForm>
      </UpperInfoContainer>

      {!isReportFetching && rows?.length === 0 && (
        <CustomInfoText>
          {"Sorry, we couldn't find any reports with this search criteria."}
        </CustomInfoText>
      )}

      <ReportGridContainer direction="column">
        {rows?.length > 0 && (
          <ReportGrid
            rows={rows}
            columns={columns}
            title="smsReport"
            summaryItems={summaryItems}
            dateColumnsName={['messageDate']}
            DateTypeProvider={DateTypeProvider}
            SummaryItemComponent={SummaryItemComponent}
            tableColumnExtensions={tableColumnExtensions}
            headerTitleForSheet="SMS Report"
            reportHeaderTagLine={`Report produced on ${new Date().toLocaleString()} for period ${startDate?.toLocaleDateString()} to ${endDate?.toLocaleDateString()}`}
          />
        )}
      </ReportGridContainer>
    </MainPageContainer>
  );
  function renderSearchIcon() {
    if (isMobile) return <Search />;
    return null;
  }

  function renderSearchButtonText() {
    if (!isMobile) return 'View';
    return null;
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!startDate || !endDate) return;
    navigateToReport();
  }
  function navigateToReport() {
    history.replace(
      createReportURL(
        { startDate: dateToString(startDate), endDate: dateToString(endDate) },
        reportPath
      )
    );
  }
}

const InputButton = styled(GridItem)`
      padding-right: ${({ theme }) => theme.margin.s};
      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          margin - bottom: 6px;
      }
      & svg {
          width: 20px;
      height: 20px;
      color: ${({ theme }) => theme.palette.secondary.main};
      }
  `;

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const ReportGridContainer = styled(Container)`
  padding-left: ${({ theme }) => theme.padding.xl};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;

const CustomInfoText = styled(InfoText)`
  margin-top: 0;
`;

const ReportTitleText = styled('div')`
  display: flex;
`;
