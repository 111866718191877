import { Column, Row } from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Container } from 'components/Layout';
import { MainPageContainer } from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { AdminDataAccessProfileParams } from 'core/routes';
import {
  ISiteCodeOnSuccess,
  useGetALLSiteCodeFormalCode,
  useRemoveSiteCodeFormalCodeAsign,
} from 'pages/hooks';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import InfoText from 'components/InfoText';
import { GrayBackdrop, Loader } from '../../components/Loader';
import PrimaryToast from 'components/PrimaryToast';

interface IFormalCodeObj {
  siteCode: string;
  schemeCode: string;
}

export function DataAccessReport() {
  const { id } = useParams<AdminDataAccessProfileParams>();
  const dataAccessProfileId = Number(id);
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'warning' | 'success';
  }>();
  const {
    allSiteCodeFormalCode,
    refetchAllSiteCodeFormalCode,
    isAllSiteCodeLoading,
  } = useGetALLSiteCodeFormalCode(dataAccessProfileId);

  const { removeSiteCodeFormalCodeAssign, isRemoving } =
    useRemoveSiteCodeFormalCodeAsign({
      onSuccess: onSuccessResponse,
    });

  const isBusy = isRemoving || isAllSiteCodeLoading;

  const [columns] = useState<Column[]>([
    { name: 'schemeCode', title: 'Formal/Scheme Code' },
    { name: 'siteCode', title: 'Site Code' },
  ]);

  const rows = useMemo<Row>(
    () => allSiteCodeFormalCode,
    [allSiteCodeFormalCode]
  );

  return (
    <MainPageContainer direction="column" wrap="nowrap">
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      {rows && rows?.length == 0 && (
        <CustomInfoText>
          {'Sorry, there are no data access details for this profile'}
        </CustomInfoText>
      )}
      <ReportGridContainer direction="column">
        {rows && rows?.length > 0 && (
          <ReportGrid
            isRemovable={true}
            columns={columns}
            rows={rows}
            title="Data Access Report"
            removeMethodName={onRemove}
          />
        )}
      </ReportGridContainer>
      {toastState && (
        <PrimaryToast
          message={toastState.message}
          isOpen={toastState.isOpen}
          severity={toastState.severity}
          onClose={() => setToastState({ ...toastState, isOpen: false })}
        />
      )}
    </MainPageContainer>
  );

  function deleteRows(deleted: (string | number)[]) {
    const rowsForDelete = rows.slice();
    let deletedRowObj = {} as IFormalCodeObj;

    deleted?.forEach((rowId) => {
      deletedRowObj = rowsForDelete[rowId];
    });
    return deletedRowObj;
  }

  function onRemove(deleted: (string | number)[]) {
    const currentObj: IFormalCodeObj = deleteRows(deleted);
    // eslint-disable-next-line no-empty
    removeSiteCodeFormalCodeAssign({
      dataAccessProfileId,
      siteCode: currentObj?.siteCode?.trim(),
      formalCode: currentObj?.schemeCode?.trim(),
    });
  }

  function onSuccessResponse({
    response,
    siteCode,
    formalCode,
  }: ISiteCodeOnSuccess) {
    response?.status !== 204
      ? setToastState({
          isOpen: true,
          message:
            `Couldn't remove Site code(${siteCode}) ` +
            `and fomalCode(${formalCode}) to data access profile`,
          severity: 'warning',
        })
      : setToastState({
          isOpen: true,
          message:
            `Site code and Formal code pair ` +
            `(${siteCode}/${formalCode}) removed successfully`,
          severity: 'success',
        });
    refetchAllSiteCodeFormalCode();
  }
}

const ReportGridContainer = styled(Container)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
  padding-left: ${({ theme }) => theme.padding.xs};
  margin-top: ${({ theme }) => theme.padding.xs};
`;

const CustomInfoText = styled(InfoText)`
  margin-top: 0;
`;
