import { Fragment, ReactElement, useEffect, ReactNode } from 'react';
import { User, UserManager } from 'oidc-client';
import store from 'core/store';
import { EnhancedStore } from '@reduxjs/toolkit';
import { userAccessTokenExpired, userLoggedin } from 'core/store/auth';
import {
  clearUserManagerUserState,
  localStorageSelectedProfileKey,
  signout,
  switchUserProfile,
} from './userService';
import { logger } from 'core/logger';

interface AuthProviderProps {
  userManager: UserManager;
  children: ReactNode;
  store: EnhancedStore;
}

export default function AuthProvider({
  userManager,
  children,
}: AuthProviderProps): ReactElement {
  useEffect(() => {
    const onUserLoaded = (user: User) => {
      logger.debug(`UserManager -> user loaded`);
      store.dispatch(
        userLoggedin({
          accessToken: user.access_token,
          name: user.profile.name,
          email: user.profile.email,
        })
      );
      const profileId = localStorage.getItem(localStorageSelectedProfileKey);
      if (profileId) {
        switchUserProfile(Number(profileId));
      }
    };

    const onUserUnloaded = () => {
      logger.debug(`UserManager -> user unloaded`);
    };

    const onAccessTokenExpiring = () => {
      logger.debug(`UserManager -> user token expiring`);
    };

    const onAccessTokenExpired = () => {
      logger.debug(`UserManager -> user token expired`);
      clearUserManagerUserState();
      store.dispatch(userAccessTokenExpired());
      signout('TokenExpired');
    };

    const onUserSignedOut = () => {
      logger.debug(`UserManager -> user signed out`);
    };

    // events for user
    userManager.events.addUserLoaded(onUserLoaded);
    userManager.events.addUserUnloaded(onUserUnloaded);
    userManager.events.addAccessTokenExpiring(onAccessTokenExpiring);
    userManager.events.addAccessTokenExpired(onAccessTokenExpired);
    userManager.events.addUserSignedOut(onUserSignedOut);

    // Specify how to clean up after this effect:
    return function cleanup() {
      userManager.events.removeUserLoaded(onUserLoaded);
      userManager.events.removeUserUnloaded(onUserUnloaded);
      userManager.events.removeAccessTokenExpiring(onAccessTokenExpiring);
      userManager.events.removeAccessTokenExpired(onAccessTokenExpired);
      userManager.events.removeUserSignedOut(onUserSignedOut);
    };
  }, [userManager]);

  return <Fragment>{children}</Fragment>;
}
