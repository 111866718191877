import {
    Column,
    Row,
    DataTypeProvider,
    DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import { Heading } from 'components/Typography';
import { BackButton, PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import { Select } from 'components/Select';
import MultiSelect from 'components/MultiSelect';
import {
    Label,
    MainPageContainer,
    PageHeader,
    UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { useInMobile } from 'core/Theming/Device';
import dayjs from 'dayjs';
import { useGetMeasureFuture30report, useGetMeasureRolling30Report, useGetRepairerGroup, useGetRepairerByGroupName, useGetStreams } from 'pages/hooks';
import { FormEvent, useMemo, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { formatDate } from 'utils/helpers';
import InfoText from 'components/InfoText';
import { GrayBackdrop, Loader } from 'components/Loader';
import { ReportTitleInfo } from 'components/ReportTitleInfo';
import { Workbook, CellValue } from 'exceljs';
import { Measure30Result } from '../../../api/resources/models/AutoGenerated';

interface Props {
    reportPath: string;
}

type DateFormatterProps = DataTypeProvider.ValueFormatterProps;

interface Props {
    reportPath: string;
}

const DateFormatter = ({ value }: DateFormatterProps) => (
    <div>{formatDate(value)}</div>
);

const DateTypeProvider: React.ComponentType<DataTypeProviderProps> = (
    props: DataTypeProviderProps
) => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

export function Rolling30DayReport({ reportPath }: Props) {
    const isMobile = useInMobile();
    const history = useHistory();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const urlParams = {
        runDate: urlSearchParams.get('runDate'),
        repairGroup: urlSearchParams.get('repairGroup'),
        siteCode: urlSearchParams.get('siteCode'),
        streams: urlSearchParams.get('streams'),
    };
    const [runDate, setRunDate] = useState<Date | null>(null);

    const [repairerGroup, setRepairerGroup] = useState<string | null>(urlParams.repairGroup || null);
    const [siteCode, setSiteCode] = useState<string[]>(urlParams.siteCode?.split(', ') || []);
    const [streams, setStreams] = useState<string[]>(urlParams.streams?.split(', ') || []);

    const queryEnabled =
        !!urlParams.runDate &&
        !!urlParams.repairGroup && 
        !!urlParams.streams && 
        !!urlParams.siteCode;

    const dateToString = (date: Date | null) => dayjs(date).format('YYYY-MM-DD');

    const { repairerGroupList, isLoading } = useGetRepairerGroup();
    const { streamsList, isStramsLoading } = useGetStreams((list: string[]) => {
        setStreams([...list]);
    });
    const { siteCodes, isFetching } = useGetRepairerByGroupName(repairerGroup || "", repairerGroup !== null, (list: string[]) => {
        setSiteCode([...list]);
    });

    const repairerGroupOptions = repairerGroupList?.map((item, index) => {
        return ({ id: index, value: item.groupName, text: item.groupName })
    });

    const siteCodesOptions = siteCodes?.map((item, index) => {
        return ({ id: index, value: item.formalSiteCode, text: item.repairerName })
    });

    const streamsOptions = streamsList?.map((item, index) => {
        return ({ id: index, value: item.wpMasterId.toString(), text: item.wpDescription })
    });

    useEffect(() => {
        navigateIfPossible();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        urlParams.runDate,
        urlParams.repairGroup,
        urlParams.siteCode,
        urlParams.streams,
      ]);

    useEffect(() => {
        setSiteCode([...siteCodesOptions?.map(a=>a.value) || []]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repairerGroup]);
    
    const { measureRolling30ReportData, isRollingReportFetching, isRollingReportFetched } =
        useGetMeasureRolling30Report(
            {
                runDate: urlParams.runDate,
                repairerGroup: urlParams.repairGroup,
                siteCodes: urlParams.siteCode,
                wpMasterIds: urlParams.streams,
                rollingMeasureCodes: '3,4',
            },
            queryEnabled
        );

    const { measureFuture30ReportData, isReportFetching, isFetched } =
        useGetMeasureFuture30report(
            {
                runDate: urlParams.runDate,
                repairerGroup: urlParams.repairGroup,
                siteCodes: urlParams.siteCode,
                wpMasterIds: urlParams.streams,
                rollingMeasureCodes: '2,3,4,5',
            },
            queryEnabled
        );
    
    const [tableColumnExtensions] = useState([
        { columnName: 'measureName', wordWrapEnabled: true },
    ]);

    const [rollingTableColumnExtensions] = useState([
        { columnName: 'measureName', wordWrapEnabled: true },
    ]);
    
    const [rolling30Dayscolumns, setRolling30Dayscolumns] = useState<Column[]>();
    useEffect(() => {
        const columnsList:Column[] = [];
        columnsList.push({name:'measureName', title:"Measure Name"})
        for (let index = 0; index <= 31; index++) {
            if (runDate) {
                columnsList.push({ name: `c${index}`, title: dayjs(new Date(runDate)).add(index , 'day').format('DD MMM') });
            }
        }
        setRolling30Dayscolumns(columnsList);
    }, [runDate]);

    const rolling30Daysrows = useMemo<Row>(() => measureRolling30ReportData || [], [measureRolling30ReportData]);

    const [columns, setColumns] = useState<Column[]>();
    useEffect(() => {
        const columnsList:Column[] = [];
        columnsList.push({name:'measureName', title:"Measure Name"})
        for (let index = 0; index <= 31; index++) {
            if (runDate) {
                columnsList.push({ name: `c${index}`, title: dayjs(new Date(runDate)).add(1, 'month').add(index , 'day').format('DD MMM') });
            }
        }

        setColumns(columnsList);

    }, [runDate]);

    const rows = useMemo<Row>(() => measureFuture30ReportData || [], [measureFuture30ReportData]);

    const handleNewSheet = async (
        workbook: Workbook
      ) => {
        const mainSheet = workbook.getWorksheet('Main');
        mainSheet.addRow({});
        let lastRowIndex = mainSheet.getSheetValues().length;

        columns?.forEach((item, index) => {
            mainSheet.getRow(lastRowIndex).getCell(index + 1).value = item.title as CellValue;
        });
        lastRowIndex++;
        measureFuture30ReportData?.forEach((element: Measure30Result) => {
            let colIndex = 1;
            Object.keys((element)).map(key => {
                mainSheet.getRow(lastRowIndex).getCell(colIndex).value = element[key as (keyof Measure30Result)] as CellValue;
                colIndex++;
            })
            lastRowIndex++;    
        });
        
      }


    const isBusy = isReportFetching || isFetching || isLoading || isStramsLoading;
    return (
        <MainPageContainer
            isLoading={isBusy}
            direction="column"
            wrap="nowrap"
        >
            <GrayBackdrop open={isBusy}>
                <Loader />
            </GrayBackdrop>
            <UpperInfoContainer direction="column">
                <GridItem>
                    <Container>
                        <BackButton text="Back to Reports" />
                    </Container>
                </GridItem>
                <PageHeader>
                    <Heading>
                        <ReportTitleText>
                            <div>Repairer Capacity Report</div>
                            <ReportTitleInfo
                                message="Provides detailed information regarding a repairers historic performance against their future workload"
                                title="Repairer Capacity Report"
                            />
                        </ReportTitleText>
                    </Heading>
                </PageHeader>
                <CustomForm onSubmit={handleSubmit}>
                    <CustomGridContainer direction="row">
                        <InputButton md={3} sm={3} xs={12}>
                            <Label>Run Date</Label>
                            <DatePickerField
                                onChange={(date) => setRunDate(date)}
                                value={runDate}
                            />
                        </InputButton>
                        <InputButton md={3} sm={3} xs={12}>
                            <Label>Repairer Group</Label>
                            <Select
                                options={repairerGroupOptions || []}
                                selectedId={
                                    repairerGroupOptions?.find(
                                      (e: { value: string | null }) => e.value == repairerGroup
                                    )?.id
                                }
                                onChange={(item) => {
                                    setRepairerGroup(item.value);
                                    setSiteCode([]);
                                }}
                            />
                        </InputButton>
                        <GridItem md={1} sm={1} xs={2}>
                            <Button type="submit" disabled={!runDate || !repairerGroup || (repairerGroup !== 'Select All' && siteCode.length <= 0) || streams.length <= 0}>
                                {renderSearchIcon()}
                                {renderSearchButtonText()}
                            </Button>
                        </GridItem>
                    </CustomGridContainer>
                    <Container direction="row" alignItems='center'>
                        <InputButton md={3} sm={3} xs={12}>
                            <Label>Select Sites</Label>
                            <MultiSelect
                                key={'sites'}
                                options={siteCodesOptions || []}
                                value={siteCode}
                                onChange={(value, checked)=> {
                                    if (value === "All") {
                                        if(checked){
                                            setSiteCode(siteCodesOptions?.map(a=>a.value) || []);
                                        }else{
                                            setSiteCode([]);
                                        }
                                    }else{
                                        if (siteCode.includes(value)) {
                                            setSiteCode((r)=>[...r.filter(a=>a !== value)]);
                                        }else{
                                            setSiteCode((r)=>[...r,value]);
                                        }
                                    }
                                }}
                                disabled={repairerGroup === "Select All"}
                                variant="outlined"
                            />
                        </InputButton>
                        <InputButton md={3} sm={3} xs={12}>
                            <Label>Select Streams</Label>
                            <MultiSelect
                                key={'streams'}
                                options={streamsOptions || []}
                                value={streams}
                                onChange={(value, checked)=> {
                                    if (value === "All") {
                                        if(checked){
                                            setStreams(streamsOptions?.map(a=>a.value) || []);
                                        }else{
                                            setStreams([]);
                                        }
                                    }else{
                                        if (streams.includes(value)) {
                                            setStreams((r)=>[...r.filter(a=>a !== value)]);
                                        }else{
                                            setStreams((r)=>[...r,value]);
                                        }
                                    }
                                }}
                                variant="outlined"
                            />
                        </InputButton>
                    </Container>
                </CustomForm>
            </UpperInfoContainer>
            {isFetched && !isReportFetching && rows && rows?.length === 0 && rolling30Daysrows && rolling30Daysrows?.length === 0 && (
                <CustomInfoText>
                    {"Sorry, we couldn't find any reports with this search criteria."}
                </CustomInfoText>
            )}
            <ReportGridContainer direction="column">
                {isRollingReportFetched && !isRollingReportFetching && rolling30Daysrows && rolling30Daysrows?.length > 0 && (
                    <ReportGrid
                        columns={rolling30Dayscolumns || []}
                        tableColumnExtensions={rollingTableColumnExtensions}
                        rows={rolling30Daysrows}
                        DateTypeProvider={DateTypeProvider}
                        title="Rolling30DayReport"
                        headerTitleForSheet="Capacity Report"
                        handleNewSheet={handleNewSheet}
                        reportHeaderTagLine={`Report produced on ${new Date().toLocaleString()}`}
                        disabledSorting
                        disabledGrouping
                        disableDragAndDrop
                        disabledTableSelection
                    />
                )}
            </ReportGridContainer>
            <ReportGridContainer direction="column">
                {isFetched && !isReportFetching && rows && rows?.length > 0 && (
                    <ReportGrid
                        columns={columns || []}
                        tableColumnExtensions={tableColumnExtensions}
                        rows={rows}
                        DateTypeProvider={DateTypeProvider}
                        title="Rolling30DayReport"
                        disabledSorting
                        disabledGrouping
                        hideExportPanel
                        disableDragAndDrop
                        disabledTableSelection
                    />
                )}
            </ReportGridContainer>
        </MainPageContainer>
    );
    function renderSearchIcon() {
        if (isMobile) return <Search />;
        return null;
    }

    function renderSearchButtonText() {
        if (!isMobile) return 'View';
        return null;
    }

    function handleSubmit(event: FormEvent) {
        event.preventDefault();
        navigateIfPossible();
        // reFetchMeasureFuture30ReportData().then(()=> {
        //     reFetchMeasureRolling30ReportData().then(() => setQueryEnabled(false))
        // });
    }

    function navigateIfPossible() {
        if (!!runDate && !!repairerGroup && !!siteCode && !!streams) {
            history.replace(
                createReportURL(
                    {
                        runDate: dateToString(runDate),
                        repairGroup: repairerGroup,
                        siteCode: siteCode.join(', '),
                        streams: streams.join(', '),
                    },
                    reportPath
                )
            );
        }else return;
        
    }
}

const InputButton = styled(GridItem)`
      padding-right: ${({ theme }) => theme.margin.s};
      max-width: 330px !important;
      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
          margin-bottom: 6px;
      }
      & svg {
          width: 20px;
      height: 20px;
      color: ${({ theme }) => theme.palette.secondary.main};
      }
  `;

const CustomForm = styled.form`
    margin-bottom: ${({ theme }) => theme.margin.l};
  `;

const Button = styled(PrimaryButton)`
    min-width: 40px;
    font-size: ${({ theme }) => theme.fontSize.s};
    height: 40px;
    padding: 12px;
    margin-top: 20.5px;
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      width: 100%;
    }
  `;

const ReportGridContainer = styled(Container)`
    padding-left: ${({ theme }) => theme.padding.xl};
    margin-bottom: ${({ theme }) => theme.padding.xl};
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      padding: 0 20px;
    }
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      padding: 0 15px;
    }
  `;
const CustomGridContainer = styled(Container)`
    margin-bottom: ${({ theme }) => theme.margin.m};;
`;
const CustomInfoText = styled(InfoText)`
    margin-top: 0;
  `;
const ReportTitleText = styled('div')`
    display: flex;
`;