import { Menu, MenuItem } from '@mui/material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Text } from 'components/Typography';
import styled from '@emotion/styled';
import { signout } from 'core/auth/userService';
import { User } from 'api/resources/models/AutoGenerated';

interface UserMenuProps {
  anchorEl: HTMLElement | null;
  onClose: VoidFunction;
  onOpenProfileClick: VoidFunction;
  selectedProfileName: string;
  user: User;
}

export const UserMenu = ({
  anchorEl,
  onClose,
  onOpenProfileClick,
  selectedProfileName,
  user,
}: UserMenuProps) => {
  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onClose}
    >
      <SelectedProfile>{selectedProfileName}</SelectedProfile>
      {user.userAccountProfiles.length > 0 && (
        <StyledMenuItem onClick={onOpenProfileClick}>
          <SupervisedUserCircleIcon />
          <Text>Switch Profile</Text>
        </StyledMenuItem>
      )}
      <StyledMenuItem onClick={logout}>
        <ExitToAppIcon />
        <Text>Log Out</Text>
      </StyledMenuItem>
    </Menu>
  );

  function logout() {
    signout();
  }
};

const SelectedProfile = styled.div`
  background-color: ${({ theme }) => theme.palette.button.light};
  color: ${({ theme }) => theme.palette.secondary.main};
  padding: 8px 15px 7px 15px;
  margin-top: -8px;
  height: 32px;
  font-size: ${({ theme }) => theme.fontSize.s};
  display: none;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    display: flex;
  }
`;
const StyledMenuItem = styled(MenuItem)`
  align-items: center;
  width: 200px;
  padding: ${({ theme }) => theme.padding.s};
  & p {
    margin-left: ${({ theme }) => theme.margin.s};
  }
`;
