import { Permit, Repair } from 'api/resources/models/AutoGenerated';

export interface VehicleTableHeader {
  id: keyof Repair;
  label: string;
  permitCode?: Permit;
}

export const tableHeaders: VehicleTableHeader[] = [
  { id: 'repairCode', label: 'Repair Code' },
  { id: 'claimReference', label: 'Claim Ref.' },
  {
    id: 'vehicleReg',
    label: 'Vehicle Reg.',
    permitCode: Permit.FLV_VEHICLE,
  },
  {
    id: 'schemeCode',
    label: 'Scheme Code',
    permitCode: Permit.FLV_STREAM,
  },
  {
    id: 'formalStatus',
    label: 'Status',
    // permitCode: Permit.FLV_FORMAL_STATUS,
  },
  {
    id: 'dtgCreated',
    label: 'Created',
    permitCode: Permit.FLV_CREATED_DATE,
  },
  { id: 'dtgUpdated', label: 'Last Updated' },
  { id: 'workInProgressCode', label: 'Work in Progress Code' },
  {
    id: 'replacementVehicleStatus',
    label: 'Replacement Vehicle',
    permitCode: Permit.FLV_CCAR,
  },
  {
    id: 'dtgEcd',
    label: 'E.C.D.',
    permitCode: Permit.FLV_ECD,
  },
  {
    id: 'diaryDate',
    label: 'Diary Date',
    permitCode: Permit.FLV_DIARY_DATE,
  },
  {
    id: 'dtgCpd',
    label: 'C.P.D.',
    permitCode: Permit.FLV_CPD,
  },
  { id: 'dtgCompleted', label: 'Completed' },
  { id: 'dtgDelivered', label: 'Delivered' },
  {
    id: 'vehicleMake',
    label: 'Manufacturer',
    permitCode: Permit.FLV_VEHICLE,
  },
  { id: 'vehicleModel', label: 'Model', permitCode: Permit.FLV_VEHICLE },
  {
    id: 'repairerGroup',
    label: 'Group',
    permitCode: Permit.FLV_REPAIRER_GROUP,
  },
];
