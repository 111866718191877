import { Column, Row } from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import { Heading, Subheading, Text } from 'components/Typography';
import { BackButton, PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import {
  Label,
  MainPageContainer,
  PageHeader,
  UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { useInMobile } from 'core/Theming/Device';
import dayjs from 'dayjs';
import { useRepairStatusReportCovea } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useReportParams } from '../hooks';
import { convertToDate } from 'pages/hooks';
import InfoText from 'components/InfoText';
import { GrayBackdrop, Loader } from 'components/Loader';
import {
  Chart,
  BarSeries,
  ArgumentAxis,
  ValueAxis,
  Legend,
} from '@devexpress/dx-react-chart-material-ui';
import { Stack } from '@devexpress/dx-react-chart';
import { Workbook, Worksheet } from 'exceljs';
import domtoimage from 'dom-to-image';
import { convertBlobToBase64 } from '../../../utils/helpers';
import { ReportTitleInfo } from 'components/ReportTitleInfo';

const rootChartContainerId = 'chart-container';

interface Props {
  reportPath: string;
}
interface LabelProps {
  text: string | number;
}

export function CoveaReport({ reportPath }: Props) {
  const isMobile = useInMobile();
  const history = useHistory();
  const location = useLocation();
  const urlParams = useReportParams(location.search);

  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParams.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParams.endDate) || null
  );

  const dateToString = (date: Date | null) => dayjs(date).format('YYYY-MM-DD');

  const queryEnabled = !!urlParams.startDate && !!urlParams.endDate;
  const { reportData, isReportFetching, isFetched } =
    useRepairStatusReportCovea(
      {
        startDate: urlParams.startDate,
        endDate: urlParams.endDate,
      },
      queryEnabled
    );

  const rows = useMemo<Row>(
    () => reportData?.repairSatus || [],
    [reportData?.repairSatus]
  );

  const [tableColumnExtensions] = useState([
    { columnName: 'repairCode', width: '13%', wordWrapEnabled: true },
    { columnName: 'repairer', width: '15%', wordWrapEnabled: true },
    { columnName: 'regmark', width: '10%', wordWrapEnabled: true },
    { columnName: 'claimReference', width: '10%', wordWrapEnabled: true },
    { columnName: 'onSiteDate', width: '10%', wordWrapEnabled: true },
    { columnName: 'smsSentDate', width: '10%', wordWrapEnabled: true },
    { columnName: 'termsAcceptedDate', width: '10%', wordWrapEnabled: true },
    { columnName: 'numberOfHits', width: '10%', wordWrapEnabled: true },
  ]);

  const [columns] = useState<Column[]>([
    { name: 'repairCode', title: 'Repair Code' },
    { name: 'repairer', title: 'Repairer Name' },
    { name: 'regmark', title: 'Vehicle Reg' },
    { name: 'claimReference', title: 'Claim Reference' },
    { name: 'onSiteDate', title: 'On Site Date' },
    { name: 'smsSentDate', title: 'SMS Sent Date' },
    { name: 'termsAcceptedDate', title: 'Terms Accepted Date' },
    { name: 'numberOfHits', title: 'Number of Hits' },
  ]);
  const chartData = useMemo(
    () => [
      {
        state: 'Summary',
        totalNotifications: reportData?.mobileCount?.recs,
        totalRepairs: reportData?.mobileCount?.repairCount,
        totalMobileNumberSupplied: reportData?.mobileCount?.mobileNumbers,
      },
    ],
    [reportData]
  );

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.startDate, urlParams.endDate]);

  const SpecialMarkerComponent = ({
    name,
    color,
  }: {
    [key: string]: unknown;
  }) => {
    return (
      <svg
        id={typeof name == 'string' ? name : ''}
        width="25"
        height="15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="25"
          height="15"
          fill={typeof color == 'string' ? color : ''}
        />
      </svg>
    );
  };

  const SpecialLabelComponent = ({ text }: LabelProps) => {
    return (
      <ChartLegendText noWrap fontSize="s" color="black">
        {text}
      </ChartLegendText>
    );
  };

  const handleNewSheet = async (
    workbook: Workbook,
    customizeHeader: (worksheet: Worksheet) => void,
    headerImageId: number
  ) => {
    const graphSheet = workbook.addWorksheet('Repair status usage summary', {
      properties: { defaultColWidth: 20 },
    });
    const newGraphSheet = workbook.getWorksheet('Repair status usage summary');

    customizeHeader(newGraphSheet);
    const chart = document.querySelector(`#${rootChartContainerId}`) as Node;
    const blobData = await domtoimage.toBlob(chart);
    const base64String = await convertBlobToBase64(blobData);

    newGraphSheet.mergeCells(2, 1, 2, 7);
    newGraphSheet.getRow(2).getCell(1).alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };
    newGraphSheet.getRow(2).getCell(1).font = { bold: true, size: 12 };
    newGraphSheet.getRow(2).getCell(1).value = 'Notification summary';

    const generalStyles = {
      font: { bold: true },
      alignment: { horizontal: 'left' },
    };

    for (let rowIndex = 3; rowIndex < 5; rowIndex += 1) {
      newGraphSheet.mergeCells(rowIndex, 1, rowIndex, 2);
      newGraphSheet.mergeCells(rowIndex, 3, rowIndex, 4);
      newGraphSheet.mergeCells(rowIndex, 5, rowIndex, 5);
      Object.assign(newGraphSheet.getRow(rowIndex).getCell(1), generalStyles);
      Object.assign(newGraphSheet.getRow(rowIndex).getCell(3), generalStyles);
      Object.assign(newGraphSheet.getRow(rowIndex).getCell(5), generalStyles);
    }
    newGraphSheet.getRow(3).getCell(1).value = 'Total notifications';
    newGraphSheet.getRow(3).getCell(3).value = 'Total repairs';
    newGraphSheet.getRow(3).getCell(5).value = 'Total mobile numbers supplied';
    newGraphSheet.getRow(4).getCell(1).value =
      reportData?.mobileCount?.recs?.toString() || '';
    newGraphSheet.getRow(4).getCell(3).value =
      reportData?.mobileCount?.repairCount?.toString() || '';
    newGraphSheet.getRow(4).getCell(5).value =
      reportData?.mobileCount?.mobileNumbers?.toString() || '';
    const imageId = workbook.addImage({
      base64: base64String as string,
      extension: 'jpeg',
    });

    newGraphSheet.addImage(imageId, {
      tl: { col: 0, row: 5 },
      ext: { width: 800, height: 250 },
    });

    newGraphSheet.addImage(headerImageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 250, height: 50 },
    });
    graphSheet.state = 'visible';
    workbook.eachSheet((worksheet) => {
      if (worksheet.name === 'Main') {
        worksheet.name = 'Repair Status Usage';
      }
    });
  };

  return (
    <MainPageContainer
      isLoading={isReportFetching}
      direction="column"
      wrap="nowrap"
    >
      <GrayBackdrop open={isReportFetching}>
        <Loader />
      </GrayBackdrop>
      <UpperInfoContainer direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Reports" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>
            <ReportTitleText>
              <div>Repair Status Portal Usage V1</div>
              <ReportTitleInfo
                message="Shows the repair status portal usage."
                title="Repair Status Portal Usage V1"
              />
            </ReportTitleText>
          </Heading>
        </PageHeader>
        <CustomForm onSubmit={handleSubmit}>
          <Container direction="row">
            <InputButton md={3} sm={4} xs={12}>
              <Label>Start Date</Label>
              <DatePickerField
                onChange={(date) => setStartDate(date)}
                value={startDate}
                maxDate={endDate}
              />
            </InputButton>
            <InputButton md={3} sm={4} xs={12}>
              <Label>End Date</Label>
              <DatePickerField
                onChange={(date) => setEndDate(date)}
                value={endDate}
                minDate={startDate}
              />
            </InputButton>
            <GridItem md={1} sm={1} xs={2}>
              <Button type="submit" disabled={!startDate || !endDate}>
                {renderSearchIcon()}
                {renderSearchButtonText()}
              </Button>
            </GridItem>
          </Container>
        </CustomForm>
      </UpperInfoContainer>
      {isFetched && !isReportFetching && rows && rows?.length === 0 && (
        <CustomInfoText>
          {"Sorry, we couldn't find any reports with this search criteria."}
        </CustomInfoText>
      )}
      {isFetched && !isReportFetching && rows && rows?.length > 0 && (
        <>
          <ReportGridContainer direction="column">
            <NotificationGridItem>
              <Subheading>Notification Summary</Subheading>
            </NotificationGridItem>
            <GridItem>
              <Container spacing={4}>
                <GridItem>
                  <Text fontWeight="600" color="#bebdbd" fontSize="s">
                    Total Notifications
                  </Text>
                  <Text fontSize="l">{reportData?.mobileCount?.recs}</Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="600" color="#bebdbd" fontSize="s">
                    Total Repairs
                  </Text>
                  <Text fontSize="l">
                    {reportData?.mobileCount?.repairCount}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="600" color="#bebdbd" fontSize="s">
                    Total Mobile Numbers Supplied
                  </Text>
                  <Text fontSize="l">
                    {reportData?.mobileCount?.mobileNumbers}
                  </Text>
                </GridItem>
              </Container>
            </GridItem>
          </ReportGridContainer>

          <ChartPaper id={rootChartContainerId}>
            <Text fontSize="s" color="#bebdbd">
              Summary of notifications
            </Text>
            <Chart data={chartData} rotated height={200}>
              {/* <ArgumentScale factory={scaleBand} /> */}
              <ArgumentAxis showLine={true} />
              <ValueAxis />

              <BarSeries
                valueField="totalNotifications"
                argumentField="state"
                name="Total Notifications"
                color="#01B8AA"
              />
              <BarSeries
                valueField="totalRepairs"
                argumentField="state"
                name="Total Repairs"
                color="#374649"
              />
              <BarSeries
                valueField="totalMobileNumberSupplied"
                argumentField="state"
                name="Total Mobile Number Supplied"
                color="#FD625E"
              />
              <Legend
                position="top"
                markerComponent={SpecialMarkerComponent}
                labelComponent={SpecialLabelComponent}
              />
              <Stack />
            </Chart>
          </ChartPaper>
        </>
      )}

      <ReportGridContainer direction="column">
        {isFetched && !isReportFetching && rows && rows?.length > 0 && (
          <ReportGrid
            rows={rows}
            columns={columns}
            tableColumnExtensions={tableColumnExtensions}
            title="RepairStatusPortalUsage"
            handleNewSheet={handleNewSheet}
            headerTitleForSheet="Repair Status Usage (v01)"
            reportHeaderTagLine={`Report produced on ${new Date().toLocaleString()} for period ${startDate?.toLocaleDateString()} to ${endDate?.toLocaleDateString()}`}
          />
        )}
      </ReportGridContainer>
    </MainPageContainer>
  );
  function renderSearchIcon() {
    if (isMobile) return <Search />;
    return null;
  }

  function renderSearchButtonText() {
    if (!isMobile) return 'View';
    return null;
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!startDate || !endDate) return;
    navigateToReport();
  }
  function navigateToReport() {
    history.replace(
      createReportURL(
        {
          startDate: dateToString(startDate),
          endDate: dateToString(endDate),
        },
        reportPath
      )
    );
  }
}

const InputButton = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 6px;
  }
  & svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const ReportGridContainer = styled(Container)`
  padding-left: ${({ theme }) => theme.padding.xl};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;

const CustomInfoText = styled(InfoText)`
  margin-top: 0;
`;
const ChartLegendText = styled(Text)`
  margin-left: 5px;
`;
const ChartPaper = styled('div')`
  width: 55vw;
  z-index: 0;
  background: ${({ theme }) => theme.palette.background.default};
  margin-bottom: ${({ theme }) => theme.padding.xl};
  padding-left: ${({ theme }) => theme.padding.xl};
  #top-left {
    display: none;
  }
  #top-container {
    .MuiList-root {
      display: flex;
      width: 800px;
    }
    .MuiListItem-root {
      min-width: 200px;
    }
  }
`;
const NotificationGridItem = styled(GridItem)`
  margin-bottom: ${({ theme }) => theme.margin.l}; ;
`;
const ReportTitleText = styled('div')`
  display: flex;
`;