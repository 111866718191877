import styled from '@emotion/styled';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Container, GridItem } from './Layout';
import { ModalTitle } from './Typography';

export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  title: string;
  children: React.ReactNode;
  onExited?: () => void;
  closeOnEsc?: boolean;
}

export function Modal({
  isOpen,
  onClose,
  title,
  children,
  onExited,
  closeOnEsc = false,
}: ModalProps) {
  return (
    <StyledDialog
      open={isOpen}
      onClose={() => {
        if (closeOnEsc && onClose) return onClose();
        return;
      }}
      TransitionProps={{
        onExited: onExited,
      }}
    >
      <StyledDialogTitle>
        <Container>
          <GridItem xs={11}>
            <ModalTitle>{title}</ModalTitle>
          </GridItem>
          {onClose && (
            <GridItem xs={1}>
              <Container justify="flex-end">
                <StyledIconButton onClick={onClose}>
                  <CloseIcon />
                </StyledIconButton>
              </Container>
            </GridItem>
          )}
        </Container>
      </StyledDialogTitle>

      <Line />
      <Content>{children}</Content>
    </StyledDialog>
  );
}

const StyledDialogTitle = styled(DialogTitle)`
  padding-top: 13px;
  padding-left: 20px;
  padding-bottom: 12px;
  padding-right: 13px;
`;
const StyledIconButton = styled(IconButton)`
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const Line = styled.div`
  border-top: 1px solid;
  border-color: #ddecf4;
`;

const Content = styled(DialogContent)`
  padding: ${({ theme }) =>
    `${theme.padding.m} ${theme.padding.l} ${theme.padding.l} ${theme.padding.l}`};
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    overflow: visible;
  }
`;
