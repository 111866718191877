import { DesktopContainer, MobileContainer } from 'core/Theming/Device';
import { Toast } from './Toast';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  duration?: number;
  severity: 'error' | 'warning' | 'success' | 'info';
  position?: {
    mobile: {
      vertical: 'top' | 'bottom';
      horizontal: 'left' | 'center' | 'right';
    };
    desktop: {
      vertical: 'top' | 'bottom';
      horizontal: 'left' | 'center' | 'right';
    };
  };
}

export default function PrimaryToast({
  isOpen,
  onClose,
  message,
  duration = 5000,
  severity,
  position = {
    mobile: { vertical: 'bottom', horizontal: 'center' },
    desktop: { vertical: 'top', horizontal: 'center' },
  },
}: Props) {
  return (
    <>
      <DesktopContainer>
        <Toast
          message={message}
          isOpen={isOpen}
          duration={duration}
          onClose={onClose}
          severity={severity}
          position={position.desktop}
        />
      </DesktopContainer>
      <MobileContainer>
        <Toast
          message={message}
          isOpen={isOpen}
          duration={duration}
          onClose={onClose}
          severity={severity}
          position={position.mobile}
        />
      </MobileContainer>
    </>
  );
}
