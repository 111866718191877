import {
  Row,
  Column,
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import { Heading } from 'components/Typography';
import { BackButton, PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import {
  Label,
  MainPageContainer,
  PageHeader,
  UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { useInMobile } from 'core/Theming/Device';
import dayjs from 'dayjs';
import { useRepairGroups, useWorkInProgressV3Report } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { convertToDate } from 'pages/hooks';
import InfoText from 'components/InfoText';
import { GrayBackdrop, Loader } from 'components/Loader';
import { ReportTitleInfo } from 'components/ReportTitleInfo';
import { formatDate } from 'utils/helpers';
import { Select } from 'components/Select';

type DateFormatterProps = DataTypeProvider.ValueFormatterProps;
interface Props {
  reportPath: string;
}

const dateToString = (date: Date | null) => dayjs(date).format('YYYY-MM-DD');

const DateFormatter = ({ value }: DateFormatterProps) => {
  return <div>{formatDate(value, 'L')}</div>;
};
const DateTypeProvider: React.ComponentType<DataTypeProviderProps> = (
  props: DataTypeProviderProps
) => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

export function WorkInProgress({ reportPath }: Props) {
  const isMobile = useInMobile();
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    endDate: urlParams.get('endDate'),
    startDate: urlParams.get('startDate'),
    repairGroup: urlParams.get('repairGroup'),
  };

  const queryEnabled =
    !!urlParamsValue.startDate &&
    !!urlParamsValue.endDate &&
    !!urlParamsValue.repairGroup;

  const { repairerGroups, isRepairGroupsLoading } = useRepairGroups();
  const { reportData, isReportFetching } = useWorkInProgressV3Report(
    {
      endDate: urlParamsValue.endDate,
      startDate: urlParamsValue.startDate,
      repairerGroup: urlParamsValue.repairGroup,
    },
    queryEnabled
  );

  const rows = useMemo<Row>(() => reportData, [reportData]);
  const [columns] = useState<Column[]>([
    { name: 'repairerGroup', title: 'Repairer Group' },
    { name: 'repairerName', title: 'Repairer Name' },
    { name: 'activeWebId', title: 'ActiveWeb Id' },
    { name: 'schemeCode', title: 'Scheme Code' },
    { name: 'registration', title: 'Registration' },
    { name: 'claimReference', title: 'Claim Reference' },
    { name: 'status', title: 'Status' },
    { name: 'dtgCreated', title: 'Created Date' },
    { name: 'dtgOnsite', title: 'On Site Date' },
    { name: 'dtgAuthorised', title: 'Authorised Date' },
    { name: 'dtgPartsOrdered', title: 'Parts Ordered Date' },
    { name: 'dtgEstimatedCompletion', title: 'Estimated Completion Date' },
    { name: 'replacementVehicleModel', title: 'Replacement Vehicle' },
    {
      name: 'dtgReplacementVehicleKeysOut',
      title: 'Replacement Vehicle Keys Out Date',
    },
    {
      name: 'replacementVehicleKeysOut',
      title: 'Replacement Vehicle Keys Out',
    },
    { name: 'dtgLastDataUpdate', title: 'Last Data Updated' },
    { name: 'dtgLastStatusChange', title: 'Last Status Change' },
    { name: 'dtgLastActivity', title: 'Last Activity Date' },
    { name: 'lastActivityBy', title: 'Last Activity By' },
    { name: 'dtgLatestPdelay', title: 'Latest PDelay' },
    { name: 'statusAge', title: 'Status Age' },
    { name: 'dataAge', title: 'Data Age' },
    { name: 'lastActivityAge', title: 'Last Activity Age' },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'repairerGroup', wordWrapEnabled: true },
    { columnName: 'repairerName', wordWrapEnabled: true },
    { columnName: 'activeWebId', wordWrapEnabled: true },
    { columnName: 'schemeCode', wordWrapEnabled: true },
    { columnName: 'registration', wordWrapEnabled: true },
    { columnName: 'claimReference', wordWrapEnabled: true },
    { columnName: 'status', wordWrapEnabled: true },
    { columnName: 'dtgCreated', wordWrapEnabled: true },
    { columnName: 'dtgOnsite', wordWrapEnabled: true },
    { columnName: 'dtgAuthorised', wordWrapEnabled: true },
    { columnName: 'dtgPartsOrdered', wordWrapEnabled: true },
    {
      columnName: 'dtgEstimatedCompletion',
      wordWrapEnabled: true,
    },
    { columnName: 'replacementVehicleModel', wordWrapEnabled: true },
    {
      columnName: 'dtgReplacementVehicleKeysOut',
      wordWrapEnabled: true,
    },
    {
      columnName: 'replacementVehicleKeysOut',
      wordWrapEnabled: true,
    },
    { columnName: 'dtgLastDataUpdate', wordWrapEnabled: true },
    { columnName: 'dtgLastStatusChange', wordWrapEnabled: true },
    { columnName: 'dtgLastActivity', wordWrapEnabled: true },
    { columnName: 'lastActivityBy', wordWrapEnabled: true },
    { columnName: 'dtgLatestPdelay', wordWrapEnabled: true },
    { columnName: 'statusAge', wordWrapEnabled: true },
    { columnName: 'dataAge', wordWrapEnabled: true },
    { columnName: 'lastActivityAge', wordWrapEnabled: true },
  ]);

  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParamsValue.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParamsValue.endDate) || null
  );
  const [repairGroup, setRepairGroup] = useState<string | null>(
    urlParamsValue.repairGroup || null
  );
  const options = repairerGroups?.map((e: { groupName: string }, i: number) => {
    return { id: i, value: e.groupName, text: e.groupName };
  });
  const isBusy = isReportFetching || isRepairGroupsLoading;

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlParamsValue.startDate,
    urlParamsValue.endDate,
    urlParamsValue.repairGroup,
  ]);

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <UpperInfoContainer direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Reports" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>
            <ReportTitleText>
              <div>Work In Progress V3 Report</div>
              <ReportTitleInfo
                message="Displays the Work In Progress V3 report for the date range provided"
                title="Work In Progress V3 Report"
              />
            </ReportTitleText>
          </Heading>
        </PageHeader>
        <CustomForm onSubmit={handleSubmit}>
          <Container direction="row">
            <InputButton md={3} sm={4} xs={12}>
              <Label>Start Date</Label>
              <DatePickerField
                onChange={(date) => setStartDate(date)}
                value={startDate}
                maxDate={endDate}
              />
            </InputButton>
            <InputButton md={3} sm={4} xs={12}>
              <Label>End Date</Label>
              <DatePickerField
                onChange={(date) => setEndDate(date)}
                value={endDate}
                minDate={startDate}
              />
            </InputButton>
            <InputButton md={3} sm={4} xs={12}>
              <Label>Repairer Groups</Label>
              <Select
                options={options}
                onChange={(status) => setRepairGroup(status.value)}
                selectedId={
                  options?.find(
                    (e: { value: string | null }) => e.value == repairGroup
                  )?.id
                }
              />
            </InputButton>
            <GridItem md={1} sm={1} xs={2}>
              <Button
                type="submit"
                disabled={!startDate || !endDate || !repairGroup}
              >
                {renderSearchIcon()}
                {renderSearchButtonText()}
              </Button>
            </GridItem>
          </Container>
        </CustomForm>
      </UpperInfoContainer>

      {!isBusy && rows?.length === 0 && (
        <CustomInfoText>
          {"Sorry, we couldn't find any reports with this search criteria."}
        </CustomInfoText>
      )}

      <ReportGridContainer direction="column">
        {rows?.length > 0 && (
          <ReportGrid
            rows={rows}
            columns={columns}
            title="workInProgress"
            dateColumnsName={[
              'dtgCreated',
              'dtgOnsite',
              'dtgAuthorised',
              'dtgPartsOrdered',
              'dtgEstimatedCompletion',
              'dtgReplacementVehicleKeysOut',
              'dtgLastDataUpdate',
              'dtgLastStatusChange',
              'dtgLastActivity',
              'dtgLatestPdelay',
            ]}
            DateTypeProvider={DateTypeProvider}
            tableColumnExtensions={tableColumnExtensions}
            headerTitleForSheet="Work In Progress V3 Report"
            reportHeaderTagLine={`Report produced on ${new Date().toLocaleString()} for period ${startDate?.toLocaleDateString()} to ${endDate?.toLocaleDateString()}`}
          />
        )}
      </ReportGridContainer>
    </MainPageContainer>
  );
  function renderSearchIcon() {
    if (isMobile) return <Search />;
    return null;
  }

  function renderSearchButtonText() {
    if (!isMobile) return 'View';
    return null;
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!!startDate && !!endDate && !!repairGroup)
      return history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
            repairGroup: repairGroup,
          },
          reportPath
        )
      );
    else return;
  }
}

const InputButton = styled(GridItem)`
        padding-right: ${({ theme }) => theme.margin.s};
        @media (max-width: ${({ theme }) =>
          `${theme.breakpoints.values.sm}px`}) {
            margin - bottom: 6px;
        }
        & svg {
            width: 20px;
        height: 20px;
        color: ${({ theme }) => theme.palette.secondary.main};
        }
    `;

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const ReportGridContainer = styled(Container)`
  padding-left: ${({ theme }) => theme.padding.xl};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;

const CustomInfoText = styled(InfoText)`
  margin-top: 0;
`;

const ReportTitleText = styled('div')`
  display: flex;
`;
