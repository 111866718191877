import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { config } from 'core/config';
import loglevel from 'loglevel';

if (process.env.NODE_ENV !== 'test') {
  datadogRum.init({
    applicationId: config.datadogApplicationId,
    clientToken: config.datadogClientToken,
    site: config.datadogSite,
    service: config.datadogService,
    env: config.loggingEnvironment,
    version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
  });

  datadogLogs.init({
    clientToken: config.datadogClientToken,
    site: config.datadogSite,
    service: config.datadogService,
    env: config.loggingEnvironment,
    version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
  });
}
const originalFactory = loglevel.methodFactory;
loglevel.methodFactory = function (methodName, logLevel, loggerName) {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);

  return function (message) {
    switch (methodName) {
      case 'error':
        datadogLogs.logger.error(message);
        break;
      case 'log':
        datadogLogs.logger.log(message);
        break;
      case 'debug':
        datadogLogs.logger.debug(message);
        break;
      case 'info':
        datadogLogs.logger.info(message);
        break;
      case 'warn':
        datadogLogs.logger.warn(message);
        break;
    }
    rawMethod(message);
  };
};

loglevel.setLevel(config.datadogLogLevel);

export const logger = loglevel;
