import { InputAdornment } from '@mui/material';
import { useEffect } from 'react';
import InputSelect, { SelectOption } from 'components/InputSelect';
import { Container, GridItem } from 'components/Layout';
import { TextField } from 'components/TextField';
import { Text } from 'components/Typography';
import {
  ISiteCodeOnSuccess,
  useAddAllSiteCodeForFormalCode,
  useAddSiteCodeFormalCodeAssign,
  useGetALLSiteCodeFormalCode,
  useRemoveSiteCodeFormalCodeAsign,
  useSiteCodeFormalCodePairSearch,
  useSiteCodes,
  useWorkProviderFormalCodes,
} from 'pages/hooks';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { GrayBackdrop, Loader } from 'components/Loader';
import PrimaryToast from 'components/PrimaryToast';
import { SearchResult } from './SearchResult';
import { DataAccessReport } from './DataAccessReport';
import { AxiosError } from 'axios';
import { getBadRequestErrorMessage } from 'api';

export function DataAccessDetails({
  dataAccessProfileId,
}: {
  dataAccessProfileId: number;
}) {
  const [siteCode, setSiteCode] = useState<SelectOption | null>(null);
  const [formalCode, setFormalCode] = useState<SelectOption | null>(null);
  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'warning' | 'success' | 'error';
  }>();

  const isPairSearchEnabled = !!siteCode && !!formalCode;
  const searchConfig = !isPairSearchEnabled
    ? 'disabled'
    : {
        dataAccessProfileId,
        siteCode: siteCode?.value ?? '',
        formalCode: formalCode?.value ?? '',
      };

  const { isSiteCodesLoading } = useSiteCodes();
  const { workProviderFormalCodes, isFormalCodesLoading } =
    useWorkProviderFormalCodes();
  const { searchResult, search, isSearching } = useSiteCodeFormalCodePairSearch(
    searchConfig,
    handleError
  );
  const { removeSiteCodeFormalCodeAssign, isRemoving } =
    useRemoveSiteCodeFormalCodeAsign({ onSuccess: onSuccessResponse });
  const { addSiteCodeFormalCodeAssign, isAdding } =
    useAddSiteCodeFormalCodeAssign();
  const { refetchAllSiteCodeFormalCode, isAllSiteCodeLoading } =
    useGetALLSiteCodeFormalCode(dataAccessProfileId);
  const { siteCodeForFormalCode, siteCodeLoading, siteCoderRefetch } =
    useAddAllSiteCodeForFormalCode(formalCode?.value ?? '');

  const isBusy =
    isRemoving ||
    isAdding ||
    isSiteCodesLoading ||
    isFormalCodesLoading ||
    isSearching ||
    isAllSiteCodeLoading ||
    siteCodeLoading;

  useEffect(() => {
    if (formalCode) siteCoderRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formalCode]);

  return (
    <>
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <Container>
        <UpperNote>
          <b>
            Note: Data access detail assignments take up to 24 hour to take
            effect.
          </b>
        </UpperNote>
      </Container>
      <MainContainer md={12}>
        <Header>
          <Text fontSize="m">Add Data Access Detail</Text>
        </Header>
        <Container alignItems="flex-end">
          <Item md={4} xs={12}>
            <Label noWrap fontSize="xs">
              Formal/Scheme Code
            </Label>
            <InputSelect
              options={workProviderFormalCodes?.map((c, index) => ({
                id: index,
                text: c.formalCode ?? '',
                value: c.formalCode ?? undefined,
              }))}
              value={formalCode}
              onChange={(data: SelectOption | null) => {
                setFormalCode(data);
                setSiteCode(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Item>
          <Item md={4} xs={12}>
            <Label fontSize="xs">Site Code</Label>
            <InputSelect
              options={siteCodeForFormalCode?.map(
                (data: SelectOption | null, index: number) => ({
                  id: index,
                  value: data,
                  text: data,
                })
              )}
              value={siteCode}
              onChange={setSiteCode}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Item>
          <Item>
            <Button disabled={!isPairSearchEnabled} onClick={onSearch}>
              Search
            </Button>
          </Item>
        </Container>
        {searchResult && siteCode?.value && formalCode?.value && (
          <SearchResult
            searchResult={searchResult}
            siteCode={siteCode.value}
            formalCode={formalCode.value}
            onAdd={onAdd}
            onRemove={onRemove}
          />
        )}
      </MainContainer>
      <DataAccessReport />
      {toastState && (
        <PrimaryToast
          message={toastState.message}
          isOpen={toastState.isOpen}
          severity={toastState.severity}
          onClose={() => setToastState({ ...toastState, isOpen: false })}
        />
      )}
    </>
  );

  function onRemove() {
    if (siteCode?.value && formalCode?.value)
      removeSiteCodeFormalCodeAssign({
        dataAccessProfileId,
        siteCode: siteCode?.value,
        formalCode: formalCode?.value,
      });
  }

  function onAdd() {
    if (siteCode?.value && formalCode?.value)
      addSiteCodeFormalCodeAssign({
        dataAccessProfileId,
        siteCode: siteCode?.value,
        formalCode: formalCode?.value,
      }).then((response) => {
        response.status !== 204
          ? setToastState({
              isOpen: true,
              message:
                `Couldn't add Site code(${siteCode.value}) ` +
                `and fomalCode(${formalCode.value}) to data access profile`,
              severity: 'warning',
            })
          : setToastState({
              isOpen: true,
              message:
                `Site code and Formal code pair ` +
                `(${siteCode.value}/${formalCode.value}) added successfully`,
              severity: 'success',
            });
        refetchAllSiteCodeFormalCode();
      });
  }

  function onSuccessResponse({
    response,
    siteCode: siteCodeOnSuccess,
    formalCode: formalCodeOnSuccess,
  }: ISiteCodeOnSuccess) {
    response?.status !== 204
      ? setToastState({
          isOpen: true,
          message:
            `Couldn't remove Site code(${siteCodeOnSuccess}) ` +
            `and fomalCode(${formalCodeOnSuccess}) to data access profile`,
          severity: 'warning',
        })
      : setToastState({
          isOpen: true,
          message:
            `Site code and Formal code pair ` +
            `(${siteCodeOnSuccess}/${formalCodeOnSuccess}) removed successfully`,
          severity: 'success',
        });
    refetchAllSiteCodeFormalCode();
  }

  function onSearch() {
    search();
  }

  function handleError(error: AxiosError) {
    setToastState({
      message: getBadRequestErrorMessage(error),
      isOpen: true,
      severity: 'error',
    });
  }
}

const MainContainer = styled(Container)`
  background-color: ${({ theme }) => theme.palette.primary.skeleton};
  padding: ${({ theme }) => theme.padding.m};
  border-radius: ${({ theme }) => theme.border.radius};
`;

const Header = styled(GridItem)`
  margin-bottom: 12px;
`;

const Label = styled(Text)`
  margin-bottom: ${({ theme }) => theme.padding.xs};
`;

const Button = styled(PrimaryButton)`
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
`;

const Item = styled(GridItem)`
  padding-right: ${({ theme }) => theme.padding.s};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-bottom: ${({ theme }) => theme.padding.s};
    padding-right: 0px;
  }
`;
const UpperNote = styled(Container)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  margin: ${({ theme }) => theme.padding.m};
`;
