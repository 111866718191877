/** @jsxImportSource @emotion/react */

import { Container, GridItem } from 'components/Layout';
import { Heading, Text } from 'components/Typography';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import styled from '@emotion/styled';
import MobileBottomButton, {
  BackButton,
  CreateButton,
  PrimaryButton,
} from 'components/Button';
import { FormGroup, InputAdornment } from '@mui/material';
import { FormEvent, useState } from 'react';
import { useUserAccounts } from 'pages/hooks';
import { usersTableHeaders } from './types';
import { useEffect } from 'react';
import { DataGrid, getSortObject } from 'components/DataGrid';
import { BaseUserAccount } from 'pages/hooks';
import { createAdminUsersAccountsUrl, ROUTES } from 'core/routes';
import { useUserAccountParams } from './hooks';
import { SortObject } from 'components/types';
import { TextField } from 'components/TextField';
import { Checkbox } from 'components/Checkbox';
import { Search } from '@mui/icons-material';
import { DesktopContainer, useInMobile } from 'core/Theming/Device';
import {
  MainPageContainer,
  UpperInfoContainer,
  PageHeader,
  TableContainer,
} from 'components/PageLayout';
import { MobileRow } from './MobileRow';
import InfoText from 'components/InfoText';

export function UserAccounts({ history, location }: RouteComponentProps) {
  const isMobile = useInMobile();
  const urlParams = useUserAccountParams(location.search);

  const [searchTerm, setSearchTerm] = useState(urlParams.searchTerm ?? '');
  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sort ?? '',
    sortOrder: urlParams.sortOrder,
  });

  const [includeDisabledAccounts, setIncludeDisabledAccounts] = useState(
    urlParams.disabledAccounts
  );

  const queryEnabled = !!urlParams.searchTerm;
  const { users, isUsersFetching } = useUserAccounts(
    {
      searchTerm: urlParams.searchTerm,
      sort: urlParams.sort,
      sortOrder: urlParams.sortOrder,
      includeDisabledAccounts: urlParams.disabledAccounts,
    },
    queryEnabled
  );

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortObject]);

  return (
    <MainPageContainer
      isLoading={isUsersFetching}
      direction="column"
      wrap="nowrap"
    >
      <UpperInfoContainer direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Administration" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>Manage User Accounts</Heading>
        </PageHeader>
        <TextItem>
          <Text fontSize="s">Search for User</Text>
        </TextItem>
        <form onSubmit={handleSubmit}>
          <Container direction="row">
            <InputButton md={3} sm={10} xs={10}>
              <TextField
                className="searchbox"
                placeholder="Search"
                variant="outlined"
                value={searchTerm}
                fullWidth
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </InputButton>
            <GridItem md={1} sm={2} xs={2}>
              <Button type="submit">
                {renderSearchIcon()}
                {renderSearchButtonText()}
              </Button>
            </GridItem>
            <GridItem md={8}>
              <DesktopContainer>{renderCreateButton()}</DesktopContainer>
            </GridItem>
          </Container>
        </form>
        <GridItem>
          <FormGroup row>
            <Checkbox
              checked={includeDisabledAccounts}
              onChange={(e) =>
                setIncludeDisabledAccounts(Boolean(e.target.checked))
              }
              label="Include disabled accounts"
              color="primary"
            />
          </FormGroup>
        </GridItem>
      </UpperInfoContainer>
      <>
        {!isUsersFetching && users && users.length === 0 && (
          <InfoText>
            {"Sorry, we couldn't find any users with this search criteria."}
          </InfoText>
        )}
        <TableContainer direction="column">
          <DataGrid
            isLoading={isUsersFetching}
            headers={usersTableHeaders}
            data={users}
            onSort={sort}
            sortObject={sortObject}
            onRowClick={rowClick}
            mobileRow={<MobileRow users={users} onClick={rowClick} />}
          />
        </TableContainer>
      </>
      <MobileBottomButton>{renderCreateButton()}</MobileBottomButton>
    </MainPageContainer>
  );

  function renderCreateButton() {
    return (
      <CreateButton text="Create New User" route={ROUTES.userAccountCreate} />
    );
  }

  function renderSearchIcon() {
    if (isMobile) return <Search />;
    return null;
  }

  function renderSearchButtonText() {
    if (!isMobile) return 'Search';
    return null;
  }

  function rowClick(row: BaseUserAccount) {
    const route = generatePath(ROUTES.userAccountEdit, {
      id: row.id,
    });
    history.push(route);
  }

  function sort(sortValue: string) {
    setSortObject((prevValue) => getSortObject(sortValue, prevValue));
  }

  function navigateIfPossible() {
    if (!searchTerm) return;
    navigateToUserAccounts();
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    setSortObject({});
    navigateIfPossible();
  }

  function navigateToUserAccounts() {
    history.replace(
      createAdminUsersAccountsUrl(
        searchTerm,
        sortObject.sortString ?? undefined,
        sortObject.sortOrder,
        includeDisabledAccounts
      )
    );
  }
}

const TextItem = styled(GridItem)`
  padding-bottom: 12px;
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const InputButton = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 6px;
  }
  & svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;
