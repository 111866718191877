import dayjs, { Dayjs } from 'dayjs';
import { GridDateStatusIcon } from './DateStatusIcon';
import { DateStatus } from './types';
import { formatDate } from 'utils/helpers';
import styled from '@emotion/styled';
import { AdditionalStatus, additionalStatus } from './additionalStatus';
import { Container } from 'components/Layout';

interface Props {
  cpd?: Date;
  delivered?: Date;
  additionalStatus?: AdditionalStatus | undefined;
}

export function DeliveredDisplay({ cpd, delivered }: Props) {
  const now = dayjs();
  const cpdDate: Dayjs | undefined = cpd ? dayjs(cpd) : undefined;
  const dtgDeliveredDate: Dayjs | undefined = delivered
    ? dayjs(delivered)
    : undefined;

  const status = getDeliveredGridStatus(now, cpdDate, dtgDeliveredDate);

  return (
    <Container direction="row" wrap="nowrap">
      <GridDateStatusIcon status={status} />
      <DateTimeColumn>
        <div className="custom-width">{formatDate(delivered)}</div>
        <div className="custom-width">
          {additionalStatus(cpdDate, dtgDeliveredDate)?.message}
        </div>
      </DateTimeColumn>
    </Container>
  );
}

export function getDeliveredGridStatus(
  now: Dayjs,
  cpd?: Dayjs,
  delivered?: Dayjs
): DateStatus {
  if (!cpd) return 'grey';
  if (cpd > now && !delivered) return 'grey';
  if (cpd < now && !delivered) return 'amber';
  if (cpd && delivered) {
    const isSameDate = dayjs(cpd).isSame(dayjs(delivered), 'date');
    if (isSameDate) return 'grey';
    if (!isSameDate) return 'red';
  }
  return 'unknown';
}

export function getDeliveredDetailsStatus(
  now: Dayjs,
  cpd?: Dayjs,
  delivered?: Dayjs
): DateStatus {
  if (!cpd && !delivered) return 'grey';
  if (cpd) {
    if (cpd > now && !delivered) return 'grey';
    if (cpd < now && !delivered) return 'amber';
  }

  if (!cpd && delivered) return 'green';
  if (cpd && delivered) {
    const isSameDate = dayjs(cpd).isSame(dayjs(delivered), 'date');
    if (isSameDate) return 'green';
    if (!isSameDate) return 'red';
  }
  return 'unknown';
}

const DateTimeColumn = styled('div')`
  max-width: 100px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  .custom-width {
    width: 83px;
    display: block;
  }
`;
