import { Company, SortOrder } from 'api/resources/models/AutoGenerated';
import MobileBottomButton, { BackButton, CreateButton } from 'components/Button';
import { DataGrid, getSortObject } from 'components/DataGrid';
import InfoText from 'components/InfoText';
import { Container, GridItem } from 'components/Layout';
import {
  MainPageContainer,
  PageHeader,
  UpperInfoContainer,
} from 'components/PageLayout';
import { SortObject } from 'components/types';
import { Heading } from 'components/Typography';
import { createCompaniesUrl, ROUTES } from 'core/routes';
import { DesktopContainer } from 'core/Theming/Device';
import { orderBy } from 'lodash';
import { useCompanies } from 'pages/hooks';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useCompaniesParams } from './hooks';
import { companiesTableHeaders } from './types';

export function Companies() {
  const history = useHistory();
  const location = useLocation();
  const { companies, isCompaniesLoading } = useCompanies({});
  const urlParams = useCompaniesParams(location.search);
  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sort ?? '',
    sortOrder: urlParams.sortOrder,
  });

  const [sortedCompanies, setSortedCompanies] = useState(companies);

  const sortCompanies = useCallback(
    (sortObj: SortObject) => {
      return orderBy(
        companies,
        sortObj.sortString,
        sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
      );
    },
    [companies]
  );

  const navigateToCompanies = useCallback(() => {
    history.replace(
      createCompaniesUrl(
        sortObject.sortString ?? undefined,
        sortObject.sortOrder
      )
    );
  }, [history, sortObject]);

  useEffect(() => {
    setSortedCompanies(sortCompanies(sortObject));
  }, [companies, sortCompanies, sortObject]);

  useEffect(() => {
    navigateToCompanies();
  }, [sortObject, navigateToCompanies]);

  return (
    <MainPageContainer
      isLoading={isCompaniesLoading}
      direction="column"
      wrap="nowrap"
    >
      <UpperInfoContainer direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Administration" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>Companies</Heading>
        </PageHeader>
        <GridItem>
          <DesktopContainer>{renderCreateButton()}</DesktopContainer>
        </GridItem>
      </UpperInfoContainer>
      <Container direction="column">
        {!isCompaniesLoading && sortedCompanies.length <= 0 ? (
          <InfoText>{"Sorry, we couldn't find any companies."}</InfoText>
        ) : (
          <DataGrid
            isLoading={isCompaniesLoading}
            headers={companiesTableHeaders}
            data={sortedCompanies}
            onSort={sort}
            onRowClick={rowClick}
            sortObject={sortObject}
          />
        )}
      </Container>
      <MobileBottomButton>{renderCreateButton()}</MobileBottomButton>
    </MainPageContainer>
  );

  function renderCreateButton() {
    return (
      <CreateButton text="Create New Company" route={ROUTES.companiesCreate} />
    );
  }

  function rowClick(row: Company) {
    const route = generatePath(ROUTES.companiesEdit, {
      id: row.id,
    });
    history.push(route);
  }

  function sort(sortValue: keyof Company) {
    setSortObject((prevValue) => {
      const sortObj = getSortObject(sortValue, prevValue);
      setSortedCompanies(sortCompanies(sortObj));
      return sortObj;
    });
  }
}
