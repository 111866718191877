import { GridItem } from 'components/Layout';
import { Controller, useFormContext } from 'react-hook-form';
import {
  DatePickerField,
  DateTimePickerField,
} from 'components/DatePickerFields';
import { RepairManualUpdate } from './form';
import {
  InputContainer,
  InputFirst,
  Header,
  InputSecond,
  Label,
  RequiredLabel,
  MainInputContainer,
} from 'components/PageLayout';
import { ErrorText } from 'components/Typography';
import InputSelect, { SelectOption } from 'components/InputSelect';
import { useState } from 'react';
import { TextField } from 'components/TextField';
// import { TextField } from 'components/TextField';

export default function Milestones() {
  const { control, getValues } = useFormContext<RepairManualUpdate>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const finalStatus = [
    { id: 16, value: 'Total Loss', text: 'Total Loss' },
    { id: 15, value: 'Cancelled', text: 'Cancelled' },
    { id: 14, value: 'Finished', text: 'Finished' },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectFinalStatus, setSelectFinalStatus] =
    useState<SelectOption | null>(
      finalStatus.find(
        (c) => c.id === getValues('claimDetail.formalStatusID')
      ) ?? null
    );

  return (
    <MainInputContainer direction="column">
      <GridItem>
        <InputContainer>
          <InputFirst md={6} sm={6} xs={12}>
            <RequiredLabel>FNOL</RequiredLabel>
            <Controller
              render={({ field, fieldState: { invalid } }) => (
                <>
                  <DateTimePickerField {...field} isDisabled />
                  {invalid && (
                    <ErrorText className="text-danger">
                      FNOL have not a proper value
                    </ErrorText>
                  )}
                </>
              )}
              name="milestone.firstActivityDate"
              control={control}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>Final Status</Label>
            <Controller
              render={({ field: { onChange } }) => (
                <InputSelect
                  value={selectFinalStatus}
                  options={finalStatus}
                  onChange={(e: SelectOption | null) => {
                    setSelectFinalStatus(e);
                    onChange(e?.id ?? null);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
              name="claimDetail.formalStatusID"
              control={control}
            />
          </InputSecond>
        </InputContainer>
        <Header>Booking</Header>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>Booked In</Label>
            <Controller
              render={({ field }) => <DatePickerField {...field} />}
              name="milestone.bookedInDate"
              control={control}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>Booked Out / C.P.D</Label>
            <Controller
              render={({ field }) => <DatePickerField {...field} />}
              name="milestone.bookedOutDate"
              control={control}
            />
          </InputSecond>
        </InputContainer>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>On Site</Label>
            <Controller
              render={({ field }) => <DateTimePickerField {...field} />}
              name="milestone.onSiteDate"
              control={control}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>Delivered</Label>
            <Controller
              render={({ field }) => <DateTimePickerField {...field} />}
              name="milestone.deliveredDate"
              control={control}
            />
          </InputSecond>
        </InputContainer>
        <Header>Repair Progress</Header>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>Authorised Date</Label>
            <Controller
              render={({ field }) => <DateTimePickerField {...field} />}
              name="milestone.insAuthorisedDate"
              control={control}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>Estimation Date</Label>
            <Controller
              render={({ field }) => <DateTimePickerField {...field} />}
              name="milestone.estimationDate"
              control={control}
            />
          </InputSecond>
        </InputContainer>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>Work Start</Label>
            <Controller
              render={({ field }) => <DateTimePickerField {...field} />}
              name="milestone.startedDate"
              control={control}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>Work Done</Label>
            <Controller
              render={({ field }) => <DateTimePickerField {...field} />}
              name="milestone.completedDate"
              control={control}
            />
          </InputSecond>
        </InputContainer>
        <Header>Parts</Header>
        <InputContainer direction="row">
          <InputFirst md={6} sm={6} xs={12}>
            <Label>Parts Ordered</Label>
            <Controller
              render={({ field }) => <DateTimePickerField {...field} />}
              name="milestone.partsOrderedDate"
              control={control}
            />
          </InputFirst>
          <InputSecond md={6} sm={6} xs={12}>
            <Label>Parts Received</Label>
            <Controller
              render={({ field }) => <DateTimePickerField {...field} />}
              name="milestone.partsInDate"
              control={control}
            />
          </InputSecond>
        </InputContainer>
      </GridItem>
    </MainInputContainer>
  );
}
