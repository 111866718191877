/** @jsxImportSource @emotion/react */

import { RegistrationNo } from './fields/RegistrationNo';
import { ModifiedDisplay } from './fields/Modified';
import { EcdDisplay } from './fields/Ecd';
import { FormalStatusField } from './fields/FormalStatus';
import { DiaryDisplay } from './fields/Diary';
import { CpdDisplay } from './fields/Cpd';
import { WorkingInProgress } from './fields/WorkingInProgress';
import { Repair } from '../../api/resources/models/AutoGenerated';
import { ReplacementVehicleIcon } from '../ReplacementVehicleIcon';
import { VehicleTableHeader } from './types';
import { WithWrapper } from 'components/WithWrapper';
import Permits from 'components/Permits';
import { StyledTableCell, StyledTableRow } from 'components/DataGrid';
import { formatDate } from 'utils/helpers';
import { CompletedDisplay } from './fields/Completed';
import { DeliveredDisplay } from './fields/Delivered';

interface Props {
  rows?: Repair[];
  onRowClick: (row: Repair) => void;
  headers: VehicleTableHeader[];
}

export function DesktopTableRow({
  rows,
  onRowClick,
  headers,
}: Props): JSX.Element {
  return (
    <>
      {rows?.map((row) => (
        <StyledTableRow key={row.repairId} onClick={() => onRowClick(row)}>
          {headers.map((header) => {
            return (
              <WithWrapper
                key={header.id}
                condition={!!header?.permitCode}
                wrap={(children) => (
                  <Permits permit={header.permitCode!}>{children}</Permits>
                )}
              >
                <StyledTableCell component="td" scope="row">
                  {renderTableItem(row, header.id)}
                </StyledTableCell>
              </WithWrapper>
            );
          })}
        </StyledTableRow>
      ))}
    </>
  );
}

function renderTableItem(row: Repair, id: keyof Repair) {
  const value = row[id];
  if (id === 'vehicleReg') {
    return <RegistrationNo number={row.vehicleReg} />;
  } else if (id === 'dtgUpdated') {
    const { dtgUpdated, dtgDelivered } = row;
    if (!dtgUpdated) return '';

    return (
      <ModifiedDisplay
        modified={new Date(dtgUpdated)}
        delivered={dtgDelivered ? new Date(dtgDelivered) : undefined}
      />
    );
  } else if (id === 'dtgEcd') {
    const { dtgEcd, dtgCompleted } = row;

    return (
      <EcdDisplay
        ecd={dtgEcd ? new Date(dtgEcd) : undefined}
        completed={dtgCompleted ? new Date(dtgCompleted) : undefined}
      />
    );
  } else if (id === 'dtgCpd') {
    const { dtgCpd, dtgDelivered } = row;

    return (
      <CpdDisplay
        cpd={dtgCpd ? new Date(dtgCpd) : undefined}
        completed={dtgDelivered ? new Date(dtgDelivered) : undefined}
      />
    );
  } else if (id === 'dtgCompleted') {
    const { dtgEcd, dtgCompleted } = row;

    return (
      <CompletedDisplay
        ecd={dtgEcd ? new Date(dtgEcd) : undefined}
        completed={dtgCompleted ? new Date(dtgCompleted) : undefined}
      />
    );
  } else if (id === 'dtgDelivered') {
    const { dtgCpd, dtgDelivered } = row;

    return (
      <DeliveredDisplay
        cpd={dtgCpd ? new Date(dtgCpd) : undefined}
        delivered={dtgDelivered ? new Date(dtgDelivered) : undefined}
      />
    );
  } else if (id === 'diaryDate') {
    if (!row.diaryDate) return '';

    return <DiaryDisplay diaryDate={new Date(row.diaryDate)} />;
  } else if (id === 'replacementVehicleStatus') {
    if (!row.replacementVehicleStatus) return '';

    return <ReplacementVehicleIcon status={row.replacementVehicleStatus} />;
  } else if (id === 'formalStatus') {
    if (!row.formalStatus || !row.formalStatusDescription) return '';

    return (
      <FormalStatusField
        status={row.formalStatus}
        dtgOnSite={row.dtgOnSite ? new Date(row.dtgOnSite) : null}
        statusDescription={row.formalStatusDescription}
      />
    );
  } else if (id === 'workInProgressCode') {
    return <WorkingInProgress status={row.workInProgressCode} />;
  } else if (id === 'dtgCreated') {
    return <div style={{ width: '83px' }}>{formatDate(value as unknown as Date)}</div>;
  } else return value;
}
