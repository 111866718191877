import { DataGrid } from "components/DataGrid"
import InfoText from "components/InfoText"
import { useUserAccountsByCompanyId } from "pages/hooks"
import { MobileRow } from "pages/UserAccounts/MobileRow"
import { Company } from "./form"
import { usersTableHeaders } from "./types"

interface Props {
    company: Company
}

export function CompanyUserAccounts({ company }: Props) {
    const { userAccounts, userAccountsLoading } = useUserAccountsByCompanyId(company.id);
    const isBusy = userAccountsLoading

    if (!isBusy && userAccounts && userAccounts.length === 0) {
        return (
            <InfoText>
                {"Sorry, we couldn't find any users associated with this company."}
            </InfoText >
        )
    }


    return (
        <DataGrid
            removeLeftPadding
            isLoading={isBusy}
            headers={usersTableHeaders}
            data={userAccounts}
            mobileRow={<MobileRow users={userAccounts} />}
        />
    )
}