import { Container, GridItem } from 'components/Layout';
import { Text } from 'components/Typography';
import styled from '@emotion/styled';
import { Activity } from '../helpers';
import { logger } from 'core/logger';
import { formatDate } from 'utils/helpers';
import { ActivityStatus } from './ActivityStatus';
import ErrorIcon from '@mui/icons-material/Error';

interface ActivityItemProp {
  item: Activity;
}

export function ActivityItem({ item }: ActivityItemProp) {
  return (
    <MainContainer wrap="nowrap">
      <GridItem className="icon">
        {item.icon && <ActivityStatus status={item.icon} />}
      </GridItem>
      <GridItem>
        <Container direction="column">
          <GridItem>
            <Container>
              <GridItem>
                <Text>
                  <span>{formatDate(item.activityDate)}</span>
                  {item.icon && item.operator
                    ? renderActivityText(item.icon, item.operator)
                    : null}
                </Text>
              </GridItem>
            </Container>
          </GridItem>
          <GridItem className="message-text">
            {item.complaint ? (
              <IconContainer>
                <Text>
                  <span>
                    <ErrorIcon />
                    Complaint
                  </span>
                  {item.message}
                </Text>
              </IconContainer>
            ) : (
              <Text>{item.message}</Text>
            )}
          </GridItem>
        </Container>
      </GridItem>
    </MainContainer>
  );

  function renderActivityText(iconCode: string, operator: string) {
    switch (iconCode) {
      case 'Tag': {
        return `Hashtag by ${operator}`;
      }
      case 'NoComms': {
        return `Communication Event by ${operator}`;
      }
      case 'DataMonitor': {
        return `Audit Event by ${operator}`;
      }
      case 'Diary': {
        return `Diary event by ${operator}`;
      }
      case 'SMS': {
        return `Sms by ${operator}`;
      }
      case 'RVCheck' || 'EracAtt': {
        return `Replacement Vehicle by ${operator}`;
      }
      case 'Customer Comment': {
        return `Online Comment by ${operator}`;
      }
      case 'NPS': {
        return `NPS Event by ${operator}`;
      }
      case 'Comment': {
        return `Comment by ${operator}`;
      }
      case 'OwnerContact': {
        return `Contact by ${operator}`;
      }
      case 'RepairerContact': {
        return `Contact by ${operator}`;
      }
      default: {
        logger.warn(`Activity text of ${iconCode} not found!`);
        break;
      }
    }
  }
}

const IconContainer = styled(Container)`
  svg {
    color: ${({ theme }) => theme.palette.icon.warn};
    vertical-align: middle;
  }
`;

const MainContainer = styled(Container)`
  background-color: ${({ theme }) => theme.palette.background.default};
  margin-bottom: ${({ theme }) => theme.margin.s};
  border-radius: ${({ theme }) => theme.border.radius};
  padding: ${({ theme }) => theme.padding.l};

  & .message-text {
    margin-top: ${({ theme }) => theme.margin.s};
  }

  & .icon {
    margin-right: ${({ theme }) => theme.margin.m};
  }

  span {
    color: ${({ theme }) => theme.palette.secondary.main};
    margin-right: 12px;
  }

  :hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }

  :active {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  }
`;
