import {
    UserAccount as ApiUserAccount,
  } from 'api/resources/models/AutoGenerated';
  import dayjs from 'dayjs';
import { BaseUserAccount, convertToDate } from 'pages/hooks';
  
  export const dateToString = (date: Date) =>
    dayjs(date).format('YYYY-MM-DDThh:mm:ss');
  
  export const getApiForm = (data: UserAccount): ApiUserAccount => ({
    ...data,
    expiryDate: data.expiryDate ? dateToString(data.expiryDate) : null,
    createdDate: data.createdDate ? dateToString(data.createdDate) : null,
    lastLoginDate: data.lastLoginDate ? dateToString(data.lastLoginDate) : null,
  });

  export const getFormData = (data: ApiUserAccount):UserAccount  => ({
    ...data,
    expiryDate: data.expiryDate ? convertToDate(data.expiryDate) : null,
    createdDate: data.createdDate ? convertToDate(data.createdDate) : null,
    lastLoginDate: data.lastLoginDate ? convertToDate(data.lastLoginDate) : null,
  });

export interface UserAccount extends BaseUserAccount {
    hasPassword: boolean;
    accountProfileName: string | null;
    locale: string | null;
    usageCount: number;
    mobile: string | null;
    telephone: string | null;
    defaultUserAccountProfileId: number | null;
    allowIPOverride: boolean;
    accountLockedDate: string | null;
    externalAuthenticationId: string | null;
    dataAccessProfileId: number;
    isGlobalAdmin: boolean;
}
  