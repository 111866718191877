import { BackButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { Heading } from 'components/Typography';
import { PageTab, PageTabItem, TabPanel } from 'components/Tab';
import { useState } from 'react';
import {
  getRepairClaimSummaryCacheKey,
  getRepairDetailsCacheKey,
  useUpdateRepair,
} from 'pages/hooks';
import { GrayBackdrop, Loader } from 'components/Loader';
import RepairStatus from './RepairStatus';
import Mobility from './Mobility';
import Milestones from './Milestones';
import { ClaimSummary, Repair } from 'api/resources/models/AutoGenerated';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import PrimaryToast from 'components/PrimaryToast';
import { getApiForm, RepairManualUpdate } from './form';
import Invoice from './Invoice';
import { queryClient } from 'api/client';
import {
  PageHeader,
  SaveButton,
  SaveIcon,
  UpperInfoContainer,
} from 'components/PageLayout';
import { FormalStatusField } from 'components/VehiclesTable/fields/FormalStatus';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

const validationSchema = z.object({
  replacementVehicle: z.object({
    replacementVehicleStatus: z.string(),
    courtesyModel: z.string().nullable(),
    courtesyMake: z.string().nullable(),
    replacementReg: z.string().nullable(),
    dateOut: z.date().nullable(),
    dateIn: z.date().nullable(),
  }),
  milestone: z.object({
    bookedOutDate: z.date().nullable(),
    estimationDate: z.date().nullable(),
    partsOrderedDate: z.date().nullable(),
    partsInDate: z.date().nullable(),
    deliveredDate: z.date().nullable(),
    startedDate: z.date().nullable(),
    completedDate: z.date().nullable(),
    firstActivityDate: z.date(),
    onSiteDate: z.date().nullable(),
    bookedInDate: z.date().nullable(),
    insAuthorisedDate: z.date().nullable(),
  }),
  invoice: z.object({
    ownerExcess: z.number().nullable(),
    labourHours: z.number().nullable(),
    invoicedLabour: z.number().nullable(),
    invoicedParts: z.number().nullable(),
    invoicedPaint: z.number().nullable(),
    invoicedOther: z.number().nullable(),
    invoicedTax: z.number().nullable(),
    invoicedTotal: z.number().nullable(),
    firstInvoiceDate: z.date().nullable(),
  }),
  claimDetail: z.object({
    isVehicleDriveable: z.boolean(),
    possibleTL: z.boolean(),
    formalStatusID: z.number().nullable(),
  }),
});

interface Props {
  repairSummary: ClaimSummary;
  repairDetails: Repair;
}

export function ManualUpdateForm({ repairSummary, repairDetails }: Props) {
  const [selectedTab, setSelectedTab] = useState<string>('milestone');

  const { updateRepair, isUpdating } = useUpdateRepair({
    repairId: repairSummary.repairId,
    repairCode: repairSummary.repairCode,
    siteCode: repairDetails.siteCode,
    onSuccess: invalidateRepairSummaryAndDetails,
  });

  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });

  const convertToDate = (date: string | null | undefined) =>
    date ? new Date(date) : null;

  const milestone = repairSummary.milestone;
  const replacementVehicle = repairSummary.replacementVehicle;
  const invoice = repairSummary.invoice;

  const repairForm = useForm<RepairManualUpdate>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      milestone: {
        bookedOutDate: convertToDate(milestone?.bookedOutDate),
        estimationDate: convertToDate(milestone?.estimationDate),
        partsOrderedDate: convertToDate(milestone?.partsOrderedDate),
        partsInDate: convertToDate(milestone?.partsInDate),
        deliveredDate: convertToDate(milestone?.deliveredDate),
        startedDate: convertToDate(milestone?.startedDate),
        completedDate: convertToDate(milestone?.completedDate),
        firstActivityDate: convertToDate(milestone?.firstActivityDate),
        onSiteDate: convertToDate(milestone?.onSiteDate),
        bookedInDate: convertToDate(milestone?.bookedInDate),
        insAuthorisedDate: convertToDate(milestone?.insAuthorisedDate),
      },
      replacementVehicle: {
        replacementVehicleStatus: repairDetails.replacementVehicleStatus,
        courtesyMake: replacementVehicle?.courtesyMake || null,
        courtesyModel: replacementVehicle?.courtesyModel || null,
        replacementReg: replacementVehicle?.replacementReg || null,
        dateIn: convertToDate(replacementVehicle?.dateIn),
        dateOut: convertToDate(replacementVehicle?.dateOut),
      },
      invoice: {
        firstInvoiceDate: convertToDate(invoice?.firstInvoiceDate),
        ownerExcess: invoice?.ownerExcess || null,
        invoicedLabour: invoice?.invoicedLabour || null,
        labourHours: invoice?.labourHours || null,
        invoicedParts: invoice?.invoicedParts || null,
        invoicedPaint: invoice?.invoicedPaint || null,
        invoicedOther: invoice?.invoicedOther || null,
        invoicedTax: invoice?.invoicedTax || null,
        invoicedTotal: invoice?.invoicedTotal || null,
      },
      claimDetail: {
        isVehicleDriveable: repairSummary.isVehicleDriveable,
        possibleTL: repairSummary.possibleTL,
        formalStatusID: repairSummary.formalStatusID || null,
      },
    },
  });

  return (
    <>
      <FormProvider {...repairForm}>
        <form onSubmit={repairForm.handleSubmit(onSubmit, onError)}>
          <GrayBackdrop open={isUpdating}>
            <Loader />
          </GrayBackdrop>
          <UpperInfoContainer>
            <Container direction="column">
              <GridItem>
                <Container>
                  <BackButton text="Back to Details" />
                </Container>
              </GridItem>
              <HeaderWrapper direction="row">
                <PageHeader className="header">
                  <Heading>{`Edit Repair "${repairDetails.vehicleReg}"`}</Heading>
                </PageHeader>
                {repairDetails.formalStatus &&
                  repairDetails.formalStatusDescription && (
                    <FormalStatusField
                      status={repairDetails.formalStatus}
                      dtgOnSite={dayjs(repairDetails.dtgOnSite).toDate()}
                      statusDescription={repairDetails.formalStatusDescription}
                    />
                  )}
              </HeaderWrapper>
            </Container>
            <Container>
              <SaveButton type="submit">
                <SaveIcon />
                Save Changes
              </SaveButton>
            </Container>
            <Container direction="column">
              <Container>
                <GridItem md={6} sm={12} xs={12}>
                  <PageTab
                    value={selectedTab}
                    onChange={(_e, value) => setSelectedTab(value)}
                  >
                    <PageTabItem label="Milestones" value="milestone" />
                    <PageTabItem label="Mobility" value="replacementVehicle" />
                    <PageTabItem label="Invoice" value="invoice" />
                  </PageTab>
                </GridItem>
              </Container>
              <Container direction="row">
                <GridItem md={6} sm={12} xs={12}>
                  <TabPanel value={selectedTab} index="milestone">
                    <Milestones />
                  </TabPanel>
                  <TabPanel value={selectedTab} index="replacementVehicle">
                    <Mobility />
                  </TabPanel>
                  <TabPanel value={selectedTab} index="invoice">
                    <Invoice />
                  </TabPanel>
                </GridItem>
                <GridItem md={6} sm={12} xs={12}>
                  <RepairStatus repair={repairDetails} />
                </GridItem>
              </Container>
            </Container>
          </UpperInfoContainer>
        </form>
      </FormProvider>
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
    </>
  );

  function onSubmit(data: RepairManualUpdate) {
    updateRepair(getApiForm({ ...repairForm, ...data }))
      .then(() => {
        setToastState({
          message: 'Repair details successfully updated',
          isOpen: true,
          severity: 'success',
        });
      })
      .catch(() => handleError());
  }

  function onError(repair: FieldErrors<RepairManualUpdate>) {
    const keys = Object.keys(repair);
    const key = keys.find((value) => value === selectedTab);
    if (key) setSelectedTab(key);
  }

  function invalidateRepairSummaryAndDetails() {
    queryClient.invalidateQueries([
      getRepairDetailsCacheKey(repairDetails.repairId),
      getRepairClaimSummaryCacheKey(
        repairDetails.repairId,
        repairDetails.repairCode
      ),
    ]);
  }

  function handleError() {
    setToastState({
      message:
        'Failed to save repair details. Please wait a second and try again',
      isOpen: true,
      severity: 'error',
    });
  }
}

const HeaderWrapper = styled(Container)`
  align-items: center;
  padding-bottom: ${({ theme }) => theme.padding.l};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.padding.m};
  }

  & .header {
    padding-bottom: 0;
    margin-right: ${({ theme }) => `${theme.margin.s}`};
  }
`;
