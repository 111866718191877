import {
  Column,
  Row,
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import { Heading } from 'components/Typography';
import { BackButton, PrimaryButton } from 'components/Button';
// import { DatePickerField } from "components/DatePickerFields";
import { Container, GridItem } from 'components/Layout';
import {
  MainPageContainer,
  PageHeader,
  UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { useInMobile } from 'core/Theming/Device';
import dayjs from 'dayjs';
import { useTotalLoss } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useReportParams } from '../hooks';
import { convertToDate } from 'pages/hooks';
import InfoText from 'components/InfoText';
import { GrayBackdrop, Loader } from 'components/Loader';
import { formatDate } from 'utils/helpers';
import StartInputDate from 'components/StartInputDate';
import EndDateInput from 'components/EndDateInput';
import { ReportTitleInfo } from 'components/ReportTitleInfo';

type DateFormatterProps = DataTypeProvider.ValueFormatterProps;

interface Props {
  reportPath: string;
}

const DateFormatter = ({ value }: DateFormatterProps) => (
  <div>{formatDate(value)}</div>
);

const DateTypeProvider: React.ComponentType<DataTypeProviderProps> = (
  props: DataTypeProviderProps
) => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

export function TotalLoss({ reportPath }: Props) {
  const isMobile = useInMobile();
  const history = useHistory();
  const location = useLocation();
  const urlParams = useReportParams(location.search);
  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParams.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParams.endDate) || null
  );

  const dateToString = (date: Date | null) => dayjs(date).format('YYYY-MM-DD');

  const queryEnabled = !!urlParams.startDate && !!urlParams.endDate;
  const { reportData, isReportFetching, isFetched } = useTotalLoss(
    {
      startDate: urlParams.startDate,
      endDate: urlParams.endDate,
    },
    queryEnabled
  );

  const [columns] = useState<Column[]>([
    { name: 'engineerName', title: 'Engineer Name' },
    { name: 'onsiteDTG', title: 'Onsite DTG' },
    { name: 'engineerDate', title: 'Engineer Date' },
    { name: 'repairerName', title: 'Repairer Name' },
    { name: 'postcode', title: 'Post Code' },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'onsiteDTG', width: '10%', wordWrapEnabled: true },
    { columnName: 'engineerDate', width: '10%', wordWrapEnabled: true },
  ]);

  const rows = useMemo<Row>(() => reportData || [], [reportData]);
  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.startDate, urlParams.endDate]);

  return (
    <MainPageContainer
      isLoading={isReportFetching}
      direction="column"
      wrap="nowrap"
    >
      <GrayBackdrop open={isReportFetching}>
        <Loader />
      </GrayBackdrop>
      <UpperInfoContainer direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Reports" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>
            <ReportTitleText>
              <div>TL Engineering Schedule Report</div>
              <ReportTitleInfo
                message="Displays the forward schedule for the Total Loss Engineering team for the date range provided."
                title="TL Engineering Schedule Report"
              />
            </ReportTitleText>
          </Heading>
        </PageHeader>
        <CustomForm onSubmit={handleSubmit}>
          <Container direction="row">
            <StartInputDate
              label="Start Date"
              onChange={(date) => setStartDate(date)}
              value={startDate}
              maxDate={endDate}
            />
            <EndDateInput
              label="End Date"
              onChange={(date) => setEndDate(date)}
              value={endDate}
              minDate={startDate}
            />
            <GridItem md={1} sm={1} xs={2}>
              <Button type="submit" disabled={!startDate || !endDate}>
                {renderSearchIcon()}
                {renderSearchButtonText()}
              </Button>
            </GridItem>
          </Container>
        </CustomForm>
      </UpperInfoContainer>
      {isFetched && !isReportFetching && rows && rows?.length === 0 && (
        <CustomInfoText>
          {"Sorry, we couldn't find any reports with this search criteria."}
        </CustomInfoText>
      )}
      <ReportGridContainer direction="column">
        {isFetched && !isReportFetching && rows && rows?.length > 0 && (
          <ReportGrid
            title="tlEngineeringScheduleReport"
            tableColumnExtensions={tableColumnExtensions}
            columns={columns}
            rows={rows}
            DateTypeProvider={DateTypeProvider}
            dateColumnsName={['onsiteDTG', 'engineerDate']}
          />
        )}
      </ReportGridContainer>
    </MainPageContainer>
  );
  function renderSearchIcon() {
    if (isMobile) return <Search />;
    return null;
  }

  function renderSearchButtonText() {
    if (!isMobile) return 'View';
    return null;
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!startDate || !endDate) return;
    navigateToReport();
  }
  function navigateToReport() {
    history.replace(
      createReportURL(
        { startDate: dateToString(startDate), endDate: dateToString(endDate) },
        reportPath
      )
    );
  }
}

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const ReportGridContainer = styled(Container)`
  padding-left: ${({ theme }) => theme.padding.xl};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;

const CustomInfoText = styled(InfoText)`
  margin-top: 0;
`;
const ReportTitleText = styled('div')`
  display: flex;
`;
