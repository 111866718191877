import { useTheme } from '@emotion/react';
import { DtlItem, SortOrder } from 'api/resources/models/AutoGenerated';
import { DataGrid, getSortObject } from 'components/DataGrid';
import InfoText from 'components/InfoText';
import { UpperInfoContainer } from 'components/PageLayout';
import StatusChip from 'components/StatusChip';
import { SortObject } from 'components/types';
import { Heading } from 'components/Typography';
import { ModifiedDisplay } from 'components/VehiclesTable/fields/Modified';
import { RegistrationNo } from 'components/VehiclesTable/fields/RegistrationNo';
import { logger } from 'core/logger';
import { createDtlUrl, ROUTES, TOTAL_LOSS } from 'core/routes';
import { orderBy } from 'lodash';
import { useDtlList } from 'pages/hooks';
import { MainContainer } from 'pages/VehiclesSearch';

import { useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { formatDate } from 'utils/helpers';
import { dtlTableHeaders, useDtlListParams } from './types';

export function DtlSearchPage() {
  const history = useHistory();
  const location = useLocation();
  const urlParams = useDtlListParams(location.search);
  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sortString ?? '',
    sortOrder: urlParams.sortOrder ?? undefined,
  });

  const { dtlList, isLoading } = useDtlList();

  const [sortedDtlList, setSortedDtlList] = useState(dtlList);
  const theme = useTheme();
  const statusColor = theme.palette.status;

  useEffect(() => {
    setSortedDtlList(sortDtlList(sortObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtlList]);

  useEffect(() => {
    navigateToAccessDataProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortObject]);

  return (
    <MainContainer isLoading={isLoading} direction="column" wrap="nowrap">
      <UpperInfoContainer direction="column">
        <Heading>Definite Total Loss</Heading>
      </UpperInfoContainer>

      {!isLoading && dtlList.length === 0 && (
        <InfoText>{"Sorry, we couldn't find any DTL repairs."}</InfoText>
      )}

      <DataGrid
        headers={dtlTableHeaders}
        data={sortedDtlList}
        isLoading={isLoading}
        onRowClick={handleVehicleClick}
        onSort={sort}
        sortObject={sortObject}
        customCellRender={renderCustomCell}
      />
    </MainContainer>
  );

  function renderCustomCell({
    baseRender,
    headerId,
    row,
  }: {
    baseRender: () => JSX.Element;
    headerId: keyof DtlItem;
    row: DtlItem;
  }) {
    if (headerId === 'formalStatus' && row.formalStatus) {
      const color =
        row.onSite === 1
          ? statusColor.onSite
          : row.delivered === 1
            ? statusColor.delivered
            : statusColor.preOnSite;
      const tooltip =
        row.onSite === 1
          ? 'On Site'
          : row.delivered === 1
            ? 'Delivered'
            : 'Pre On Site';

      return (
        <StatusChip
          toolTipMessage={tooltip}
          color={color}
          description={row.formalStatus}
        />
      );
    }
    if (headerId === 'dtgCreated') return <>{formatDate(row.dtgCreated)}</>;
    if (headerId === 'dtgUpdated' && row.dtgUpdated)
      return (
        <ModifiedDisplay
          modified={new Date(row.dtgUpdated)}
          delivered={row.delivered === 1 ? new Date() : undefined}
        />
      );
    if (headerId === 'dtgEcd') return <>{formatDate(row.dtgEcd, "L")}</>;
    if (headerId === 'vehicleReg')
      return <RegistrationNo number={row.vehicleReg} />;
    return baseRender();
  }

  function handleVehicleClick(row: DtlItem) {
    if (!row.repairCode)
      return logger.error(
        'Unable to navigate to Vehicle details from DTL Search',
        {
          repairId: row.repairId,
        }
      );

    const route = generatePath(ROUTES.vehicleDetails, {
      repairId: row.repairId,
      repairCode: row.repairCode,
      search: TOTAL_LOSS,
    });
    history.push(route);
  }

  function sortDtlList(sortObj: SortObject) {
    return orderBy(
      dtlList,
      sortObj.sortString,
      sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
    );
  }

  function sort(sortValue: keyof DtlItem) {
    setSortObject((prevValue) => {
      const sortObj = getSortObject(sortValue, prevValue);
      setSortedDtlList(sortDtlList(sortObj));
      return sortObj;
    });
  }

  function navigateToAccessDataProfiles() {
    history.replace(
      createDtlUrl(sortObject.sortString ?? undefined, sortObject.sortOrder)
    );
  }
}
