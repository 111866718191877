/** @jsxImportSource @emotion/react */

import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { DesktopContainer, MobileContainer } from 'core/Theming/Device';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as FilterIcon } from 'assets/filter.svg';
import { ReactComponent as RepairersFilter } from 'assets/repairers.svg';
import { FilterModal } from '../../components/MobileFilterModal';
import { Container, GridItem } from 'components/Layout';
import { Activity } from './Activity';
import { PrimaryButton } from 'components/Button';
import { ClickAwayListener } from '@mui/material';
import InputSelect, { SelectOption } from 'components/InputSelect';
import { TextField } from 'components/TextField';
import { Repairer, Repairs } from 'api/resources/models/AutoGenerated';
import { useFilterOptions, useRepairersOptions } from './hooks';
import { config } from 'core/config';
import { createFilterOptions } from '@mui/material/Autocomplete';

interface Props {
  repairs?: Repairs;
  selectedRepairerId?: number;
  selectedRepairerGroup?: string;
  selectedFilterId?: number;
  onApply: () => void;
  onRepairerIdChange: (selectedRepairerId: number | undefined) => void;
  onRepairerGroupChange: (selectedRepairerGroup: string | undefined) => void;
  onFilterIdChange: (filterId: number) => void;
}

export function VehiclesSearchFilters({
  repairs,
  onApply,
  selectedRepairerId,
  selectedRepairerGroup,
  selectedFilterId,
  onRepairerIdChange,
  onRepairerGroupChange,
  onFilterIdChange,
}: Props) {
  const [showFilters, setShowFilters] = useState<'filter' | 'repairer' | null>(
    null
  );
  const filterOptions = useFilterOptions();
  const { repairers: repairersOptions } = useRepairersOptions();

  const applyRef = useRef<HTMLButtonElement>(null);

  const filter = filterOptions.find((f) => f.id === selectedFilterId) ?? null;
  const selectedRepairer =
    repairersOptions?.find((r) => r.id === selectedRepairerId) ?? null;

  const selectedGroup = selectedRepairerGroup
    ? repairersOptions?.find(
      (r) => r.value?.repairerGroup === selectedRepairerGroup
    )
    : null;

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [inputRepairerValue, setInputRepairerValue] = useState<string>(
    (selectedRepairer?.text || selectedRepairerGroup) ?? ''
  );
  const [inputFilterValue, setInputFilterValue] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (selectedRepairerId !== undefined && selectedFilterId)
      applyRef.current?.focus();
  }, [selectedFilterId, selectedRepairerId]);

  return (
    <Container css={FilterControlsStyles}>
      <MobileContainer>
        <>
          <FilterButton
            type="button"
            onClick={() => setIsFilterModalOpen(true)}
          >
            <FilterIcon />
            {'Filter'}
          </FilterButton>
          <FilterModal
            isOpen={isFilterModalOpen}
            onClose={() => setIsFilterModalOpen(false)}
            title={'Filter'}
          >
            <div css={FilterControlsStyles}>
              {renderFilters({ autoFocus: false })}
            </div>
          </FilterModal>
          {
            <Activity
              device="mobile"
              repairs={repairs}
              showActivity={
                !!selectedRepairerId &&
                selectedRepairerId > 0 &&
                !!selectedFilterId
              }
            />
          }
        </>
      </MobileContainer>
      <DesktopContainer>{renderFilters({ autoFocus: true })}</DesktopContainer>
    </Container>
  );

  function renderFilters({ autoFocus }: { autoFocus: boolean }) {
    const repairersFilterOptions = createFilterOptions({
      stringify: (option: SelectOption<Repairer>) =>
        `${option.text} ${option.value?.repairerGroup}`,
    });
    return (
      <>
        <ClickAwayListener onClickAway={() => setShowFilters(null)}>
          <MainFiltersContainer>
            <FilterItem spacing={1}>
              <FilterButton
                type="button"
                fullWidth
                isOpen={showFilters === 'repairer'}
                onClick={() => {
                  setShowFilters((value) => {
                    {
                      if (value === 'repairer') return null;
                      onRepairerGroupChange(undefined);
                      return 'repairer';
                    }
                  });
                }}
              >
                <RepairersFilter />
                <p>
                  {' '}
                  {selectedRepairer?.text ??
                    selectedGroup?.value?.repairerGroup ??
                    'Choose Repairer'}
                </p>
              </FilterButton>
              {showFilters === 'repairer' && (
                <FilterContainer>
                  <InputSelect<Repairer>
                    options={repairersOptions}
                    value={selectedRepairer || selectedGroup}
                    onChange={(value: SelectOption<Repairer> | null) => {
                      onRepairerIdChange(value?.id);
                      onRepairerGroupChange(undefined);
                      value && setShowFilters('filter');
                    }}
                    groupBy={(option) => option.value?.repairerGroup ?? ''}
                    inputValue={inputRepairerValue}
                    onInputChange={setInputRepairerValue}
                    open={true}
                    filterOptions={repairersFilterOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoFocus={autoFocus}
                        onFocus={(e) => autoFocus && e.target.select()}
                        variant="outlined"
                        placeholder="Search..."
                        fullWidth
                      />
                    )}
                    renderGroup={(g) => {
                      return (
                        <Container direction="column">
                          {g.group && (
                            <Group
                              onClick={() => {
                                // we don't have backend methods for this yet and we decided to make groups unclickable
                                // in future if PPG provide us BE methods will continue work on this
                                return;
                                onRepairerGroupChange(g.group);
                                setInputRepairerValue(g.group);
                                onRepairerIdChange(undefined);
                                setShowFilters(null);
                              }}
                            >
                              {g.group}
                            </Group>
                          )}
                          <GridItem>{g.children}</GridItem>
                        </Container>
                      );
                    }}
                    getOptionDisabled={(option) =>
                      getAllRepairerDisabled(option.id)
                    }
                  />
                </FilterContainer>
              )}
            </FilterItem>
            <FilterItem className="input-container">
              <FilterButton
                type="button"
                fullWidth={true}
                isOpen={showFilters === 'filter'}
                onClick={() =>
                  setShowFilters((value) =>
                    value === 'filter' ? null : 'filter'
                  )
                }
              >
                <FilterIcon />
                <p>{filter?.text ? filter.text : 'Choose Filter'}</p>
              </FilterButton>
              {showFilters === 'filter' && (
                <FilterContainer className="filter">
                  <InputSelect
                    options={filterOptions}
                    value={filter}
                    onChange={(value: SelectOption | null) => {
                      if (value !== null) onFilterIdChange(value?.id);
                      setShowFilters(null);
                      applyRef.current?.focus();
                    }}
                    inputValue={inputFilterValue}
                    onInputChange={setInputFilterValue}
                    open={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Search..."
                      />
                    )}
                    getOptionDisabled={(filterOption) =>
                      getAllRepairsDisabled(filterOption.id)
                    }
                  />
                </FilterContainer>
              )}
            </FilterItem>
          </MainFiltersContainer>
        </ClickAwayListener>
        <>
          <GridItem className="input-container">
            <Button
              ref={applyRef}
              autoFocus
              onClick={handleApplyClick}
              disabled={
                selectedRepairerId === undefined ||
                selectedFilterId === undefined
              }
            >
              Apply
            </Button>
          </GridItem>
          <DesktopContainer>
            <Activity
              device="desktop"
              repairs={repairs}
              showActivity={
                !!selectedRepairerId &&
                selectedRepairerId > 0 &&
                !!selectedFilterId
              }
            />
          </DesktopContainer>
        </>
      </>
    );
  }

  function handleApplyClick() {
    onApply();
    setIsFilterModalOpen(false);
  }

  function getAllRepairerDisabled(optionId?: number) {
    return (
      (filter &&
        filter.id ===
        filterOptions.find((o) => o.id === config.allRepairsFilterValue)
          ?.id &&
        repairersOptions.length > 0 &&
        optionId === repairersOptions[0].id) ??
      false
    );
  }

  function getAllRepairsDisabled(filterOptionId?: number) {
    return (
      (selectedRepairer &&
        selectedRepairer.id === repairersOptions[0].id &&
        filterOptionId === filterOptions[0].id) ??
      false
    );
  }
}

export const FilterButton = styled.button<{
  isOpen?: boolean;
  fullWidth?: boolean;
}>`
  font-size: ${({ theme }) => theme.fontSize.s};
  background: ${({ isOpen, theme }) =>
    isOpen ? theme.palette.primary.active : theme.palette.button.light};
  border: ${({ isOpen, theme }) =>
    isOpen
      ? `1px solid ${theme.palette.primary.activeBorder}`
      : `1px solid ${theme.palette.button.light}`};
  color: ${({ theme, isOpen }) =>
    isOpen ? theme.palette.primary.activeText : theme.palette.background.dark};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.border.radius};
  padding: 12px;
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;

  & svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  path {
    fill: ${({ theme, isOpen }) =>
    isOpen
      ? theme.palette.primary.activeText
      : theme.palette.background.dark};
  }
  :hover {
    cursor: pointer;
    background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.palette.primary.active : theme.palette.primary.hover};
    color: ${({ theme, isOpen }) =>
    isOpen
      ? theme.palette.primary.activeText
      : theme.palette.background.dark};
  }

  :active {
    background-color: ${({ theme }) => theme.palette.primary.active};
    border-color: ${({ theme }) => theme.palette.primary.activeBorder};
    color: ${({ theme }) => theme.palette.primary.activeText};
    path {
      fill: ${({ theme }) => theme.palette.primary.activeText};
    }
  }
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    & p {
      width: 250px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .MuiOutlinedInput-adornedEnd {
    padding: 1px;
  }

  ${({ fullWidth, theme }) =>
    fullWidth &&
    `@media (max-width: ${theme.breakpoints.values.md}px) {
      width: 100%;
      margin-bottom: ${theme.padding.s};}`};
`;

const FilterControlsStyles = (theme: Theme) => css`
  & .filter {
    & .MuiAutocomplete-root.filter {
      .MuiInputBase-root {
        opacity: 0;
      }

      & .MuiAutocomplete-paper {
        border-radius: ${theme.border.radius};
      }
    }

    & .MuiAutocomplete-popper {
      top: 0;
    }
  }
`;

const FilterItem = styled(GridItem)`
  position: relative;
`;
const FilterContainer = styled.div`
  position: absolute;
  top: 44px;
  width: 100%;
  background: #f1f8fc;
  z-index: 999;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;
const Button = styled(PrimaryButton)`
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  min-width: 0px;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const Group = styled(GridItem)`
  padding: ${({ theme }) => theme.margin.s};
  :hover {
    // commented due to not loose work what was done during repairers grouping implementation
    //background-color: rgba(0, 0, 0, 0.08);
    //cursor: pointer;
  }
`;

const MainFiltersContainer = styled('div')`
  display: flex;
  flex-direction: row;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
  }
`;
