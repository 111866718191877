import { useTheme } from '@emotion/react';
import { FormalStatus } from 'api/resources/models/AutoGenerated';
import StatusChip from 'components/StatusChip';
import { logger } from 'core/logger';
import { Theme } from 'core/Theming/theme';

interface FormalStatusProps {
  status: FormalStatus;
  statusDescription: string;
  dtgOnSite: Date | null;
}
export const FormalStatusField = ({
  status,
  statusDescription,
  dtgOnSite,
}: FormalStatusProps) => {
  const theme = useTheme();
  const props = { status, dtgOnSite, statusDescription };
  return <>{renderFormalStatus(props, theme)}</>;
};

function renderFormalStatus(props: FormalStatusProps, theme: Theme) {
  const statusColor = theme.palette.status;
  if (
    props.status === FormalStatus.Notified ||
    props.status === FormalStatus.New ||
    props.status === FormalStatus.Acknowledged ||
    props.status === FormalStatus.Liability ||
    props.status === FormalStatus.BookedIn ||
    props.status === FormalStatus.Unknown
  )
    return (
      <StatusChip
        toolTipMessage="Pre On Site"
        color={statusColor.preOnSite}
        description={props.statusDescription}
      />
    );
  else if (
    props.status === FormalStatus.Authorized ||
    props.status === FormalStatus.EstimateMerged ||
    props.status === FormalStatus.PartsOrder ||
    props.status === FormalStatus.AwaitingParts ||
    props.status === FormalStatus.TlPending
  )
    return (
      <StatusChip
        toolTipMessage={props.dtgOnSite ? 'On Site' : 'Pre On Site'}
        color={props.dtgOnSite ? statusColor.onSite : statusColor.preOnSite}
        description={props.statusDescription}
      />
    );
  else if (
    props.status === FormalStatus.OnSite ||
    props.status === FormalStatus.Started ||
    props.status === FormalStatus.Completed
  )
    return (
      <StatusChip
        toolTipMessage="On Site"
        color={statusColor.onSite}
        description={props.statusDescription}
      />
    );
  else if (
    props.status === FormalStatus.Delivered ||
    props.status === FormalStatus.Reopened
  )
    return (
      <StatusChip
        toolTipMessage="Delivered"
        color={statusColor.delivered}
        description={props.statusDescription}
      />
    );
  else if (
    props.status === FormalStatus.Finished ||
    props.status === FormalStatus.Cancelled ||
    props.status === FormalStatus.TotalLoss ||
    props.status === FormalStatus.Redirected
  )
    return (
      <StatusChip
        toolTipMessage="Terminal"
        color={statusColor.terminal}
        description={props.statusDescription}
      />
    );
  else
    logger.warn(
      `Couldn't handle formal status with value - ${props.status}. ` +
        `Mapping between proxy service value and api has to be done.`
    );
}
