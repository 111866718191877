import { OwnerReason } from 'api/resources/models/AutoGenerated';
import { GrayBackdrop, Loader } from 'components/Loader';
import { Modal } from 'components/Modal';
import { useOwnerContact } from 'pages/hooks';
import { useState } from 'react';
import { ContactOwner } from './ContactOwner';
import { ownerReasons } from './mappings';
import { ReasonsForm } from './ReasonsForm';

interface ContactOwnerModalProps {
  isOpen: boolean;
  onClose: () => void;
  repairId: number;
}

type ModalStep = 'OwnerInformation' | 'ReasonsForm';

export function ContactOwnerModal({
  isOpen,
  onClose,
  repairId,
}: ContactOwnerModalProps) {
  const [currentStep, setCurrentStep] = useState<ModalStep>('OwnerInformation');

  const ownerContact = useOwnerContact();

  const title =
    currentStep === 'OwnerInformation'
      ? 'Contact Owner'
      : 'Help Us Make It Better';
  return (
    <Modal
      isOpen={isOpen}
      title={title}
      // onClose={currentStep === 'OwnerInformation' ? undefined : onClose}
      onExited={() => setCurrentStep('OwnerInformation')}
    >
      <GrayBackdrop open={ownerContact.isRecording}>
        <Loader />
      </GrayBackdrop>
      {currentStep === 'OwnerInformation' ? (
        <ContactOwner
          repairId={repairId}
          onContinue={() => setCurrentStep('ReasonsForm')}
        ></ContactOwner>
      ) : (
        <ReasonsForm<OwnerReason>
          reasons={ownerReasons}
          defaultReason={OwnerReason.Other}
          onSubmit={onSubmit}
          title={'the owner'}
        ></ReasonsForm>
      )}
    </Modal>
  );

  function onSubmit(
    selectedReason: OwnerReason,
    additionalNotes: string | null
  ) {
    ownerContact
      .record(repairId, selectedReason, additionalNotes)
      .then(() => onClose());
  }
}
