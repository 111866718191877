import { BackButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { useParams } from 'react-router-dom';
import { Heading } from 'components/Typography';
import { useState } from 'react';
import { DataAccessDetails } from './DataAccessDetails';
import { DataAccessGeneralDetails } from './DataAccessGeneralDetails';
import { useDataAccessProfile } from 'pages/hooks';
import { AdminDataAccessProfileParams } from 'core/routes';
import { DataAccessProfile } from 'api/resources/models/AutoGenerated';
import { TabPanel, PageTab, PageTabItem } from 'components/Tab';
import { PageHeader, UpperInfoContainer } from 'components/PageLayout';

const useAdminDataAccessProfileParams = () => {
  const { id } = useParams<AdminDataAccessProfileParams>();
  const dataAccessProfileId = Number(id);
  return isNaN(dataAccessProfileId)
    ? 'Data access profile id must be a number'
    : dataAccessProfileId;
};

export function DataAccessProfileEdit() {
  const dataAccessProfileId = useAdminDataAccessProfileParams();
  if (typeof dataAccessProfileId === 'string') throw Error(dataAccessProfileId);
  const { dataAccessProfile } = useDataAccessProfile(dataAccessProfileId);
  return dataAccessProfile ? (
    <DataAccessProfilesDetails dataAccessProfile={dataAccessProfile} />
  ) : (
    <></>
  );
}

export function DataAccessProfileCreate() {
  return <DataAccessProfilesDetails />;
}

interface Props {
  dataAccessProfile?: DataAccessProfile;
}
export function DataAccessProfilesDetails({ dataAccessProfile }: Props) {
  const [selectedTab, setSelectedTab] = useState<string>('details');

  return (
    <UpperInfoContainer>
      <Container direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Data Access Profiles" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>
            {dataAccessProfile
              ? `Edit Data Access Profile "${dataAccessProfile?.name}"`
              : `Create Data Access Profile`}
          </Heading>
        </PageHeader>
      </Container>
      <Container direction="row">
        <Container>
          <GridItem md={10} sm={12} xs={12}>
            <PageTab
              value={selectedTab}
              onChange={(_e, value) => setSelectedTab(value)}
            >
              <PageTabItem label="Details" value="details" />
              <PageTabItem
                label="Data Access Detail"
                value="dataAccessDetail"
                disabled={!dataAccessProfile}
              />
            </PageTab>

            <TabPanel value={selectedTab} index="details">
              <DataAccessGeneralDetails dataAccessProfile={dataAccessProfile} />
            </TabPanel>

            <TabPanel value={selectedTab} index="dataAccessDetail">
              {dataAccessProfile && (
                <DataAccessDetails dataAccessProfileId={dataAccessProfile.id} />
              )}
            </TabPanel>
          </GridItem>
        </Container>
      </Container>
    </UpperInfoContainer>
  );
}
