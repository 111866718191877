import {
  ClaimSummary,
  Repair,
  Activity as ApiActivity,
  ServiceLevelAgreement as ApiServiceLevelAgreement,
  Permit,
} from 'api/resources/models/AutoGenerated';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import UTC from 'dayjs/plugin/utc';
import { ReplacementVehicleIcon } from '../../components/ReplacementVehicleIcon';
import { formatDate } from 'utils/helpers';
import { getCpdDetailsStatus } from 'components/VehiclesTable/fields/Cpd';
import { DateStatus } from 'components/VehiclesTable/fields/types';
import { getDeliveredDetailsStatus } from 'components/VehiclesTable/fields/Delivered';
import { getEcdDetailsStatus } from 'components/VehiclesTable/fields/Ecd';
import { getCompletedDetailsStatus } from 'components/VehiclesTable/fields/Completed';
import { getOnSiteDetailsStatus } from 'components/VehiclesTable/fields/onSite';
import { getBookedInDetailsStatus } from 'components/VehiclesTable/fields/bookedIn';
import {
  AdditionalStatus,
  additionalStatus,
} from 'components/VehiclesTable/fields/additionalStatus';
import WarningAmber from '@mui/icons-material/WarningRounded';
dayjs.extend(localizedFormat);
dayjs.extend(UTC);

const locales = (locale: string) => import(`dayjs/locale/${locale}.js`);

const currentLocale =
  navigator.language.toLowerCase() === 'en-us'
    ? 'en'
    : navigator.language.toLowerCase();

locales(currentLocale).then(() => {
  dayjs.locale(currentLocale);
});

export type Activity = {
  activityDate: Date;
  message: string | null;
  operator: string | null;
  icon: string | null;
  category: string | null;
  complaint: boolean;
};

export type ServiceLevelAgreement = {
  title?: string | null;
  description?: string | null;
  startDate?: string;
  expectedValue?: string;
  actualValue?: string;
  status?: string | null;
  icon?: string | null;
};

export type QuickDetails = {
  title: string;
  value?: string | JSX.Element | null;
  permit?: Permit;
};

export type Milestone = {
  name: string;
  date?: string;
  status?: DateStatus;
  additionalStatus?: AdditionalStatus;
  permit?: Permit;
};

export type Information = {
  name: string;
  value: string | JSX.Element | number;
  permit?: Permit;
};

export const mapQuickDetails = (data: ClaimSummary): QuickDetails[] => {
  return [
    {
      title: 'Owner',
      value: data.ownerName,
      permit: Permit.FLV_OWNER,
    },
    {
      title: 'Repair Code',
      value: data.repairCode,
    },
    {
      title: 'Vehicle Registration',
      value: data.vehicleRegistration,
    },
    {
      title: 'Vehicle',
      value: data.vehicle,
    },
    { title: 'Stream', value: data.stream },
    {
      title: 'Claim Reference',
      value: data.claimReference,
    },
  ];
};

export const mapMilestoneDetails = (
  data: ClaimSummary,
  details: Repair
): Milestone[] => {
  const now = dayjs();
  const getDate = (date: null | string | Date | undefined) =>
    date ? dayjs(date) : undefined;

  return [
    {
      name: 'FNOL',
      date: formatDate(data.milestone?.firstActivityDate),
    },
    {
      name: 'Booked In',
      date: formatDate(data.milestone?.bookedInDate, 'L'),
      status: getBookedInDetailsStatus(
        now,
        getDate(data.milestone?.onSiteDate),
        getDate(data.milestone?.bookedInDate)
      ),
    },
    {
      name: 'On Site',
      date: formatDate(data.milestone?.onSiteDate),
      status: getOnSiteDetailsStatus(
        now,
        getDate(data.milestone?.bookedInDate),
        getDate(data.milestone?.onSiteDate)
      ),
      additionalStatus: additionalStatus(
        getDate(data.milestone?.bookedInDate),
        getDate(data.milestone?.onSiteDate)
      ),
    },
    {
      name: 'Authorised',
      date: formatDate(data.milestone?.insAuthorisedDate),
    },
    {
      name: 'Work Start',
      date: formatDate(data.milestone?.startedDate),
    },
    {
      name: 'E.C.D.',
      date: formatDate(details?.dtgEcd, 'L'),
      status: getEcdDetailsStatus(
        now,
        getDate(details?.dtgEcd),
        getDate(details?.dtgCompleted)
      ),
    },
    {
      name: 'Work Done',
      date: formatDate(details.dtgCompleted),
      status: getCompletedDetailsStatus(
        now,
        getDate(details?.dtgEcd),
        getDate(details?.dtgCompleted)
      ),
      additionalStatus: additionalStatus(
        getDate(details.dtgEcd),
        getDate(details.dtgCompleted)
      ),
    },
    {
      name: 'Booked Out / C.P.D',
      date: formatDate(details?.dtgCpd, 'L'),
      status: getCpdDetailsStatus(
        now,
        getDate(details.dtgCpd),
        getDate(details.dtgDelivered)
      ),
    },
    {
      name: 'Delivered',
      date: formatDate(data.milestone?.deliveredDate),
      status: getDeliveredDetailsStatus(
        now,
        getDate(details.dtgCpd),
        getDate(details.dtgDelivered)
      ),
      additionalStatus: additionalStatus(
        getDate(details.dtgCpd),
        getDate(details.dtgDelivered)
      ),
    },
  ];
};

export const mapClaimInformation = (
  data: ClaimSummary,
  repairDetails: Repair
): Information[] => {
  return [
    {
      name: 'Claim ID',
      value: data?.claimId ?? '-',
    },
    {
      name: 'Owner Excess',
      value: data?.ownerExcess ?? '-',
    },
    {
      name: 'Owner Paid',
      value: data?.ownerExcessPaid ?? '-',
    },
    {
      name: 'Possible Total Loss',
      value: data?.possibleTL ? <WarningAmber className="image" /> : '-',
    },
    {
      name: 'Repair Stage',
      value: repairDetails.repairStage ? repairDetails.repairStage : '-',
    },
    {
      name: 'Repairer Group',
      value: data?.repairer?.group ?? '-',
    },
    {
      name: 'Repairer Name',
      value: data?.repairer?.name ?? '-',
    },
  ];
};

export const mapMobilityInformation = (
  data: ClaimSummary,
  repair: Repair
): Information[] => {
  const isAnyOfCourtesyMakeOrModel =
    !!data.replacementVehicle?.courtesyMake ||
    !!data.replacementVehicle?.courtesyModel;
  return [
    {
      name: 'Drivable',
      value:
        data.isVehicleDriveable === null
          ? '-'
          : data.isVehicleDriveable
          ? 'Yes'
          : 'No',
    },
    {
      name: 'Replacement Vehicle',
      value: isAnyOfCourtesyMakeOrModel
        ? `${data.replacementVehicle?.courtesyMake ?? ''} ${
            data.replacementVehicle?.courtesyModel ?? ''
          }`.trim()
        : '-',
    },
    {
      name: 'Replacement Vehicle Reg. No.',
      value: data.replacementVehicle?.replacementReg?.trim()
        ? data.replacementVehicle?.replacementReg
        : '-',
    },
    {
      name: 'Status',
      value: (
        <ReplacementVehicleIcon status={repair.replacementVehicleStatus} />
      ),
    },
  ];
};

export const mapActivity = (activity: ApiActivity): Activity => {
  return {
    activityDate: dayjs(activity.activityDate).toDate(),
    operator: activity.operator,
    message: activity.message,
    icon: activity.icon,
    category: activity.activityCategory,
    complaint: activity.complaint,
  };
};

export const mapSlas = (item: ApiServiceLevelAgreement) => {
  return {
    title: item.title ?? '',
    description: item.description ?? '',
    startDate: formatDate(item.startDate),
    expectedValue: formatDate(item.expectedValue),
    actualValue: formatDate(item.actualValue),
    icon: item.icon,
    status: item.status,
  };
};
