import { getBadRequestErrorMessage } from "api";
import { AxiosError } from "axios";
import PrimaryToast from "components/PrimaryToast";
import { useCreateCompany } from "pages/hooks";
import React, { useState } from "react";
import { CompaniesDetails } from "./CompaniesDetails";
import { Company, convertToAPIData } from "./form";

export function CompaniesCreate() {
    const { createCompany, isCreating } = useCreateCompany();
    const [toastState, setToastState] = useState<{
        message: string;
        isOpen: boolean;
        severity: 'error' | 'success';
    }>({
        message: '',
        isOpen: false,
        severity: 'success',
    });
    return (
        <React.Fragment>
            <CompaniesDetails onSubmit={onSubmit} isBusy={isCreating} />
            <PrimaryToast
                message={toastState.message}
                isOpen={toastState.isOpen}
                onClose={() => setToastState({ ...toastState, isOpen: false })}
                severity={toastState.severity}
            />
        </React.Fragment>
    );
    function onSubmit(data: Company) {
        createCompany(convertToAPIData({ ...data }))
            .then(() => {
                setToastState({
                    message: 'Company successfully created',
                    isOpen: true,
                    severity: 'success',
                });
            })
            .catch((error: AxiosError) => handleError(error));
    }

    function handleError(error: AxiosError) {
        setToastState({
            message: getBadRequestErrorMessage(error),
            isOpen: true,
            severity: 'error',
        });
    }
}
