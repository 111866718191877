import { DtlItem, SortOrder } from 'api/resources/models/AutoGenerated';

export interface DtlListHeader {
  id: keyof DtlItem;
  label: string;
}

export const dtlTableHeaders: DtlListHeader[] = [
  { id: 'repairCode', label: 'Repair Code' },
  { id: 'claimReference', label: 'Claim Ref.' },
  { id: 'vehicleReg', label: 'Vehicle Reg.' },
  { id: 'schemeCode', label: 'Scheme Code' },
  { id: 'formalStatus', label: 'Status' },
  { id: 'dtgCreated', label: 'Created' },
  { id: 'dtgUpdated', label: 'Last Updated' },
  { id: 'dtgEcd', label: 'E.C.D' },
  { id: 'vehicleMake', label: 'Vehicle' },
  { id: 'repairerGroup', label: 'Repair Group' },
  { id: 'repairStage', label: 'Repair Stage' },
];

type DtlListParams = {
  sortString: keyof DtlItem | null;
  sortOrder: SortOrder | null;
};

export function useDtlListParams(url: string): DtlListParams {
  const urlParams = new URLSearchParams(url);

  const sort = urlParams.get('sort');
  const sortOrder = urlParams.get('sortDirection') as SortOrder;
  return {
    sortString: sort as keyof DtlItem,
    sortOrder: Object.values(SortOrder).includes(sortOrder) ? sortOrder : null,
  };
}
