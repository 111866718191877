import {
  Column,
  DataTypeProvider,
  DataTypeProviderProps,
  Row,
} from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import { Heading } from 'components/Typography';
import { BackButton, PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import {
  Label,
  MainPageContainer,
  PageHeader,
  UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { useInMobile } from 'core/Theming/Device';
import dayjs from 'dayjs';
import { useInvoiceExtractReport } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { convertToDate } from 'pages/hooks';
import InfoText from 'components/InfoText';
import { GrayBackdrop, Loader } from 'components/Loader';
import { Select } from 'components/Select';

import { formatDate } from 'utils/helpers';
import { ROUTES } from 'core/routes';
import { ReportTitleInfo } from 'components/ReportTitleInfo';

interface Props {
  reportPath: string;
}

export function InvoiceExtract({ reportPath }: Props) {
  const isMobile = useInMobile();
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    startDate: urlParams.get('startDate'),
    endDate: urlParams.get('endDate'),
    dataAccessProfileName: urlParams.get('dataAccessProfileName'),
  };

  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParamsValue.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParamsValue.endDate) || null
  );
  const [dataAccessProfileName, setDataAccessProfileName] = useState<
    string | null
  >(urlParamsValue.dataAccessProfileName || null);
  const dateToString = (date: Date | null) => dayjs(date).format('YYYY-MM-DD');

  const queryEnabled = !!urlParamsValue.startDate && !!urlParamsValue.endDate;
  const { reportData, isReportFetching, isFetched } = useInvoiceExtractReport(
    {
      startDate: urlParamsValue.startDate,
      endDate: urlParamsValue.endDate,
      dataAccessProfileName: urlParamsValue.dataAccessProfileName,
    },
    queryEnabled
  );

  const [columns] = useState<Column[]>([
    { name: 'activeWebID', title: 'Active Web ID' },
    { name: 'repairerCode', title: 'Repairer Code' },
    { name: 'repairer_Name', title: 'Repairer Name' },
    { name: 'created_Date', title: 'Created Date' },
    { name: 'finalisationDate', title: 'Finalisation Date' },
    { name: 'scheme_Code', title: 'Scheme Code' },
    { name: 'claimReference', title: 'Claim Reference' },
    { name: 'registration', title: 'Registration' },
  ]);

  type DateFormatterProps = DataTypeProvider.ValueFormatterProps;

  const DateFormatter = ({ value }: DateFormatterProps) => (
    <div>{formatDate(value, 'L')}</div>
  );

  const DateTypeProvider: React.ComponentType<DataTypeProviderProps> = (
    props: DataTypeProviderProps
  ) => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

  const rows = useMemo<Row>(() => reportData || [], [reportData]);

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlParamsValue.startDate,
    urlParamsValue.endDate,
    urlParamsValue.dataAccessProfileName,
  ]);

  const workProviderOptions = [
    { id: 1, label: 'Allianz', value: 'ALLIANZ - MANAGER TEMPLATE' },
    { id: 2, label: 'RSA', value: 'RSA SRM PLUS TEMPLATE' },
    {
      id: 3,
      label: 'Direct Line Group',
      value: 'DLG AREA NETWORK CONTROLLER/MANAGER/REG MGR',
    },
    { id: 4, label: 'NARG', value: 'NARG ADMIN TEMPLATE' },
    { id: 5, label: 'LV', value: 'LV= MANAGER TEMPLATE' },
    { id: 6, label: 'Esure', value: 'Esure Claims Supervisor TEMPLATE' },
    { id: 7, label: 'Covea', value: 'COVEA CLAIM HANDLER TEMPLATE' },
    {
      id: 8,
      label: 'NNS',
      value: 'NATIONWIDE NETWORK SERVICE TEMPLATE USER (NWS)',
    },
    { id: 9, label: 'Kindertons', value: 'KINDERTONS - MANAGER TEMPLATE' },
    { id: 10, label: 'Avant', value: 'AVANT MANAGER TEMPLATE' },
  ];

  return (
    <MainPageContainer
      isLoading={isReportFetching}
      direction="column"
      wrap="nowrap"
    >
      <GrayBackdrop open={isReportFetching}>
        <Loader />
      </GrayBackdrop>
      <UpperInfoContainer direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Reports" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>
            <ReportTitleText>
              <div>Invoice Extract Report - PPG Internal</div>
              <ReportTitleInfo
                message="Invoicing extract for monthly reporting - Download to Excel and keep in sharepoint for audit purposes (PPG ONLY)"
                title="Invoice Extract Report - PPG Internal"
              />
            </ReportTitleText>
          </Heading>
        </PageHeader>
        <CustomForm onSubmit={handleSubmit}>
          <Container direction="row">
            <InputButton md={3} sm={4} xs={12}>
              <Label>Start Date</Label>
              <DatePickerField
                onChange={(date) => setStartDate(date)}
                value={startDate}
                maxDate={endDate}
              />
            </InputButton>
            <InputButton md={3} sm={4} xs={12}>
              <Label>End Date</Label>
              <DatePickerField
                onChange={(date) => setEndDate(date)}
                value={endDate}
                minDate={startDate}
              />
            </InputButton>
            <InputButton md={3} sm={4} xs={12}>
              <Label>Work Provider</Label>
              <Select
                selectedId={
                  workProviderOptions.find(
                    (status) => status.value === dataAccessProfileName
                  )?.id
                }
                onChange={(status) => setDataAccessProfileName(status.value)}
                options={workProviderOptions.map((status) => ({
                  id: status.id,
                  value: status.value,
                  text: status.label ?? '',
                }))}
              />
            </InputButton>
            <GridItem md={1} sm={1} xs={2}>
              <Button
                type="submit"
                disabled={
                  !startDate || !endDate || dataAccessProfileName == null
                }
              >
                {renderSearchIcon()}
                {renderSearchButtonText()}
              </Button>
            </GridItem>
          </Container>
        </CustomForm>
      </UpperInfoContainer>
      {isFetched && !isReportFetching && rows && rows?.length === 0 && (
        <CustomInfoText>
          {"Sorry, we couldn't find any reports with this search criteria."}
        </CustomInfoText>
      )}
      <ReportGridContainer direction="column">
        {isFetched && !isReportFetching && rows && rows?.length > 0 && (
          <ReportGrid
            columns={columns}
            rows={rows}
            DateTypeProvider={DateTypeProvider}
            dateColumnsName={['created_Date', 'finalisationDate']}
            title="InvoiceExtractPPG"
          />
        )}
      </ReportGridContainer>
    </MainPageContainer>
  );
  function renderSearchIcon() {
    if (isMobile) return <Search />;
    return null;
  }

  function renderSearchButtonText() {
    if (!isMobile) return 'View';
    return null;
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!startDate || !endDate || dataAccessProfileName == null) return;
    navigateToReport();
  }
  function createReportURL(
    startDateValue: string,
    endDateValue: string,
    dataAccessProfileNameValue: string | null,
    reportName: string
  ): string {
    const url = new URLSearchParams();
    if (startDateValue) url.set('startDate', startDateValue);
    if (endDateValue) url.set('endDate', endDateValue);
    if (dataAccessProfileNameValue)
      url.set('dataAccessProfileName', dataAccessProfileNameValue);
    return `${ROUTES.reports}/${reportName}?${url.toString()}`;
  }

  function navigateToReport() {
    history.replace(
      createReportURL(
        dateToString(startDate),
        dateToString(endDate),
        dataAccessProfileName,
        reportPath
      )
    );
  }
}

const InputButton = styled(GridItem)`
  padding-right: ${({ theme }) => theme.margin.s};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 6px;
  }
  & svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const ReportGridContainer = styled(Container)`
  padding-left: ${({ theme }) => theme.padding.xl};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;

const CustomInfoText = styled(InfoText)`
  margin-top: 0;
`;

const ReportTitleText = styled('div')`
  display: flex;
`;
