import {
  ClaimDetail,
  RepairManualUpdate as ApiRepairManualUpdate,
} from 'api/resources/models/AutoGenerated';
import dayjs from 'dayjs';

export const dateToString = (date: Date) =>
  dayjs(date).format('YYYY-MM-DDTHH:mm:ss');

export const getApiForm = ({
  invoice,
  replacementVehicle,
  milestone,
  claimDetail,
}: RepairManualUpdate): ApiRepairManualUpdate => ({
  invoice: invoice && {
    ...invoice,
    firstInvoiceDate: invoice.firstInvoiceDate
      ? dateToString(invoice.firstInvoiceDate)
      : null,
  },
  replacementVehicle: replacementVehicle && {
    ...replacementVehicle,
    dateIn: replacementVehicle.dateIn
      ? dateToString(replacementVehicle.dateIn)
      : null,
    dateOut: replacementVehicle.dateOut
      ? dateToString(replacementVehicle.dateOut)
      : null,
  },
  milestone: {
    partsOrderedDate: milestone?.partsOrderedDate
      ? dateToString(milestone.partsOrderedDate)
      : null,
    estimationDate: milestone?.estimationDate
      ? dateToString(milestone.estimationDate)
      : null,
    partsInDate: milestone?.partsInDate
      ? dateToString(milestone.partsInDate)
      : null,
    createdDate: milestone?.createdDate
      ? dateToString(milestone.createdDate)
      : null,
    updatedDate: milestone?.updatedDate
      ? dateToString(milestone.updatedDate)
      : null,
    insAuthorisedDate: milestone?.insAuthorisedDate
      ? dateToString(milestone.insAuthorisedDate)
      : null,
    firstActivityDate: milestone?.firstActivityDate
      ? dateToString(milestone.firstActivityDate)
      : dateToString(new Date()),
    notificationDate: milestone?.notificationDate
      ? dateToString(milestone.notificationDate)
      : dateToString(new Date()),
    incidentDate: milestone?.incidentDate
      ? dateToString(milestone.incidentDate)
      : null,
    bookedOutDate: milestone?.bookedOutDate
      ? dateToString(milestone.bookedOutDate)
      : null,
    bookedInDate: milestone?.bookedInDate
      ? dateToString(milestone.bookedInDate)
      : null,
    onSiteDate: milestone?.onSiteDate
      ? dateToString(milestone.onSiteDate)
      : null,
    startedDate: milestone?.startedDate
      ? dateToString(milestone.startedDate)
      : null,
    completedDate: milestone?.completedDate
      ? dateToString(milestone.completedDate)
      : null,
    deliveredDate: milestone?.deliveredDate
      ? dateToString(milestone.deliveredDate)
      : null,
  },
  claimDetail: claimDetail && {
    ...claimDetail,
  },
});

export interface RepairManualUpdate {
  milestone: Milestone | null;
  invoice: Invoice | null;
  replacementVehicle: ReplacementVehicle | null;
  claimDetail: ClaimDetail | null;
}

export interface Milestone {
  partsOrderedDate: Date | null;
  estimationDate: Date | null;
  partsInDate: Date | null;
  createdDate: Date | null;
  updatedDate: Date | null;
  insAuthorisedDate: Date | null;
  firstActivityDate: Date | null;
  notificationDate: Date;
  incidentDate: Date | null;
  bookedOutDate: Date | null;
  bookedInDate: Date | null;
  onSiteDate: Date | null;
  startedDate: Date | null;
  completedDate: Date | null;
  deliveredDate: Date | null;
}

export interface ReplacementVehicle {
  courtesyMake: string | null;
  courtesyModel: string | null;
  replacementReg: string | null;
  dateIn: Date | null;
  dateOut: Date | null;
  replacementVehicleStatus: ReplacementVehicleStatus;
}

export interface Invoice {
  firstInvoiceDate: Date | null;
  labourHours: number | null;
  invoicedLabour: number | null;
  invoicedParts: number | null;
  invoicedPaint: number | null;
  invoicedOther: number | null;
  invoicedTax: number | null;
  invoicedTotal: number | null;
  ownerExcess: number | null;
  ownerExcessPaid: number | null;
}

export enum ReplacementVehicleStatus {
  NotRequired = 'NotRequired',
  IsRequired = 'IsRequired',
  AssignedOut = 'AssignedOut',
  AssignedReturned = 'AssignedReturned',
}
