/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { Container, GridItem } from 'components/Layout';
import { Text } from 'components/Typography';
import { logger } from 'core/logger';
import { MenuItem, Pagination, TextField } from '@mui/material';
import { useInDesktop } from 'core/Theming/Device';
import { useRowCountPerPage } from './hooks';

export interface TablePaginationProps {
  totalCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  onItemsPerPageChange?: (count: number) => void;
}

export function TablePagination({
  totalCount,
  onPageChange,
  currentPage,
  onItemsPerPageChange,
}: TablePaginationProps) {
  const isDesktop = useInDesktop();
  const { setItemsPerPage, availableRowsPerPage, itemsPerPage } =
    useRowCountPerPage();
  const pageInfo = getPageInfo({
    totalCount,
    itemsPerPage,
    currentPage,
  });

  if (typeof pageInfo === 'string') {
    logger.error('Unable to render pagination component', pageInfo);
    return null;
  }

  const { pageEndIndex, pageStartIndex } = pageInfo;

  return (
    <PaginationContainer>
      <GridItem>
        <StyledPagination
          color="primary"
          count={Math.ceil(totalCount / itemsPerPage)}
          shape="rounded"
          page={currentPage + 1}
          onChange={(_e, page) => {
            onPageChange(page - 1);
            scrollToTop();
          }}
          siblingCount={1}
          boundaryCount={1}
        />
      </GridItem>
      <GridItem>
        <CenteredContainer
          justify={isDesktop ? 'flex-start' : 'center'}
          alignItems="center"
        >
          <Text>
            {pageStartIndex}-{pageEndIndex} items out of {totalCount}
          </Text>
        </CenteredContainer>
      </GridItem>
      <GridItem>
        <CenteredContainer
          justify={isDesktop ? 'flex-start' : 'center'}
          alignItems="center"
        >
          <Text>Rows per page</Text>
          <StyledSelect
            select
            variant="outlined"
            value={itemsPerPage}
            onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
          >
            {availableRowsPerPage.map((rowsCount, index) => (
              <MenuItem key={index} value={rowsCount}>
                <Text fontSize="m">{rowsCount}</Text>
              </MenuItem>
            ))}
          </StyledSelect>
        </CenteredContainer>
      </GridItem>
    </PaginationContainer>
  );

  function handleItemsPerPageChange(itemsCount: number) {
    setItemsPerPage(itemsCount).then(() => {
      const pageCount = Math.ceil(totalCount / itemsCount);
      if (currentPage > pageCount) onPageChange(pageCount - 1);
      onItemsPerPageChange?.(itemsCount);
      scrollToTop();
    });
  }

  function scrollToTop() {
    window.scrollTo(0, 0);
  }
}

export function getPageInfo({
  totalCount,
  itemsPerPage,
  currentPage,
}: {
  totalCount: number;
  itemsPerPage: number;
  currentPage: number;
}): { pageStartIndex: number; pageEndIndex: number } | string {
  if (currentPage < 0) return `page index can't be less than 0`;
  if (itemsPerPage < 1) return `items per page can't be less than 1`;

  const pageStartItem = currentPage * itemsPerPage + 1;
  const currentPageItemsCount = (currentPage + 1) * itemsPerPage;
  const pageItemsCount =
    currentPageItemsCount > totalCount ? totalCount : currentPageItemsCount;
  return { pageStartIndex: pageStartItem, pageEndIndex: pageItemsCount };
}

const PaginationContainer = styled(Container)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    justify-content: center;
    padding-bottom: ${({ theme }) => theme.padding.m};
  }
`;

const StyledPagination = styled(Pagination)`
  & .MuiPagination-ul {
    flex-wrap: nowrap;
    padding: ${({ theme }) => theme.padding.m} 0;
  }

  & .MuiPaginationItem-root {
    background-color: ${({ theme }) => theme.palette.button.light};
    color: ${({ theme }) => theme.palette.primary.main};
    border-radius: ${({ theme }) => theme.border.radius};
    :hover {
      background-color: ${({ theme }) => theme.palette.button.hover};
      color: ${({ theme }) => theme.palette.primary.light};
    }
    :active {
      background-color: ${({ theme }) => theme.palette.button.active};
    }
  }

  & .Mui-selected {
    color: ${({ theme }) => theme.palette.primary.light};
    background-color: ${({ theme }) => theme.palette.button.dark};
  }
`;

const CenteredContainer = styled(Container)`
  height: 100%;
  margin-right: 100px;
`;

const StyledSelect = styled(TextField)`
  margin-left: 5px;
  fieldset {
    border: 0px;
  }
  .MuiSelect-root {
    border: 0px;
    background-color: ${({ theme }) => theme.palette.button.light};
    padding: ${({ theme }) => theme.padding.s};
    border-radius: ${({ theme }) => theme.border.radius};
  }

  svg {
    display: none;
  }
`;
