import { BackButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { useParams } from 'react-router-dom';
import { Heading } from 'components/Typography';
import { useState } from 'react';
import { PageTab, PageTabItem, TabPanel } from 'components/Tab';
import { UserProfiles } from './UserProfiles';
import { AdminUserAccountParams } from 'core/routes';
import { getUserAccountCashKey, useUserAccount } from 'pages/hooks';
import { UserSecurityInfo } from './UserSecurityInfo';
import { UserAccount, getFormData } from './form';
import { UserCreate } from './UserCreate';
import { UserEdit } from './UserEdit';
import { queryClient } from 'api/client';
import { GrayBackdrop, Loader } from 'components/Loader';
import PrimaryToast from 'components/PrimaryToast';
import { PageHeader, UpperInfoContainer } from 'components/PageLayout';

const useAdminUserAccountParams = () => {
  const { id: rawUserId } = useParams<AdminUserAccountParams>();
  const userId = Number(rawUserId);
  return isNaN(userId) ? 'User id must be a number' : userId;
};

export function UserAccountEdit() {
  const userId = useAdminUserAccountParams();
  if (typeof userId === 'string') throw new Error(userId);
  const { userAccount: user, isLoading } = useUserAccount(userId);
  return user ? (
    <UserAccountDetails user={getFormData(user)} />
  ) : (
    <GrayBackdrop open={isLoading}>
      <Loader />
    </GrayBackdrop>
  );
}

export function UserAccountCreate() {
  return <UserAccountDetails />;
}

interface Props {
  user?: UserAccount;
}

export function UserAccountDetails({ user }: Props) {
  const [selectedTab, setSelectedTab] = useState<string>('details');
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);

  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });

  return (
    <UpperInfoContainer direction="column">
      <Container direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to User Accounts" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>
            {user
              ? `Edit User Account "${user?.userName}"`
              : `Create New User Account`}
          </Heading>
        </PageHeader>
      </Container>
      <Container direction="column">
        <Container>
          <GridItem md={6} sm={12} xs={12}>
            <PageTab
              value={selectedTab}
              onChange={(_e, value) => setSelectedTab(value)}
            >
              <PageTabItem label="Details" value="details" />
              <PageTabItem
                label="User Account Profiles"
                value="userAccountProfiles"
                disabled={!user}
              />
            </PageTab>
          </GridItem>
        </Container>
        <Container direction="row">
          <GridItem md={6} sm={12} xs={12}>
            <TabPanel value={selectedTab} index="details">
              {user ? <UserEdit
                user={user}
                toastHandler={toastHandler}
                blockNavigationHandler={blockNavigationHandler}
              /> : <UserCreate
                toastHandler={toastHandler}
                blockNavigationHandler={blockNavigationHandler}
              />}
            </TabPanel>

            {user && (
              <TabPanel value={selectedTab} index="userAccountProfiles">
                <UserProfiles user={user} />
              </TabPanel>
            )}
          </GridItem>
          <GridItem md={6} sm={12} xs={12}>
            {user && (
              <UserSecurityInfo
                user={user}
                onUserChange={handleUserDisableEnableChange}
                onUserAction={(message) =>
                  setToastState({
                    isOpen: true,
                    message,
                    severity: 'success',
                  })
                }
              />
            )}
          </GridItem>
        </Container>
      </Container>
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => setToastState({ ...toastState, isOpen: false })}
        severity={toastState.severity}
      />
    </UpperInfoContainer>
  );

  function blockNavigationHandler(payload: boolean) {
    if (payload) {
      !shouldBlockNavigation && setShouldBlockNavigation(payload);
    } else {
      setShouldBlockNavigation(payload);
    }
  }


  function toastHandler(): boolean {
    if (shouldBlockNavigation) {
      setToastState({
        message: "Please save your changes",
        isOpen: true,
        severity: 'error',
      })
      return false;
    } else {
      return true;
    }
  }

  function handleUserDisableEnableChange(successMessage: string) {
    if (user?.id) {
      queryClient.invalidateQueries(getUserAccountCashKey(user.id));
      queryClient.fetchQuery(getUserAccountCashKey(user.id)).then(() => {
        setToastState({
          isOpen: true,
          message: successMessage,
          severity: 'success',
        });
      });
    }
  }
}
