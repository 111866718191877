import { api } from 'api';
import { config } from 'core/config';
import {
  CoveaReport,
  GoodWillReport,
  NPSBillingReport,
  RenderExtension,
  TotalLossReport,
  SsrsReportParameter,
  InvoiceReport,
  SmsReport,
  CycleTimeReportExV2,
  Measure30Result,
  WorkInProgress,
  SLAPerformance,
  SMSBillingReport,
} from './models/AutoGenerated';
import * as qs from 'qs';

const reportsUrl = '/api/reports';

export const getHtmlReport = (
  reportPath: string,
  pageNumber: number,
  parameters: { [name: string]: string }[]
): Promise<string> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/${reportPath}.html`, {
      params: {
        pageNumber,
        parameters,
      },
      paramsSerializer: (params) => qs.stringify(params),
    })
    .then((response) => response.data);

export const getReportFile = (
  reportPath: string,
  parameters: { [name: string]: string }[]
) =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/file/${reportPath}`, {
      params: {
        parameters,
      },
      paramsSerializer: (params) => qs.stringify(params),
      responseType: 'blob',
    })
    .then((response) => {
      return response;
    });

export const getReportParameters = (
  reportPath: string
): Promise<SsrsReportParameter[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/${reportPath}/parameters`)
    .then((response) => response.data);

export const getRenderExtensions = (): Promise<RenderExtension[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/extensions`)
    .then((response) => response.data);

export const getReportPageCount = (
  reportPath: string,
  parameters: { [name: string]: string }[]
): Promise<number> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/${reportPath}/page-count`, {
      params: {
        parameters,
      },
      paramsSerializer: (params) => qs.stringify(params),
    })
    .then((response) => response.data);

export const getRepairStatusReportCovea = (
  startDate: string | null,
  endDate: string | null
): Promise<CoveaReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/covea`, {
      params: { startDate, endDate },
    })
    .then((response) => response.data);

export const getNPSBillingReport = (
  startDate: string | null,
  endDate: string | null
): Promise<NPSBillingReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/nps`, {
      params: { startDate, endDate },
    })
    .then((response) => response.data);

export const getSMSReport = (
  startDate: string | null,
  endDate: string | null
): Promise<SmsReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/sms`, {
      params: { startDate, endDate },
    })
    .then((response) => response.data);

export const getWorkInProgressV3 = (
  startDate: string | null,
  endDate: string | null,
  repairerGroup: string | null
): Promise<WorkInProgress> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/workinprogressv3`, {
      params: { startDate, endDate, repairerGroup },
    })
    .then((response) => response.data);

export const getSMSBillingReport02 = (
  startDate: string | null,
  endDate: string | null,
  workProvider: string | null
): Promise<SMSBillingReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/smsbilling`, {
      params: { startDate, endDate, wpMasterName: workProvider },
    })
    .then((response) => response.data);

export const gettotalLoss = (
  startDate: string | null,
  endDate: string | null
): Promise<TotalLossReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/totalLossSchedule`, {
      params: { startDate, endDate },
    })
    .then((response) => response.data);

export const getDLProposedGoodwillReport = (
  startDate: string | null,
  endDate: string | null
): Promise<GoodWillReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/goodwill`, {
      params: { startDate, endDate },
    })
    .then((response) => response.data);

export const getSLAPerformanceV3Report = (
  startDate: string | null,
  endDate: string | null,
  repairerGroup: string | null,
  siteCode: string | null,
): Promise<SLAPerformance> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/slaperformancev3`, {
      params: { startDate, endDate, repairerGroup, siteCode },
    })
    .then((response) => response.data);

export const getInvoiceExtractReport = (
  startDate: string | null,
  endDate: string | null,
  dataAccessProfileName: string | null
): Promise<InvoiceReport> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/invoice`, {
      params: { startDate, endDate, dataAccessProfileName },
    })
    .then((response) => response.data);

export const getDetailedCycleTimeReport = (
  startDate: string | null,
  endDate: string | null,
  repairerGroup: string | null,
  siteCode: string | null,
  showExep: boolean
): Promise<CycleTimeReportExV2[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/detailedcycletimeteportexv2`, {
      params: { startDate, endDate, repairerGroup, siteCode, showExep },
    })
    .then((response) => response.data);

export const getMeasureFuture30report = (
  runDate: string | null,
  repairerGroup: string | null,
  siteCodes: string | null,
  rollingMeasureCodes: string | null,
  wpMasterIds:string | null,
): Promise<Measure30Result[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/measurefuture30report`, {
      params: { runDate, repairerGroup, siteCodes, rollingMeasureCodes, wpMasterIds},
    })
    .then((response) => response.data);

export const getMeasureRolling30Report = (
  runDate: string | null,
  repairerGroup: string | null,
  siteCodes: string | null,
  rollingMeasureCodes: string | null,
  wpMasterIds:string | null,
): Promise<Measure30Result[]> =>
  api
    .get(`${config.activewebApiUrl}${reportsUrl}/measurerolling30report`, {
      params: { runDate, repairerGroup, siteCodes, rollingMeasureCodes, wpMasterIds},
    })
    .then((response) => response.data);
