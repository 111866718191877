import { ROUTES } from 'core/routes';

export interface ListItem {
  name: string;
  link: string;
  disabled?: boolean;
}

export const listItem: ListItem[] = [
  { name: 'Manage User Accounts', link: ROUTES.userAccounts },
  { name: 'Manage Account Profiles', link: ROUTES.accountProfiles },
  { name: 'Manage Data Access Profiles', link: ROUTES.dataAccessProfiles },
  { name: 'Manage Companies', link: ROUTES.companies, disabled: false },
];
