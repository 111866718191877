/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { PrimaryButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { useState } from 'react';
import { PossibleReason, Reason } from './types';
import { ErrorText, Text } from 'components/Typography';
import { TextArea } from 'components/TextArea';
import { css, Theme } from '@emotion/react';
import { RequiredLabel } from 'components/PageLayout';
import { MainModalContainer } from 'components/ModalLayout';

interface ReasonsFormsProps<T extends PossibleReason> {
  reasons: Reason<T>[];
  defaultReason: T;
  onSubmit: (selectedReason: T, additionalNotes: string | null) => void;
  title: string;
}

export function ReasonsForm<T extends PossibleReason>({
  onSubmit,
  reasons,
  defaultReason,
  title,
}: ReasonsFormsProps<T>) {
  const [selectedReason, setSelectedReason] = useState<T>();
  const [additionalNotes, setAdditionalNotes] = useState<string>();
  const [showError, setShowError] = useState<boolean>(false);

  return (
    <MainContainer direction="column">
      <GridItem>
        <Container direction="column" className="text-container">
          <Text fontSize="l">
            {`You just contacted ${title}, could you describe the reason for your contact?
             This helps us make this service better.`}
          </Text>
        </Container>
      </GridItem>
      <GridItem>
        <RequiredLabel css={StyledLabel}>Reasons for Contact</RequiredLabel>
      </GridItem>
      <GridItem>
        <ReasonsContainer direction="row" showError={showError}>
          {reasons.map((value, index) => (
            <Chip<T>
              key={index}
              reasonCode={value.key}
              selectedReason={selectedReason}
              onClick={() => onChipClick(value.key)}
            >
              {value.displayText}
            </Chip>
          ))}
        </ReasonsContainer>
      </GridItem>
      {showError && (
        <Error>
          Please select &quot;Reason for Contact&quot; before submitting
        </Error>
      )}
      <GridItem>
        <AdditionalNotesLabel css={StyledLabel}>
          Additional Notes
        </AdditionalNotesLabel>
      </GridItem>
      <GridItem>
        <Container>
          <StyledTextArea
            placeholder="Add additional notes on why you contacted this person. This is optional."
            onChange={(event) => setAdditionalNotes(event.target.value)}
            value={additionalNotes}
          />
        </Container>
      </GridItem>
      <GridItem>
        <Container>
          <SubmitButton onClick={onSubmitClick}>Submit</SubmitButton>
        </Container>
      </GridItem>
    </MainContainer>
  );

  function onChipClick(selected: T) {
    setShowError(false);
    setSelectedReason(selected);
  }

  function onSubmitClick() {
    if (!selectedReason) {
      setShowError(true);
      return;
    }
    onSubmit(
      selectedReason ? selectedReason : defaultReason,
      additionalNotes ?? null
    );
  }
}

interface ChipProps<T extends PossibleReason> {
  selectedReason?: T;
  reasonCode: T;
}
const Chip = styled.div<ChipProps<PossibleReason>>`
  background: ${({ selectedReason, reasonCode, theme }) => {
    return selectedReason && selectedReason === reasonCode
      ? theme.palette.primary.selected
      : theme.palette.primary.main;
  }};
  height: 24px;
  margin-right: 4px;
  margin-top: 4px;
  border-radius: ${({ theme }) => theme.border.radius};
  display: flex;
  padding: 5px 6px 6px;
  font-size: 11px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.light};
  cursor: pointer;
` as <T extends PossibleReason>(
  props: ChipProps<T> &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
) => JSX.Element;

const StyledLabel = (theme: Theme) => css`
  font-weight: bold;
  font-style: normal;
  font-size: ${theme.fontSize.s};
  line-height: 16px;
  color: ${theme.palette.primary.main};
`;

const SubmitButton = styled(PrimaryButton)`
  margin-top: 24px;
  width: 100%;
  height: 40px;
`;

const MainContainer = styled(MainModalContainer)`
  & .text-container {
    margin-bottom: 16px;
  }
`;

const ReasonsContainer = styled(Container)<{ showError: boolean }>`
  margin-top: 4px;
  margin-bottom: 6px;
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
  height: 84px;
  margin-top: 12px;
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  box-sizing: unset;
`;

const Error = styled(ErrorText)`
  margin-bottom: 6px;
`;

const AdditionalNotesLabel = styled(Container)`
  margin-top: 10px;
`;
