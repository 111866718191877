import { api } from 'api';
import { config } from 'core/config';
import {
  AccountProfile,
  BaseUserAccount,
  Company,
  DataAccessProfile,
  DataAccessProfileIdentity,
  SortOrder,
  UserAccount,
  UserIdentity,
  UserAccountProfile,
  WorkProviderFormalCode,
  AccountProfiles,
  AccountProfilePermits,
  AccountProfileIdentity,
  AccountProfileTemplate,
  TokenResponse,
  RepairerGroupModel,
  RepairerMetaData,
  AdminAccountProfileWPMaster,
  WorkProvider
} from './models/AutoGenerated';

const adminBaseUrl = '/api/users';
const dataAccessProfilesUrl = '/api/dataAccessProfiles';
const companiesUrl = '/api/companies';
const accountProfilesUrl = '/api/accountProfiles';

export const getUsers = (
  searchTerm?: string,
  sort?: string,
  sortOrder?: SortOrder,
  includeDisabledAccounts?: boolean
): Promise<BaseUserAccount[]> => {
  return api
    .get(`${config.activewebApiUrl}${adminBaseUrl}`, {
      params: {
        userSearchTerm: searchTerm,
        sort,
        sortOrder,
        includeDisabledAccounts,
      },
    })
    .then((response) => response.data);
};

export const getUserAccount = (id: number): Promise<UserAccount> => {
  return api
    .get(`${config.activewebApiUrl}${adminBaseUrl}/${id}`)
    .then((response) => response.data);
};

export const getUserAccountsByCompanyId = (
  companyId: number
): Promise<BaseUserAccount[]> => {
  return api
    .get(`${config.activewebApiUrl}${adminBaseUrl}`, {
      params: { companyId },
    })
    .then((response) => response.data);
};

export const getDataAccessProfiles = (
  companyId?: number
): Promise<DataAccessProfile[]> => {
  return api
    .get(`${config.activewebApiUrl}${dataAccessProfilesUrl}`, {
      params: { companyId },
    })
    .then((response) => response.data);
};

export const getRepairerGroup = (): Promise<RepairerGroupModel[]> => {
  return api
    .get(`${config.activewebApiUrl}/api/repairers/repairerGroup`)
    .then((response) => response.data);
};

export const getRepairerByGroupName = (repairerGroup: string | null): Promise<RepairerMetaData[]> => {
  return api
    .get(`${config.activewebApiUrl}/api/repairers/repairerGroupByName`, {
      params: {
        repairerGroup
      }
    })
    .then((response) => response.data);
};

export const getUserAccountProfiles = (
  id: number
): Promise<UserAccountProfile[]> => {
  return api
    .get(`${config.activewebApiUrl}${adminBaseUrl}/${id}/profiles`)
    .then((response) => response.data);
};

export const getAccountProfilesByCompanyId = (
  companyId: number
): Promise<{ items: AccountProfile[]; totalRows: number }> => {
  return api
    .get(`${config.activewebApiUrl}${accountProfilesUrl}`, {
      params: { companyId },
    })
    .then((response) => response.data);
};

export const getAssignableAccountProfilesByCompanyId = (
  companyId: number
): Promise<AccountProfileTemplate[]> => {
  return api
    .get(
      `${config.activewebApiUrl}${accountProfilesUrl}/${companyId}/GetAssignable`
    )
    .then((response) => response.data);
};

export interface AccountProfilesConfig {
  skip: number;
  take: number;
  sortString?: string;
  sortOrder?: SortOrder;
  companyId?: number;
  isTemplate?: boolean;
}

export const getAccountProfiles = ({
  skip,
  take,
  sortString,
  sortOrder,
  companyId,
  isTemplate,
}: AccountProfilesConfig): Promise<AccountProfiles> => {
  return api
    .get(`${config.activewebApiUrl}${accountProfilesUrl}`, {
      params: {
        skip,
        take,
        sortString,
        sortOrder,
        companyId,
        isTemplate,
      },
    })
    .then((response) => response.data);
};

export const getAccountProfile = (
  accountProfileId: number
): Promise<AccountProfile> => {
  return api
    .get(`${config.activewebApiUrl}${accountProfilesUrl}/${accountProfileId}`)
    .then((response) => response.data);
};

export const getAccountProfilePermits = (
  accountProfileId?: number
): Promise<AccountProfilePermits> => {
  return api
    .get(`${config.activewebApiUrl}${accountProfilesUrl}/permits`, {
      params: { accountProfileId },
    })
    .then((response) => response.data);
};

export const updateUserAccountProfiles = (
  userId: number,
  accountProfileIds: number[],
  defaultAccountProfileId: number
) => {
  return api
    .patch(
      `${config.activewebApiUrl}${adminBaseUrl}/${userId}/user-account-profiles`,
      {
        accountProfileIds,
        defaultAccountProfileId,
      }
    )
    .then((response) => response.data);
};

export const getUserAccountsByProfileId = ({
  accountProfileId,
}: {
  accountProfileId: number | undefined;
}): Promise<BaseUserAccount[]> =>
  api
    .get(
      `${config.activewebApiUrl}${adminBaseUrl}/profiles/${accountProfileId}`
    )
    .then((response) => response.data);

export const createUserAccount = (user: UserAccount): Promise<UserIdentity> => {
  return api
    .post(`${config.activewebApiUrl}${adminBaseUrl}`, user)
    .then((response) => response.data);
};

export const updateUserAccount = (user: UserAccount) => {
  return api
    .patch(`${config.activewebApiUrl}${adminBaseUrl}/${user.id}`, user)
    .then((response) => response.data);
};

export const disableUserAccount = (userId: number) => {
  return api.patch(
    `${config.activewebApiUrl}${adminBaseUrl}/${userId}/disable`
  );
};

export const enableUserAccount = (userId: number) => {
  return api.patch(`${config.activewebApiUrl}${adminBaseUrl}/${userId}/enable`);
};

export const unlockUserAccount = (userId: number) => {
  return api.patch(`${config.activewebApiUrl}${adminBaseUrl}/${userId}/unlock`);
};

export const getCompanies = (): Promise<Company[]> => {
  return api
    .get(`${config.activewebApiUrl}${companiesUrl}`)
    .then((response) => response.data);
};

export const getCompaniesById = (companyId: number): Promise<Company> => {
  return api
    .get(`${config.activewebApiUrl}${companiesUrl}/${companyId}`)
    .then((response) => response.data);
};

export const createCompany = (company: Company) => {
  return api
    .post(`${config.activewebApiUrl}${companiesUrl}`, company)
    .then((response) => response.data);
};

export const updateCompanies = (company: Company) => {
  return api
    .patch(`${config.activewebApiUrl}${companiesUrl}/${company.id}`, company)
    .then((response) => response.data);
};

export const getDataAccessProfile = (
  dataAccessProfileId: number
): Promise<DataAccessProfile> => {
  return api
    .get(
      `${config.activewebApiUrl}${dataAccessProfilesUrl}/${dataAccessProfileId}`
    )
    .then((response) => response.data);
};

export const getWorkProviderFormalCodes = (): Promise<
  WorkProviderFormalCode[]
> =>
  api
    .get(`${config.activewebApiUrl}${dataAccessProfilesUrl}/codes`)
    .then((response) => response.data);


export const addWorkProviderformalcodes = (
  accountProfilePermitMasterIds: number[],
  accountProfileId: number
) =>
  api
    .patch(
      `${config.activewebApiUrl}${accountProfilesUrl}/wp-master-profile`,
      {
        accountProfilePermitMasterIds,
        accountProfileId
      }
    )
    .then((response) => response.data);

export const searchSiteCodeFormalCodePair = (
  dataAccessProfileId: number,
  siteCode: string,
  formalCode: string
) =>
  api
    .get(`${config.activewebApiUrl}${dataAccessProfilesUrl}/search`, {
      params: { dataAccessProfileId, siteCode, formalCode },
    })
    .then((response) => response.data);

export const getAccountprofilesWorkProvider = (
  dataAccessProfileId: number,
): Promise<AdminAccountProfileWPMaster[]> =>
  api
    .get(`${config.activewebApiUrl}${accountProfilesUrl}/workprovider/${dataAccessProfileId}`)
    .then((response) => response.data);

export const getStreams = (): Promise<WorkProvider[]> =>
  api
    .get(`${config.activewebApiUrl}/api/workprovider/wpcodes`)
    .then((response) => response.data);

export const removeSiteCodeFormalCodeAssign = (
  dataAccessProfileId: number,
  siteCode: string,
  formalCode: string
) =>
  api.delete(
    `${config.activewebApiUrl}${dataAccessProfilesUrl}/assign/delete/${dataAccessProfileId}`,
    { params: { siteCode, formalCode } }
  );

export const addSiteCodeFormalCodeAssign = (
  dataAccessProfileId: number,
  siteCode: string,
  formalCode: string
) =>
  api.post(
    `${config.activewebApiUrl}${dataAccessProfilesUrl}/assign/add/${dataAccessProfileId}`,
    { siteCode, formalCode }
  );

export const addAllSiteCodeForFormalCode = (
  formalcode: string
) =>
  api.get(
    `${config.activewebApiUrl}${dataAccessProfilesUrl}/${formalcode}/sites`,
  ).then((response) => response.data);

export const getALLSiteCodeFormalCode = (
  dataAccessProfileId: number,
) =>
  api.get(
    `${config.activewebApiUrl}${dataAccessProfilesUrl}/${dataAccessProfileId}/formalcodes`)
    .then((response) => response.data);

export const createDataAccessProfile = (
  dataAccessProfile: DataAccessProfile
): Promise<DataAccessProfileIdentity> =>
  api
    .post(
      `${config.activewebApiUrl}${dataAccessProfilesUrl}`,
      dataAccessProfile
    )
    .then((response) => response.data);

export const updateDataAccessProfile = (
  dataAccessProfile: DataAccessProfile
): Promise<DataAccessProfileIdentity> =>
  api
    .patch(
      `${config.activewebApiUrl}${dataAccessProfilesUrl}`,
      dataAccessProfile
    )
    .then((response) => response.data);

export const resetPassword = (email: string): Promise<boolean> =>
  api.post(`${config.activewebApiUrl}${adminBaseUrl}/reset-pass/${email}`);

export const notifyUser = (email: string): Promise<boolean> =>
  api.post(`${config.activewebApiUrl}${adminBaseUrl}/notify-user/${email}`);

export const impersonateUser = (userId: number): Promise<TokenResponse> =>
  api
    .get(`${config.activewebApiUrl}${adminBaseUrl}/impersonate/${userId}`)
    .then((response) => response.data);

export const createAccountProfile = (
  accountProfile: AccountProfile,
  permitIds: number[],
  recordFilterIds: number[],
  reportPermitIds: number[]
): Promise<AccountProfileIdentity> =>
  api
    .post(`${config.activewebApiUrl}${accountProfilesUrl}`, {
      accountProfile,
      permitIds,
      recordFilterIds,
      reportPermitIds,
    })
    .then((response) => response.data);

export const updateAccountProfile = (
  accountProfile: AccountProfile,
  permitIds: number[],
  recordFilterIds: number[],
  reportPermitIds: number[]
) =>
  api.patch(`${config.activewebApiUrl}${accountProfilesUrl}`, {
    accountProfile,
    permitIds,
    recordFilterIds,
    reportPermitIds,
  });
