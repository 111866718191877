/** @jsxImportSource @emotion/react */
import { ReactElement, useEffect, useState } from 'react';
import { ROUTES } from 'core/routes';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from 'core/PrivateRoute';
import { Dashboard } from 'pages/Dashboard';
import { VehiclesSearchPage } from 'pages/VehiclesSearch';
import { VehicleDetails } from 'pages/VehicleDetails';
import { AdminPage } from 'pages/AdminPage';
import { Login } from 'components/Login';
import { SigninOidc } from 'pages/SigninOidc';
import { SignoutOidc } from 'pages/SignoutOidc';
import { loadUserFromStorage } from 'core/auth/userService';
import { useAppSelector } from 'core/store';
import { Menu } from 'components/Menu';
import styled from '@emotion/styled';
import { MainHeader } from 'components/MainHeader';
import { useUser } from 'pages/hooks';
import { UserAccounts } from 'pages/UserAccounts';
import { UserAccountCreate, UserAccountEdit } from 'pages/UserAccountDetails';
import { LoadingScreen } from 'components/LoadingScreen';
import { RepairsSearch } from 'pages/RepairsSearch';
import { RedirectToStartPageHandler } from 'pages/RedirectToStartPageHandler';
import { DataAccessProfiles } from 'pages/DataAccessProfiles';
import {
  DataAccessProfileCreate,
  DataAccessProfileEdit,
} from 'pages/DataAccessProfilesDetails';
import { Reports } from 'pages/Reports';
import { ManualUpdate } from 'pages/ManualUpdate';
import { Companies } from 'pages/Companies';
import { DtlSearchPage } from 'pages/DtlSearch';
import { AccountProfiles } from 'pages/AccountProfiles';
import {
  AccountProfileCreate,
  AccountProfileEdit,
} from 'pages/AccountProfileDetails';
import { Permit } from 'api/resources/models/AutoGenerated';
import { CompanyCreate, CompanyEdit } from 'pages/CompaniesDetails';
import { ReportsDetails } from 'pages/ReportsDetails';
import { SilentSigninOidc } from 'pages/SilentSigninOidc';

export default function Main(): ReactElement {
  const { isImpersonating, isLoading } = useAppSelector((state) => state.auth);
  const { user } = useUser();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadUserFromStorage();
  }, []);

  return isLoading ? (
    <div></div>
  ) : (
    <Container>
      {(!user || isImpersonating) && <LoadingScreen />}
      <Menu userPermits={user?.userPermits} />
      {user?.userPermits && (
        <MainHeader
          isOpen={open}
          userPermits={user.userPermits}
          setOpen={() => setOpen(!open)}
          currentUserFullName={user.fullName}
        />
      )}
      <MainSection>
        <Switch>
          <PrivateRoute
            exact
            path={ROUTES.redirectHandler}
            component={RedirectToStartPageHandler}
          />
          <PrivateRoute
            exact
            path={ROUTES.vehiclesSearch}
            component={VehiclesSearchPage}
            anyOfPermits={[Permit.CORE_VEHICLES]}
          />
          <PrivateRoute
            exact
            path={ROUTES.dtlDetails}
            component={VehicleDetails}
            anyOfPermits={[Permit.CORE_TOTALLOSS]}
          />
          <PrivateRoute
            exact
            path={ROUTES.vehiclesSearchDetails}
            component={VehicleDetails}
            anyOfPermits={[Permit.CORE_VEHICLES]}
          />
          <PrivateRoute
            exact
            path={ROUTES.repairsSearchDetails}
            component={VehicleDetails}
            anyOfPermits={[Permit.CORE_SEARCH]}
          />
          <PrivateRoute
            exact
            path={ROUTES.manualUpdate}
            component={ManualUpdate}
            anyOfPermits={[Permit.ALLOW_MANUAL_UPDATES]}
          />
          <PrivateRoute
            exact
            path={ROUTES.repairsSearch}
            component={RepairsSearch}
            anyOfPermits={[Permit.CORE_SEARCH]}
          />
          <PrivateRoute
            exact
            path={ROUTES.dtlSearch}
            component={DtlSearchPage}
            anyOfPermits={[Permit.CORE_TOTALLOSS]}
          />
          <PrivateRoute exact path={ROUTES.dashboard} component={Dashboard} />
          <PrivateRoute
            exact
            path={ROUTES.admin}
            component={AdminPage}
            anyOfPermits={[Permit.CORE_ADMIN]}
          />
          <PrivateRoute
            exact
            path={ROUTES.userAccounts}
            component={UserAccounts}
            anyOfPermits={[Permit.GLOBAL_ADMIN]}
          />
          <PrivateRoute
            exact
            path={ROUTES.userAccountEdit}
            component={UserAccountEdit}
            anyOfPermits={[Permit.GLOBAL_ADMIN]}
          />
          <PrivateRoute
            exact
            path={ROUTES.userAccountCreate}
            component={UserAccountCreate}
            anyOfPermits={[Permit.GLOBAL_ADMIN]}
          />
          <PrivateRoute
            exact
            path={ROUTES.dataAccessProfiles}
            component={DataAccessProfiles}
            anyOfPermits={[Permit.GLOBAL_ADMIN]}
          />
          <PrivateRoute
            exact
            path={ROUTES.dataAccessProfileEdit}
            component={DataAccessProfileEdit}
            anyOfPermits={[Permit.GLOBAL_ADMIN]}
          />
          <PrivateRoute
            exact
            path={ROUTES.dataAccessProfilesDetailsCreate}
            component={DataAccessProfileCreate}
            anyOfPermits={[Permit.GLOBAL_ADMIN]}
          />
          <PrivateRoute
            exact
            path={ROUTES.accountProfiles}
            component={AccountProfiles}
            anyOfPermits={[Permit.GLOBAL_ADMIN]}
          />
          <PrivateRoute
            exact
            path={ROUTES.accountProfileCreate}
            component={AccountProfileCreate}
            anyOfPermits={[Permit.GLOBAL_ADMIN]}
          />
          <PrivateRoute
            exact
            path={ROUTES.accountProfileEdit}
            component={AccountProfileEdit}
            anyOfPermits={[Permit.GLOBAL_ADMIN]}
          />
          <PrivateRoute
            exact
            path={ROUTES.reports}
            component={Reports}
            anyOfPermits={[Permit.CORE_REPORTS]}
          />
          <PrivateRoute
            exact
            path={ROUTES.reportViewer}
            component={ReportsDetails}
            anyOfPermits={[Permit.CORE_REPORTS]}
          />
          <PrivateRoute
            exact
            path={ROUTES.companies}
            component={Companies}
            anyOfPermits={[Permit.GLOBAL_ADMIN]}
          />
          <PrivateRoute
            exact
            path={ROUTES.companiesEdit}
            component={CompanyEdit}
            anyOfPermits={[Permit.GLOBAL_ADMIN]}
          />
          <PrivateRoute
            exact
            path={ROUTES.companiesCreate}
            component={CompanyCreate}
            anyOfPermits={[Permit.GLOBAL_ADMIN]}
          />

          <Route path={ROUTES.login} component={Login} />
          <Route path={ROUTES.signoutOidc} component={SignoutOidc} />
          <Route path={ROUTES.singinOidc} component={SigninOidc} />
          <Route path={ROUTES.silentSigninOidc} component={SilentSigninOidc} />
          <Route
            path={ROUTES.notFound}
            component={() => <div>Not Found</div>}
          />
        </Switch>
      </MainSection>
    </Container>
  );
}

const Container = styled('div')`
  width: 100%;
  height: 100%;
  padding-left: ${({ theme }) => `${theme.palette.drawer.width}px`};
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding-left: 0;
  }
`;

const MainSection = styled.main`
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);
`;
