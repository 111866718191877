import {
  Clear,
  LockOpen,
  MailOutline,
  Security,
  SupervisorAccount,
} from '@mui/icons-material';
import { UserAccount } from './form';
import { GridItem } from 'components/Layout';
import { GrayBackdrop, Loader } from 'components/Loader';
import { Label } from 'components/PageLayout';
import {
  ActionButton,
  ActionText,
  InnerStatusContainer,
  ItemWrapper,
  Line,
  MainStatusContainer,
  StatusHeader,
} from 'components/PageStatusLayout';
import { Text } from 'components/Typography';
import { logger } from 'core/logger';
import {
  useDisableUser,
  useEnableUser,
  useImpersonateUser,
  useNotifyUser,
  useResetPassword,
  useUnlockUser,
} from 'pages/hooks';
import { formatDate } from 'utils/helpers';
import { userImpersonate } from 'core/auth/userService';
import { ROUTES } from 'core/routes';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'core/store';

interface Props {
  user: UserAccount;
  onUserChange: (successMessage: string) => void;
  onUserAction: (successMessage: string) => void;
}

export function UserSecurityInfo({ user, onUserChange, onUserAction }: Props) {
  const history = useHistory();
  const { disableUser, isDisabling } = useDisableUser();
  const { enableUser, isEnabling } = useEnableUser();
  const { unlockUser, isUnlocking } = useUnlockUser();
  const { resetPassword, isReseting } = useResetPassword();
  const { notifyUser, isNotifying } = useNotifyUser();
  const { impersonateUser, isImpersonating } = useImpersonateUser();
  const { impersonatedUser } = useAppSelector((state) => state.auth);
  const isBusy =
    isDisabling ||
    isEnabling ||
    isUnlocking ||
    isReseting ||
    isNotifying ||
    isImpersonating;
  return (
    <MainStatusContainer md={8} xs={12} direction="column">
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <GridItem>
        <InnerStatusContainer direction="column">
          <StatusHeader>Security Details</StatusHeader>
          <ItemWrapper>
            <Label>Password Status</Label>
            <Text fontSize="l" noWrap>
              {user.hasPassword
                ? 'User has password'
                : "User doesn't have password"}
            </Text>
          </ItemWrapper>
          <ItemWrapper>
            <Label>Locked Status</Label>
            <Text fontSize="l">
              {user.isLocked ? 'User is locked' : 'User is not locked'}
            </Text>
          </ItemWrapper>
          {user.lastLoginDate && (
            <ItemWrapper>
              <>
                <Label>Last Login</Label>
                <Text fontSize="l">{formatDate(user.lastLoginDate)}</Text>
              </>
            </ItemWrapper>
          )}
          <ItemWrapper>
            <Label>Login Count</Label>
            <Text fontSize="l">{user.usageCount}</Text>
          </ItemWrapper>
          <GridItem>
            <Label>Failed Login Count</Label>
            <Text fontSize="l">{user.invalidLoginAttempts}</Text>
          </GridItem>
        </InnerStatusContainer>
      </GridItem>

      <GridItem>
        <InnerStatusContainer direction="column">
          <StatusHeader>Security Actions</StatusHeader>
          <GridItem>
            <ActionButton
              disabled={user.isDisabled || !!impersonatedUser}
              onClick={() => {
                if (user.email && !user.isDisabled && !impersonatedUser)
                  impersonateUser(user.id).then((response) => {
                    userImpersonate(response, user.id);
                    history.push(ROUTES.dashboard);
                  });
              }}
            >
              <ActionText>
                <SupervisorAccount />
                Impersonate User Account
              </ActionText>
            </ActionButton>
          </GridItem>
          <GridItem>
            <Line />
          </GridItem>
          <GridItem>
            <ActionButton
              onClick={() => {
                if (user.email)
                  notifyUser(user.email).then(() =>
                    onUserAction('User notification email sent successfully')
                  );
              }}
            >
              <ActionText>
                <MailOutline />
                Notify User
              </ActionText>
            </ActionButton>
          </GridItem>
          <GridItem>
            <Line />
          </GridItem>
          <GridItem>
            <ActionButton
              onClick={() => {
                if (user.email)
                  resetPassword(user.email).then(() =>
                    onUserAction('Password reset link sent successfully')
                  );
              }}
            >
              <ActionText>
                <Security />
                Request Password Reset
              </ActionText>
            </ActionButton>
          </GridItem>
          <GridItem>
            <Line />
          </GridItem>
          <GridItem>
            <ActionButton
              onClick={() =>
                user.isDisabled
                  ? createActionHandler(
                      () => enableUser(user.id),
                      'User account enabled successfully'
                    )
                  : createActionHandler(
                      () => disableUser(user.id),
                      'User account disabled successfully'
                    )
              }
            >
              <ActionText>
                <Clear />
                {user.isDisabled
                  ? 'Enable User Account'
                  : 'Disable User Account'}
              </ActionText>
            </ActionButton>
          </GridItem>
          <GridItem>
            <Line />
          </GridItem>
          <GridItem>
            <ActionButton
              disabled={!user.isLocked}
              onClick={() =>
                createActionHandler(
                  () => unlockUser(user.id),
                  'User account unlocked successfully'
                )
              }
            >
              <ActionText>
                <LockOpen />
                Unlock User Account
              </ActionText>
            </ActionButton>
          </GridItem>
        </InnerStatusContainer>
      </GridItem>
    </MainStatusContainer>
  );

  function createActionHandler<T>(
    getPromise: () => Promise<T>,
    successMessage: string
  ) {
    return getPromise()
      .then(() => {
        onUserChange(successMessage);
      })
      .catch((error) => logger.error(error));
  }
}
