import { SortOrder } from 'api/resources/models/AutoGenerated';

type UserAccountParams = {
  searchTerm: string | null;
  sort: string | null;
  sortOrder: SortOrder;
  disabledAccounts: boolean;
};

export function useUserAccountParams(search: string): UserAccountParams {
  const urlParams = new URLSearchParams(search);

  const searchTerm = urlParams.get('searchTerm');
  const sort = urlParams.get('sort');
  const sortOrder = urlParams.get('sortDirection') as SortOrder;
  const disabledAccounts = urlParams.get('disabledAccounts') === 'true';
  return {
    searchTerm: searchTerm,
    sort: sort,
    sortOrder: Object.values(SortOrder).includes(sortOrder)
      ? sortOrder
      : SortOrder.Descending,
    disabledAccounts,
  };
}
