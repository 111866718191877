import styled from '@emotion/styled/macro';
import { ControllerFieldState } from 'react-hook-form';
import { TextField } from './TextField';

interface Props {
  fieldState: ControllerFieldState;
  onChange: (...event: unknown[]) => void;
  value: number | null;
}

export default function NumberField({
  fieldState: { error },
  onChange,
  value,
}: Props) {
  return (
    <NumberInput
      onChange={(event) => {
        const inputValue = getNumberInputValue(event.target.value);
        if (inputValue === undefined) {
          event.preventDefault();
          return;
        }
        onChange(inputValue);
      }}
      value={value}
      fullWidth
      type="number"
      helperText={error?.message}
      error={!!error}
      onPaste={(event) => {
        if (isNaN(parseFloat(event.clipboardData.getData('Text'))))
          event.preventDefault();
      }}
      onKeyPress={(event) => {
        if (
          event?.key === '-' ||
          event?.key === '+' ||
          event?.key === 'e' ||
          event?.key === 'E'
        ) {
          event.preventDefault();
        }
      }}
    />
  );
}

export function getNumberInputValue(value: string): number | null | undefined {
  const regex = new RegExp('^\\d*[0-9]?(?:\\.[0-9]{1,2})?$');
  if (!regex.test(value) || value.length > 15) {
    return;
  }
  const number = parseFloat(value);
  const newValue = isNaN(number) ? null : number;
  return newValue;
}

const NumberInput = styled(TextField)`
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
