import MobileBottomButton, {
  BackButton,
  CreateButton,
} from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { Heading, Text } from 'components/Typography';
import styled from '@emotion/styled';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Select } from 'components/Select';
import { useDataAccessProfiles } from 'pages/hooks';
import { DataGrid, getSortObject } from 'components/DataGrid';
import { dataAccessProfilesTableHeaders } from './type';
import { useCompaniesOptions, useDataAccessProfilesParams } from './hooks';
import { SortObject } from 'components/types';
import { useEffect, useState } from 'react';
import { createAdminDataAccessProfilesUrl, ROUTES } from 'core/routes';
import {
  DataAccessProfile,
  SortOrder,
} from 'api/resources/models/AutoGenerated';
import { orderBy } from 'lodash';
import { disabledCompanySearchColumns } from 'config';
import {
  MainPageContainer,
  UpperInfoContainer,
  PageHeader,
  TableContainer,
} from 'components/PageLayout';
import { DesktopContainer } from 'core/Theming/Device';
import InfoText from 'components/InfoText';

export function DataAccessProfiles() {
  const location = useLocation();
  const companiesOptions = useCompaniesOptions();
  const history = useHistory();
  const urlParams = useDataAccessProfilesParams(location.search);
  const [sortObject, setSortObject] = useState<SortObject>({
    sortString: urlParams.sortString ?? '',
    sortOrder: urlParams.sortOrder ?? undefined,
  });

  const [selectedCompanyId, setSelectedCompanyId] = useState(
    urlParams.companyId
  );

  const { dataAccessProfiles, isDataAccessProfilesLoading } =
    useDataAccessProfiles({
      companyId: urlParams.companyId ?? undefined,
      enabled: true,
    });

  const [sortedDataAccessProfiles, setSortedDataAccessProfiles] =
    useState(dataAccessProfiles);

  const disabledSortColumns =
    (selectedCompanyId && disabledCompanySearchColumns) || undefined;

  useEffect(() => {
    setSortedDataAccessProfiles(sortProfiles(sortObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAccessProfiles]);

  useEffect(() => {
    navigateToAccessDataProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortObject, selectedCompanyId]);

  return (
    <MainPageContainer
      isLoading={isDataAccessProfilesLoading}
      direction="column"
      wrap="nowrap"
    >
      <UpperInfoContainer direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Administration" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>Data Access Profiles</Heading>
        </PageHeader>
        <TextItem>
          <Text fontSize="m">Select Company</Text>
        </TextItem>
        <Container direction="row">
          <CompanyContainer md={3} sm={12} xs={12}>
            <Select
              options={companiesOptions}
              selectedId={selectedCompanyId ?? undefined}
              onChange={(e) => handleCompanyIdChange(e.id)}
              variant="outlined"
            />
          </CompanyContainer>
          <DesktopContainer>
            <GridItem sm={9} xs={12}>
              {renderCreateButton()}
            </GridItem>
          </DesktopContainer>
        </Container>
      </UpperInfoContainer>
      {!isDataAccessProfilesLoading &&
        (!sortedDataAccessProfiles ||
          sortedDataAccessProfiles.length === 0) && (
          <InfoText>
            {"Sorry, we couldn't find any profiles with this search criteria."}
          </InfoText>
        )}
      <TableContainer direction="column">
        <DataGrid
          isLoading={isDataAccessProfilesLoading}
          disabledSortColumns={disabledSortColumns}
          headers={dataAccessProfilesTableHeaders}
          data={sortedDataAccessProfiles}
          onRowClick={rowClick}
          onSort={sort}
          sortObject={sortObject}
        />
      </TableContainer>
      <MobileBottomButton>{renderCreateButton()}</MobileBottomButton>
    </MainPageContainer>
  );

  function renderCreateButton() {
    return (
      <CreateButton
        text="Create New Profile"
        route={ROUTES.dataAccessProfilesDetailsCreate}
      />
    );
  }

  function rowClick(row: DataAccessProfile) {
    const route = generatePath(ROUTES.dataAccessProfileEdit, {
      id: row.id,
    });

    history.push(route);
  }

  function sort(sortValue: keyof DataAccessProfile) {
    setSortObject((prevValue) => {
      const sortObj = getSortObject(sortValue, prevValue);
      setSortedDataAccessProfiles(sortProfiles(sortObj));
      return sortObj;
    });
  }

  function sortProfiles(sortObj: SortObject) {
    return orderBy(
      dataAccessProfiles,
      sortObj.sortString,
      sortObj.sortOrder === SortOrder.Descending ? 'desc' : 'asc'
    );
  }

  function navigateToAccessDataProfiles() {
    history.replace(
      createAdminDataAccessProfilesUrl(
        selectedCompanyId ?? undefined,
        sortObject.sortString ?? undefined,
        sortObject.sortOrder
      )
    );
  }

  function handleCompanyIdChange(companyId: number) {
    setSelectedCompanyId(companyId);
    setSortObject({});
  }
}

const TextItem = styled(GridItem)`
  padding-bottom: 12px;
`;

const CompanyContainer = styled(GridItem)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-bottom: 6px;
  }
`;
