import {
  Row,
  Column,
  TableSummaryRow,
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import styled from '@emotion/styled';
import { Search } from '@mui/icons-material';
import { Heading, Text } from 'components/Typography';
import { BackButton, PrimaryButton } from 'components/Button';
import { DatePickerField } from 'components/DatePickerFields';
import { Container, GridItem } from 'components/Layout';
import {
  Label,
  MainPageContainer,
  PageHeader,
  UpperInfoContainer,
} from 'components/PageLayout';
import ReportGrid from 'components/ReportGrid';
import { createReportURL } from 'core/routes';
import { useInMobile } from 'core/Theming/Device';
import dayjs from 'dayjs';
import { useSMSBillingReport02 } from 'pages/hooks';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { convertToDate } from 'pages/hooks';
import InfoText from 'components/InfoText';
import { GrayBackdrop, Loader } from 'components/Loader';
import { ReportTitleInfo } from 'components/ReportTitleInfo';
import { formatDate } from 'utils/helpers';
import { Select } from 'components/Select';

const SummaryItemComponent = (data: TableSummaryRow.ItemProps) => {
  return (
    <Text fontSize="m" fontWeight="bold">
      Total Messages: {data.value}
    </Text>
  );
};

const GroupSummaryItemComponent = (data: TableSummaryRow.ItemProps) => {
  return (
    <Text fontSize="m" fontWeight="bold">
      Messages: {data.value}
    </Text>
  );
};

type DateFormatterProps = DataTypeProvider.ValueFormatterProps;
interface Props {
  reportPath: string;
}

const dateToString = (date: Date | null) => dayjs(date).format('YYYY-MM-DD');

const DateFormatter = ({ value }: DateFormatterProps) => {
  return <div>{formatDate(value, 'L')}</div>;
};
const DateTypeProvider: React.ComponentType<DataTypeProviderProps> = (
  props: DataTypeProviderProps
) => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;

export function SMSBillingReport({ reportPath }: Props) {
  const isMobile = useInMobile();
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlParamsValue = {
    endDate: urlParams.get('endDate'),
    startDate: urlParams.get('startDate'),
    workProvider: urlParams.get('workProvider'),
  };

  const queryEnabled =
    !!urlParamsValue.startDate &&
    !!urlParamsValue.endDate &&
    !!urlParamsValue.workProvider;

  const { reportData, isReportFetching } = useSMSBillingReport02(
    {
      endDate: urlParamsValue.endDate,
      startDate: urlParamsValue.startDate,
      workProvider: urlParamsValue.workProvider,
    },
    queryEnabled
  );

  const rows = useMemo<Row>(() => reportData, [reportData]);
  const [columns] = useState<Column[]>([
    { name: 'network', title: 'Network' },
    { name: 'repairer', title: 'Repairer' },
    { name: 'repairID', title: 'Repairer ID' },
    { name: 'creditCount', title: 'Units' },
    { name: 'claimReference', title: 'Claim Ref.' },
    { name: 'registration', title: 'Vehicle Reg.' },
    { name: 'tranmissionDTG', title: 'Message Sent' },
    { name: 'smsBody', title: 'SMS Body' },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'repairer', wordWrapEnabled: true },
    { columnName: 'repairID', wordWrapEnabled: true },
    { columnName: 'registration', wordWrapEnabled: true },
    { columnName: 'network', wordWrapEnabled: true },
    { columnName: 'creditCount', wordWrapEnabled: true },
    { columnName: 'claimReference', wordWrapEnabled: true },
    { columnName: 'tranmissionDTG', wordWrapEnabled: true },
    { columnName: 'smsBody', wordWrapEnabled: true },
  ]);

  const [startDate, setStartDate] = useState<Date | null>(
    convertToDate(urlParamsValue.startDate) || null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    convertToDate(urlParamsValue.endDate) || null
  );
  const [workProvider, setWorkProvider] = useState<string | null>(
    urlParamsValue.workProvider || null
  );
  const [summaryItems] = useState([
    {
      columnName: 'repairer',
      type: 'count',
    },
  ]);

  const [groupSummaryItems] = useState([
    {
      columnName: 'smsBody',
      type: 'count',
      showInGroupFooter: false,
      alignByColumn: true,
    },
  ]);
  const [defaultGroups] = useState([{ columnName: 'network' }]);
  const options = [
    { id: 0, value: 'Allianz', text: 'Allianz' },
    { id: 1, value: 'Narg Zurich', text: 'Narg Zurich' },
    { id: 2, value: 'Covea', text: 'Covea' },
    { id: 3, value: 'Direct Line Group', text: 'Direct Line Group' },
    { id: 4, value: 'RSA Group', text: 'RSA Group' },
  ];
  const isBusy = isReportFetching;

  useEffect(() => {
    navigateIfPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamsValue.startDate, urlParamsValue.endDate]);

  return (
    <MainPageContainer isLoading={isBusy} direction="column" wrap="nowrap">
      <GrayBackdrop open={isBusy}>
        <Loader />
      </GrayBackdrop>
      <UpperInfoContainer direction="column">
        <GridItem>
          <Container>
            <BackButton text="Back to Reports" />
          </Container>
        </GridItem>
        <PageHeader>
          <Heading>
            <ReportTitleText>
              <div>SMS Billing Report 02</div>
              <ReportTitleInfo
                message="Displays the SMS Billing Report 02 for the date range provided"
                title="SMS Billing Report 02"
              />
            </ReportTitleText>
          </Heading>
        </PageHeader>
        <CustomForm onSubmit={handleSubmit}>
          <Container direction="row">
            <InputButton md={3} sm={4} xs={12}>
              <Label>Start Date</Label>
              <DatePickerField
                onChange={(date) => setStartDate(date)}
                value={startDate}
                maxDate={endDate}
              />
            </InputButton>
            <InputButton md={3} sm={4} xs={12}>
              <Label>End Date</Label>
              <DatePickerField
                onChange={(date) => setEndDate(date)}
                value={endDate}
                minDate={startDate}
              />
            </InputButton>
            <InputButton md={3} sm={4} xs={12}>
              <Label>Work Provider</Label>
              <Select
                options={options}
                onChange={(status) => setWorkProvider(status.value)}
                selectedId={
                  options?.find(
                    (e: { value: string | null }) => e.value == workProvider
                  )?.id
                }
              />
            </InputButton>
            <GridItem md={1} sm={1} xs={2}>
              <Button
                type="submit"
                disabled={!startDate || !endDate || !workProvider}
              >
                {renderSearchIcon()}
                {renderSearchButtonText()}
              </Button>
            </GridItem>
          </Container>
        </CustomForm>
      </UpperInfoContainer>

      {!isBusy && rows?.length === 0 && (
        <CustomInfoText>
          {"Sorry, we couldn't find any reports with this search criteria."}
        </CustomInfoText>
      )}

      <ReportGridContainer direction="column">
        {rows?.length > 0 && (
          <ReportGrid
            rows={rows}
            columns={columns}
            disableDragAndDrop
            title="smsBillingReport"
            summaryItems={summaryItems}
            defaultGrouping={defaultGroups}
            DateTypeProvider={DateTypeProvider}
            dateColumnsName={['tranmissionDTG']}
            groupSummaryItems={groupSummaryItems}
            headerTitleForSheet="SMS Billing Report 02"
            SummaryItemComponent={SummaryItemComponent}
            tableColumnExtensions={tableColumnExtensions}
            GroupSummaryItemComponent={GroupSummaryItemComponent}
            reportHeaderTagLine={`Report produced on ${new Date().toLocaleString()} for period ${startDate?.toLocaleDateString()} to ${endDate?.toLocaleDateString()}`}
          />
        )}
      </ReportGridContainer>
    </MainPageContainer>
  );
  function renderSearchIcon() {
    if (isMobile) return <Search />;
    return null;
  }

  function renderSearchButtonText() {
    if (!isMobile) return 'View';
    return null;
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    navigateIfPossible();
  }

  function navigateIfPossible() {
    if (!!startDate && !!endDate && !!workProvider)
      return history.replace(
        createReportURL(
          {
            startDate: dateToString(startDate),
            endDate: dateToString(endDate),
            workProvider: workProvider,
          },
          reportPath
        )
      );
    else return;
  }
}

const InputButton = styled(GridItem)`
        padding-right: ${({ theme }) => theme.margin.s};
        @media (max-width: ${({ theme }) =>
          `${theme.breakpoints.values.sm}px`}) {
            margin - bottom: 6px;
        }
        & svg {
            width: 20px;
        height: 20px;
        color: ${({ theme }) => theme.palette.secondary.main};
        }
    `;

const CustomForm = styled.form`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const Button = styled(PrimaryButton)`
  min-width: 40px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
  margin-top: 20.5px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    width: 100%;
  }
`;

const ReportGridContainer = styled(Container)`
  padding-left: ${({ theme }) => theme.padding.xl};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;

const CustomInfoText = styled(InfoText)`
  margin-top: 0;
`;

const ReportTitleText = styled('div')`
  display: flex;
`;
