import { getBadRequestErrorMessage } from 'api';
import { UserAccount, getApiForm } from './form';
import { AxiosError } from 'axios';
import PrimaryToast from 'components/PrimaryToast';
import { logger } from 'core/logger';
import { ROUTES } from 'core/routes';
import {
  useAssignableAccountProfilesByCompanyId,
  useCompanies,
  useCreateUserAccount,
  useDataAccessProfiles,
} from 'pages/hooks';
import { useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { UserDetails } from './UserDetails';

interface Props {
  toastHandler: () => boolean;
  blockNavigationHandler: (payload: boolean) => void;
}

export interface DataProfile {
  id: number,
  text: string | null,
  value?: string | null
}

export function UserCreate({ toastHandler, blockNavigationHandler }: Props) {
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>();
  const history = useHistory();
  const { createUserAccount, isCreating } = useCreateUserAccount();
  const { accountProfiles, isAccountProfilesLoading } =
    useAssignableAccountProfilesByCompanyId(
      selectedCompanyId ? { id: selectedCompanyId } : 'disabled'
    );
  const { companies, isCompaniesLoading } = useCompanies({});
  const { dataAccessProfiles, isDataAccessProfilesLoading } =
    useDataAccessProfiles({
      companyId: selectedCompanyId,
      enabled: !!selectedCompanyId,
    });

  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });

  const [createdUserId, setCreatedUserId] = useState<number>();

  return (
    <>
      <UserDetails
        onSubmit={submit}
        companies={companies}
        toastHandler={toastHandler}
        accountProfiles={accountProfiles}
        onCompanyChange={setSelectedCompanyId}
        dataAccessProfiles={dataAccessProfiles}
        blockNavigationHandler={blockNavigationHandler}
        dataAccessProfileClick={dataAccessProfileClick}
        isBusy={
          isAccountProfilesLoading ||
          isCompaniesLoading ||
          isDataAccessProfilesLoading ||
          isCreating
        }
      />
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => {
          setToastState({ ...toastState, isOpen: false });
          if (createdUserId) navigateToEditUser(createdUserId);
        }}
        severity={toastState.severity}
      />
    </>
  );

  function submit(data: UserAccount) {
    createUserAccount(getApiForm(data))
      .then((response) => {
        blockNavigationHandler(false);
        setToastState({
          message: `User ${data.firstName} ${data.surname} was created successfully`,
          isOpen: true,
          severity: 'success',
        });
        setCreatedUserId(response.id);
      })
      .catch((error: AxiosError) => handleError(error));
  }

  function dataAccessProfileClick(dataProfile: DataProfile) {
    const route = generatePath(ROUTES.dataAccessProfileEdit, {
      id: dataProfile.id,
    });

    history.push(route);
  }

  function navigateToEditUser(userId: number) {
    history.replace(generatePath(ROUTES.userAccountEdit, { id: userId }));
  }

  function handleError(error: AxiosError) {
    logger.error(error);
    setToastState({
      message: getBadRequestErrorMessage(error),
      isOpen: true,
      severity: 'error',
    });
  }
}
