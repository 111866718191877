type ReportParams = {
  startDate: string | null;
  endDate: string | null;
};

export function useReportParams(search: string): ReportParams {
  const urlParams = new URLSearchParams(search);

  const startDate = urlParams.get('startDate');
  const endDate = urlParams.get('endDate');
  return { startDate, endDate };
}
