/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import MenuItem from '@mui/material/MenuItem';
import MaterialSelect from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { SelectChangeEvent } from "@mui/material";

type Id = number;

export interface SelectOption<T = string> {
  id: Id;
  value: T;
  text: string;
  optionElement?: JSX.Element;
}

interface SelectProps<T = string> {
  options: SelectOption<T>[];
  selectedId?: number;
  onChange?: (selected: SelectOption<T>) => void;
  className?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  error?: boolean;
  disabled?: boolean;
}

export function Select<T = string>({
  options,
  selectedId,
  onChange,
  className,
  variant = 'outlined',
  error,
  disabled,
}: SelectProps<T>) {
  const [id, setId] = useState<number>();

  function handleChange(event: SelectChangeEvent<unknown>) {
    if (event.target.value === 'novalue') return;
    const selectedOptionId = event.target.value as Id;
    const selected = options.find((o) => o.id === selectedOptionId);
    if (!selected) throw new Error('Unable to find exising option');

    if (selectedId === undefined) {
      setId(selectedOptionId);
    }

    onChange?.(selected);
  }

  let value = selectedId ?? id ?? '';
  if (!options.find((o) => o.id === value)) value = '';

  useEffect(() => {
    if (value === '') {
      setId(undefined);
    }
  }, [value, setId]);

  return (
    <StyledMaterialSelect
      disabled={disabled}
      className={className}
      value={value}
      onChange={handleChange}
      variant={variant}
      error={error}
    >
      {options.length > 0 ? (
        options.map((option) => (
          <StyledMenuItem key={option.id} value={option.id}>
            {option.optionElement || option.text}
          </StyledMenuItem>
        ))
      ) : (
        <StyledMenuItem value="novalue">No values</StyledMenuItem>
      )}
    </StyledMaterialSelect>
  );
}

const StyledMenuItem = styled(MenuItem)`
  font-size: ${({ theme }) => theme.fontSize.m};
`;

const StyledMaterialSelect = styled(MaterialSelect)`
  width: 100%;
  height: 40px;
  font-size: ${({ theme }) => theme.fontSize.m};
  background-color: ${({ theme }) => theme.palette.background.default};

  & .MuiSelect-select {
    background-color: transparent;
    padding: 2px 14px;
  }

  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${({ theme }) => `2px solid ${theme.palette.primary.main}`};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme, disabled }) =>
    disabled ? theme.palette.button.disabled : 'inherits'};
  }

  &.MuiPaper-root {
    width: 100%;
  }
  fieldset {
    border: ${({ disabled }) => (disabled ? '0px' : 'inherits')};
  }
`;
