import dayjs, { Dayjs } from 'dayjs';

export type ComparisonResult = 'overdue' | 'early' | 'late';

export interface AdditionalStatus {
  comparison: ComparisonResult;
  message: string;
}

export function additionalStatus(
  start?: Dayjs,
  end?: Dayjs
): AdditionalStatus | undefined {
  const now = dayjs();

  const dayOnlyStart = start ? dayjs(start.format('YYYY-MM-DD')) : undefined;
  const dayOnlyEnd = end ? dayjs(end.format('YYYY-MM-DD')) : undefined;

  if (!dayOnlyStart) return undefined;

  if (dayOnlyStart < now && !end)
    return {
      message: 'Overdue',
      comparison: 'overdue',
    };

  if (!dayOnlyEnd) return undefined;

  const dateDifference = dayOnlyStart.diff(dayOnlyEnd, 'day');
  if (dateDifference > 0)
    return {
      message: `${dateDifference} days early`,
      comparison: 'early',
    };
  if (dateDifference < 0)
    return {
      message: `${Math.abs(dateDifference)} days late`,
      comparison: 'late',
    };
}
